import React from 'react';
import Modal from 'react-modal';
import Legend from '../Legend';
import { IProps } from './Interfaces/Component.props';
import get from 'lodash/get';
import { DATA, EVENT, CATEGORY, ACTION } from '../../analytics/constants';
import NoIndex from '../NoIndex';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Gauge.scss';

interface IState {
  isModalOpen: boolean;
}

const activeClass = 'gauge__list-item_active';

class Gauge extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }
  public render = () => (
    <>
      <a className="gauge cursor-pointer" onClick={() => this.toggleModal()} {...this.getDataAttributes()}>
        <div className="gauge__label">{get(this.props, 'fields.heading.value', '')}</div>
        <ul className="list-no-style gauge__list">{this.generateItems()}</ul>
      </a>
      <Modal
        isOpen={this.state.isModalOpen}
        onRequestClose={() => this.toggleModal()}
        className="modal"
        overlayClassName="modal__overlay"
        contentLabel="Legend"
      >
        <NoIndex>
          <button className="modal__close" onClick={() => this.toggleModal()}>
            {get(this.props, 'fields.closeLabel.value', '')}
          </button>
          <Legend fields={get(this.props, 'legend.fields')} />
        </NoIndex>
      </Modal>
    </>
  );
  private getDataAttributes = (): object => {
    const dataAttributes = {
      [DATA.event]: EVENT.click,
      [DATA.category]: CATEGORY.userInteraction,
      [DATA.action]: ACTION.popin,
      [DATA.label]: `${get(this.props, 'headline', '')} - ${get(this.props, 'fields.heading.value', '')}`,
    };

    return dataAttributes;
  };
  private generateItems = () => {
    const gaugeItems: JSX.Element[] = [];
    for (let index = 0; index < get(this.props, 'fields.levels.value', 0); index += 1) {
      const classNames = `gauge__list-item
      ${get(this.props, 'fields.level.value', 0) <= index ? '' : activeClass}`;
      gaugeItems.push(<li className={classNames} key={`gauge__list-item${index}`} />);
    }
    return gaugeItems;
  };
  private toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }
}

export default withStyles(s)(Gauge);
