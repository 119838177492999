import React, { useContext } from 'react';
import Sticker from 'react-stickyfill';
import ProgressBar from '../ProgressBar';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import NoIndex from '../NoIndex';
import ProgressBarContext from '../../ProgressBarContext';
import TreatmentDetailsPageContext from '../Styleguide-Layout-TreatmentDetailsPage/TreatmentDetailsPageContext';
import IndicationPageContext from '../Styleguide-Layout-IndicationPage/IndicationPageContext';

const ProgressBarHolder: React.FunctionComponent<any> = (props: any) => {
  const getSitecoreModeStatus = props.addClassSitecore;

  const getProgressBarClassName = () => {
    return getSitecoreModeStatus ? 'progressbar-holder_is-edit-mode' : '';
  };

  const getPositionClass = context => {
    return context.stepperIsRelative ? '_relative' : '';
  };

  return (
    <ProgressBarContext.Consumer>
      {context => (
        <NoIndex>
          {props.progressBar && (
            <Sticker>
              <div
                ref={props.progressBarHolder}
                className={`progressbar-holder ${getPositionClass(context)} ${getProgressBarClassName()}`}
              >
                <Container fluid={true} className="layout">
                  <Row align={'center'} className="flex">
                    <Col xs={12}>
                      <div className="progressbar-wrapper">
                        <Visible xs={true} sm={true}>
                          <div ref={props.stepsHolder}>
                            <ProgressBar routeName={props.routeName} fields={props.progressBar.fields} />
                          </div>
                        </Visible>
                        <Hidden xs={true} sm={true}>
                          <ProgressBar
                            routeName={props.routeName}
                            fields={props.progressBar && props.progressBar.fields}
                          />
                        </Hidden>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Sticker>
          )}
        </NoIndex>
      )}
    </ProgressBarContext.Consumer>
  );
};

export default ProgressBarHolder;
