import { Visible, Hidden, Row, Col } from 'react-grid-system';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { IProps } from './Interfaces/Component.props';
import InformationPageContext from './InformationPageContext';
import OnImagesLoaded from 'react-on-images-loaded';
import { fireEvent, getRenderingWithNumbers, getMobileRendering } from '../../helpers/utils';
import Sticker from 'react-stickyfill';
import { onImagesLoadedTimeout } from '../../constants';
import NoIndex from '../NoIndex';

class StyleguideLayoutInformationPage extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  public render = () => {
    const { rendering } = this.props;

    const mobileRendering = getMobileRendering(rendering, 'information-page');

    return (
      <InformationPageContext.Provider value={{ isInformationPage: true }}>
        <Row className="flex wrapper information-page layout-row">
          <Visible xs={true} sm={true} md={true}>
            <Col xs={12}>
              <div className="content-holder">
                <Placeholder name="information-page-head" rendering={rendering} />
                <OnImagesLoaded
                  onLoaded={() => {
                    fireEvent(window, 'deckImagesLoaded');
                  }}
                  timeout={onImagesLoadedTimeout}
                >
                  <div className="decks">
                    <Placeholder
                      name="information-page"
                      rendering={getRenderingWithNumbers(mobileRendering, 'information-page')}
                    />
                    {/* This image needed for firing event deckImagesLoaded */}
                    <img className="hidden" />
                  </div>
                </OnImagesLoaded>
              </div>
              <div className="widgets__wrapper">
                <NoIndex>
                  <Placeholder name="sidebar-top-layout" rendering={rendering} />
                </NoIndex>
                <div className="widgets">
                  <NoIndex>
                    <Placeholder name="information-sidebar-layout" rendering={rendering} />
                  </NoIndex>
                </div>
              </div>
              <div className="teasers">
                <NoIndex>
                  <Placeholder name="information-teasers-layout" rendering={rendering} />
                </NoIndex>
              </div>
              <Placeholder name="indication-navigation-layout" rendering={rendering} />
            </Col>
          </Visible>
          <Hidden xs={true} sm={true} md={true}>
            <Col md={3}>
              <Sticker>
                <div className="widgets__wrapper">
                  <NoIndex>
                    <Placeholder name="sidebar-top-layout" rendering={rendering} />
                  </NoIndex>
                  <div className="widgets">
                    <NoIndex>
                      <Placeholder name="information-sidebar-layout" rendering={rendering} />
                    </NoIndex>
                  </div>
                </div>
              </Sticker>
            </Col>
            <Col md={9}>
              <Placeholder name="information-page-head" rendering={rendering} />
              <OnImagesLoaded
                onLoaded={() => {
                  fireEvent(window, 'deckImagesLoaded');
                }}
                timeout={onImagesLoadedTimeout}
              >
                <div className="decks">
                  <Placeholder
                    name="information-page"
                    rendering={getRenderingWithNumbers(rendering, 'information-page')}
                  />
                  {/* This image needed for firing event deckImagesLoaded */}
                  <img className="hidden" />
                </div>
              </OnImagesLoaded>
              <div className="teasers">
                <NoIndex>
                  <Placeholder name="information-teasers-layout" rendering={rendering} />
                </NoIndex>
              </div>
              <Placeholder name="indication-navigation-layout" rendering={rendering} />
            </Col>
          </Hidden>
        </Row>
      </InformationPageContext.Provider>
    );
  };
}

export default StyleguideLayoutInformationPage;
