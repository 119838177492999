import React from 'react';

export interface IIndicationPage {
  isIndicationPage: boolean;
}

const IndicationPageContext = React.createContext({
  isIndicationPage: false,
});

export default IndicationPageContext;
