import get from 'lodash/get';

const getCateroryItem = (id: number, name: string) => {
  return {
    id,
    fields: {
      textField: name,
    },
  };
};

const getSearchCategories = (props: any) => {
  const categories = [getCateroryItem(0, get(props, 'sitecoreContext.header.buttonAllCategories'))];
  const searchCategoriesFromProps = get(props, 'sitecoreContext.header.searchCategories', []);

  const mappedCategories = Array.isArray(searchCategoriesFromProps) ? searchCategoriesFromProps.map(category => {
    const { categoryId: id, categoryName } = category;
    return getCateroryItem(id, categoryName);
  }) : [];

  const searchCategoriesCombined = [...categories, ...mappedCategories];
  const sitecoreSearchCategories = searchCategoriesCombined || [];
  const searchCategories = sitecoreSearchCategories;

  return searchCategories;
};

export {
  getSearchCategories
};
