import * as RootItem from './Root/RootItem';
import * as RouteItem from './Routes/RoutesItem';
import { IApplicationState } from '../interfaces/ApplicationState';

export const reducers = {
  root: RootItem.reducer,
  routes: RouteItem.reducer,
};

export type IAppThunkAction<TAction> = (dispatch: (action: TAction) => void, getState: () => IApplicationState) => void;
