import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import './UserSettingsOverlay.scss';
import { IUserSettingsOverlay as IProps } from './Interfaces/Component.props';
import get from 'lodash/get';
import Modal from 'react-modal';
import CommonLink from '../CommonLink';
import { NavLink } from 'react-router-dom';
import { makeXMLHttpRequest } from '../../helpers/rest';

interface IState {
  isModalOpen: boolean;
}

class UserSettingsOverlay extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  public render = () => (
    <>
      <div className="user-settings__trigger" onClick={() => this.toggleModal()} />
      <Modal
        isOpen={this.state.isModalOpen}
        onRequestClose={() => this.toggleModal()}
        className="modal modal_user-settings"
        overlayClassName="modal__overlay"
        contentLabel={get(this.props, 'userName.value')}
      >
        <button className="modal__close" onClick={() => this.toggleModal()}>
          <Text field={get(this.props, 'close', { value: 'Close' })} />
        </button>

        <div className={`user-settings  ${this.getIsUserSettingsCollapsedClass()}`}>
          <NavLink
            className={`user-settings__logo ${this.getIsHeaderCollapsedClass()}`}
            to={this.getHomePage()}
            onClick={() => this.toggleModal()}
          >
            <Image className="user-settings__logo_leftPart" media={get(this.props, 'leftPartLogoUrlInverse')} />
            <Image className="user-settings__logo_rightPart" media={get(this.props, 'rightPartLogoUrlInverse')} />
          </NavLink>

          <div className="user-settings__user">
            {get(this.props, 'image') && (
              <div className="user-settings__user-avatar">
                <Image field={get(this.props, 'image')} />
              </div>
            )}
            <div className="user-settings__user-name">
              <Text field={get(this.props, 'userName')} />
            </div>
          </div>

          {this.props.links && <Text className="user-settings__heading" tag="h2" field={get(this.props, 'heading')} />}
          <ul className="list-no-style user-settings__list">
            {this.props.links &&
              this.props.links.map((item, key) => (
                <li key={key}>
                  <CommonLink className="user-settings__list-item" link={item} />
                </li>
              ))}
            <li>
              <div
                className="user-settings__list-item user-settings__list-item_action cursor-pointer"
                onClick={this.logout}
              >
                {get(this.props, 'logout.value.text')}
              </div>
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );

  private logout = (): void => {
    const pathName = window.location.href;
    const url = `/${get(this.props, 'logout.value.href')}?pathName=${pathName}`;
    const contentType = 'application/json';

    makeXMLHttpRequest({
      contentType,
      url,
      loadCallback: this.onLogoutLoad,
      errorCallback: error => {
        // console.warn(error);
      },
      type: 'GET',
    });
  };

  private onLogoutLoad = (response: any): void => {
    const responseText = get(response, 'target.responseText', '')?.replace(/["]+/g, '');

    if (responseText) {
      window.location.href = responseText;
    }
  };

  private toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  private getHomePage = (): string => {
    return this.props.isDisconnectedMode ? 'step-1' : '/';
  };

  private getIsHeaderCollapsedClass = (): string => {
    return this.props.isHeaderCollapsed ? 'user-settings__logo_collapsed' : '';
  };

  private getIsUserSettingsCollapsedClass = (): string => {
    return this.props.isHeaderCollapsed ? 'user-settings__collapsed' : '';
  };
}

export default UserSettingsOverlay;
