import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import get from 'lodash/get';
import { IWidgetBaseProps as IProps } from './Interfaces/Sitecore.props';
import CommonLink from '../CommonLink';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Widget.scss';

const Widget: React.FC<IProps> = (props: IProps) => (
  <div className="widget">
    <Text tag="h2" field={get(props, 'fields.heading', {})} className="widget__headline" />
    <RichText field={get(props, 'fields.content', {})} className="widget__content" />
    <CommonLink className="widget__link" link={get(props, 'fields.link')} />
  </div>
);

export default withStyles(s)(Widget);
