const EVENT = {
  click: 'click',
  asyncPage: 'AsyncPage',
  browserBack: 'BrowserBack'
};

const CATEGORY = {
  userInteraction: 'User Interaction'
};

const ACTION = {
  readFullIndication: 'Read full indication',
  shareEmail: 'Share type Email',
  shareLink: 'Share type Copy Link',
  shareImessage: 'Share type iMessage',
  shareWhatsapp: 'Share type Whatsapp',
  external: 'External',
  popin: 'Popin'
};

const LABEL = {
  closedTreatment: 'Closed treatment'
};

const PREFIX = 'data-';

const DATA = {
  event: `${PREFIX}event`,
  category: `${PREFIX}category`,
  action: `${PREFIX}action`,
  label: `${PREFIX}label`,
};

const context = 'sitecoreContext';
const analytics = `${context}.analytics`;

const SITECORE_CONTEXT = {
	applicationName: `${analytics}.applicationName`,
	googleTagManagerId: `${analytics}.googleTagManagerId`,
	title: `${context}.route.fields.pageTitle.value`,
  url: `${context}.url`
};

export {
  EVENT, CATEGORY, ACTION, LABEL, DATA, SITECORE_CONTEXT
};
