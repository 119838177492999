import { Action, Reducer } from 'redux';
import { IAppThunkAction } from '..';
import ActionTypes from './RoutesActions';

export interface IRoutes {
  route: string[];
}

export interface IRoutesState {
  payload: IRoutes;
}

export interface ISetRouteItemAction extends Action {
  type: typeof ActionTypes.SET_ROUTE_ITEM;
  res: string[];
}

type KnownAction = ISetRouteItemAction;

export const actionCreators = {
  setRouteItem: (route: string[]): IAppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState().routes;
    // check if set of new urls is subset of current
    const res = route.every(val => state.payload.route.includes(val)) ? state.payload.route : route;

    dispatch({
      res,
      type: ActionTypes.SET_ROUTE_ITEM,
    });
  },
};

const unloadedState: IRoutesState = {
  payload: { route: [] },
};

export const reducer: Reducer<any, KnownAction> = (state: IRoutesState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.SET_ROUTE_ITEM:
      return {
        ...state,
        payload: { route: action.res },
      };

    default:
  }

  return state || unloadedState;
};
