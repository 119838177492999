import { Visible, Hidden, Row, Col } from 'react-grid-system';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { IProps } from './Interfaces/Component.props';
import Sticker from 'react-stickyfill';
import NoIndex from '../NoIndex';
import IndicationPageContext from './IndicationPageContext';

class StyleguideLayoutIndicationPage extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  public render = () => {
    const { rendering } = this.props;

    return (
      <IndicationPageContext.Provider value={{ isIndicationPage: true }}>
        <Row className="flex wrapper indication-page layout-row">
          <Visible xs={true} sm={true} md={true}>
            <Col xs={12}>
              <div className="content-holder">
                <Placeholder name="indication-page-head" rendering={rendering} />
                <Placeholder name="indication-page" rendering={rendering} />
              </div>
              <div className="treatment-categories" />
              <div className="widgets__wrapper">
                <NoIndex>
                  <Placeholder name="sidebar-top-layout" rendering={rendering} />
                </NoIndex>
                <div className="widgets">
                  <NoIndex>
                    <Placeholder name="indication-sidebar-layout" rendering={rendering} />
                  </NoIndex>
                </div>
              </div>
              <div className="teasers">
                <NoIndex>
                  <Placeholder name="indication-teasers-layout" rendering={rendering} />
                </NoIndex>
              </div>
              <Placeholder name="indication-navigation-layout" rendering={rendering} />
            </Col>
          </Visible>
          <Hidden xs={true} sm={true} md={true}>
            <Col md={3}>
              <Sticker>
                <div className="widgets__wrapper">
                  <NoIndex>
                    <Placeholder name="sidebar-top-layout" rendering={rendering} />
                  </NoIndex>
                  <div className="widgets">
                    <NoIndex>
                      <Placeholder name="indication-sidebar-layout" rendering={rendering} />
                    </NoIndex>
                  </div>
                </div>
              </Sticker>
            </Col>
            <Col md={9}>
              <Placeholder name="indication-page-head" rendering={rendering} />
              <Placeholder name="indication-page" rendering={rendering} />
              <div className="treatment-categories" />
              <div className="teasers">
                <NoIndex>
                  <Placeholder name="indication-teasers-layout" rendering={rendering} />
                </NoIndex>
              </div>
              <Placeholder name="indication-navigation-layout" rendering={rendering} />
            </Col>
          </Hidden>
        </Row>
      </IndicationPageContext.Provider>
    );
  };
}

export default StyleguideLayoutIndicationPage;
