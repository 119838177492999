import * as d3 from 'd3';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { withRouter } from '../../lib/withRouter';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import Modal from 'react-modal';
import { ReactSVG } from 'react-svg';
import {
  getFormattedId,
  isIdFromSelection,
  getIdAttribute,
  getFormattedIdFromUrl,
  getNormalizedNumber,
  getLoginLinkWithParameters,
} from '../../helpers/utils';
import { IClassRange } from './interfaces/ClassRange';
import { IProps } from './interfaces/Component.props';
import { IInitialScalePan } from './interfaces/InitialScalePan';
import { IIdMapFields } from './interfaces/IdMapFields';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { arrayToObject } from './utils';
import { ITextField } from '../../interfaces/TextField';
import { EVENT, CATEGORY, ACTION } from '../../analytics/constants';
import RestrictedContent from '../RestrictedContent';

const ZOOM_IN_CLASS = 'skeleton-zoomed-in';
const ZOOM_CLASS_PREFIX = 'skeleton-zoomed-in-';
const ZOOM_LEVEL_PREFIX = 'zoom_';
const ZOOM_DELTA = 0.1;
const ID_SEPARATOR = '-';
const IS_OVERLAY_VISIBLE_KEY = 'isOverlayVisible';
const SVG_SELECTOR = 'div > div > svg'; // Dependency in the structure on the 3rd party solution ReactSVG
const DEFAULT_SCALE = 1;
const DEFAULT_SIZE = 0;
const TRANSITION_DURATION = 500; // In milliseconds
const SCALABLE_SVG_PART_SELECTOR = '#scalable';
const SKELETON_HIDDEN_CLASS = 'skeleton_hidden';
const BASE_SELECTOR = '#base';
const LABELS_PREFIXES = ['svg_label_rectangle_', 'area_', 'lock_'];
const SVG_LABEL_PREFIX = 'svg_label_';
const ZOOM_SELECTORS = ['svg_dot_circle_', 'zoom_area_', 'svg_zoom_label_rectangle_'];

interface ISkeleton {
  isReady: boolean;
  scaleExtentMin: number;
  scaleExtentMax: number;
  isLoaded: boolean;
  width: number;
  height: number;
  x: number;
  y: number;
  k: number;
  isOverlayVisible: boolean;
  isLockedOverlayVisible: boolean;
  isZoomDragActivated: boolean;
  zoomLevels: any;
  initialScalePan: IInitialScalePan;
  idMapping: { [key: string]: IIdMapFields };
  activeId: string;
  link: string;
  loginLinkWithParameters: string;
}

class SkeletonItem extends React.Component<IProps, ISkeleton> {
  private zoom = d3.zoom();
  private window: any;

  constructor(props: IProps) {
    super(props);

    const k = this.getInitialScale();
    if (typeof window !== 'undefined') {
      this.window = window;
    }

    this.state = {
      k,
      isReady: false,
      width: DEFAULT_SIZE,
      height: DEFAULT_SIZE,
      isLockedOverlayVisible: false,
      isZoomDragActivated: false,
      isOverlayVisible:
        get(this.window, 'localStorage') && get(this.window, 'localStorage').getItem(IS_OVERLAY_VISIBLE_KEY) === null,
      isLoaded: false,
      scaleExtentMin: DEFAULT_SCALE,
      scaleExtentMax: DEFAULT_SCALE,
      x: 0,
      y: 0,
      zoomLevels: {},
      initialScalePan: {
        k,
        x: 0,
        y: 0,
      },
      idMapping: {},
      activeId: '',
      link: '',
      loginLinkWithParameters: '',
    };
  }

  public render = () => {
    return (
      <>
        {this.state.link && <Navigate to={this.state.link} />}
        <ReactSVG onClick={this.handleClick} src={get(this.props, 'url')} afterInjection={this.updateSvg} />
        {this.isScalable() && (
          <div className="skeleton__controls">
            <div
              className="skeleton__control skeleton__control_zoom-out"
              data-zoom-in="false"
              onClick={this.handleZoom}
            />
          </div>
        )}
        {this.state.isOverlayVisible && this.isScalable() && (
          <div className="skeleton__overlay" onClick={this.handleOverlayInteraction}>
            {this.props.overlayMessage && (
              <div className="skeleton__overlay-message">{this.props.overlayMessage.value}</div>
            )}
          </div>
        )}
        {this.state.isLockedOverlayVisible && (
          <div className="skeleton__overlay skeleton__overlay_md" onClick={this.toggleModal}>
            {this.getLockedContent()}
          </div>
        )}
        {get(this.props, 'screenClass') === 'xs' && (
          <Modal
            isOpen={this.state.isLockedOverlayVisible}
            onRequestClose={this.toggleModal}
            className="modal"
            overlayClassName="modal__overlay"
            contentLabel="Locked content"
          >
            {this.getLockedContent()}
          </Modal>
        )}
      </>
    );
  };

  public componentDidMount() {
    this.setState({
      idMapping: arrayToObject(this.props.idMapping, 'fields.id.value', 'fields'),
      loginLinkWithParameters: getLoginLinkWithParameters(this.props),
    });
  }

  public componentDidUpdate(previousProps) {
    const isRouteChanged = this.props.location !== previousProps.location;
    if (isRouteChanged) {
      this.setState({
        loginLinkWithParameters: getLoginLinkWithParameters(this.props),
      });
    }
  }

  private isScalable = (): boolean => {
    return Object.keys(get(this.state, 'zoomLevels')).length > 1;
  };

  private getLockedContent = (): React.ReactElement => {
    return (
      <article className="skeleton__overlay-content" onClick={e => e.stopPropagation()}>
        <button className="modal__close" onClick={this.toggleModal} onWheel={this.toggleModal}>
          <Text field={get(this.props, 'closeLabel')} />
        </button>
        <RestrictedContent {...this.getRestrictedContentData()} />
      </article>
    );
  };

  private getRestrictedContentData = () => {
    return {
      label: this.getOverlayData('overlayLabel'),
      heading: this.getOverlayData('overlayHeading'),
      text: this.getOverlayData('overlayText'),
      question: this.getOverlayData('overlayQuestion'),
      loginLabel: get(this.props, 'loginText'),
      loginUrl: this.state.loginLinkWithParameters,
      registrationLabel: get(this.props, 'signupText'),
      registrationUrl: get(this.props, 'signupUrl.value', ''),
    };
  };

  private getOverlayData = (name: string): object | ITextField => {
    return get(this.state, `idMapping[${this.state.activeId}].overlay.fields.${name}`, {});
  };

  private toggleModal = (): void => {
    this.setState({
      isLockedOverlayVisible: false,
    });
  };

  private handleOverlayInteraction = (): void => {
    if (get(this.window, 'localStorage')) {
      get(this.window, 'localStorage').setItem(IS_OVERLAY_VISIBLE_KEY, 'false');
    }
    this.setState({
      isOverlayVisible: false,
    });
  };

  private getScalableSvgPartSelector = (): string => {
    return `${this.getSvgId()} ${SCALABLE_SVG_PART_SELECTOR}`;
  };

  private lazyZoom = (tx: number, ty: number, k: number): void => {
    this.window.requestAnimationFrame(() => {
      d3.selectAll(this.getScalableSvgPartSelector()).attr('transform', `translate(${[tx, ty]})scale(${k})`);
    });
  };

  private handleClick = (event: any): void => {
    this.handleZoomButtonClick(event);
    if (!this.getIsZoomId(event)) {
      this.handleLabelClick(event);
    }
  };

  private handleZoom = (): void => {
    const { x, y, k } = this.state.initialScalePan;
    this.updateZoom(x, y, k);
  };

  private getSvgId = (): string => {
    return `#${getFormattedIdFromUrl(`${get(this.props, 'url')}`)}`;
  };

  private getSkeletonNode = (): SVGElement => {
    return d3.select<any, HTMLElement>(this.getSvgId()).node();
  };

  private enableZoomButtons = (newScale: number): void => {
    const disableClass = 'zoom-out-disable';
    const skeletonNode = this.getSkeletonNode();

    if (!skeletonNode) {
      return;
    }

    if (this.isInRange(newScale, get(this.state, 'scaleExtentMin'), get(this.state, 'scaleExtentMax'))) {
      skeletonNode.classList.remove(disableClass);
    }
    if (newScale <= get(this.state, 'scaleExtentMin')) {
      skeletonNode.classList.add(disableClass);

      this.setState({
        isZoomDragActivated: false,
      });
    }
  };

  private getIsZoomId = (event: Event): boolean => {
    const id = getIdAttribute(event, 'id');
    const formattedId = getFormattedId(ZOOM_SELECTORS, id);
    const isZoomId = isIdFromSelection(ZOOM_SELECTORS, id) && formattedId;

    return !!isZoomId;
  };

  private handleZoomButtonClick = (event: Event): void => {
    const id = getIdAttribute(event, 'id');

    if (this.getIsZoomId(event)) {
      const { x, y, k } = this.getZoomData(getFormattedId(ZOOM_SELECTORS, id));

      if (id && x && y && k) {
        this.updateZoom(x, y, k);
      }
    }
  };

  private updateZoom = (x: number, y: number, k: number): void => {
    this.zoomPanTo(x, y, k);
    this.enableZoomButtons(k);
  };

  private handleLabelClick = (event: Event): void => {
    const id = getIdAttribute(event, 'id');
    const activeId = this.getActiveId(id);
    const isCorrectId = this.getIsCorrectId(activeId, id);

    if (!isCorrectId) {
      this.handleNotCorrectId(event, activeId);
    }
    const activeMapItem = this.state.idMapping[activeId];
    const isLocked = get(activeMapItem, 'isLocked.value');

    if (isLocked) {
      this.handleLockedContent(activeId);
      event.stopPropagation();
    } else {
      this.handleLink(activeId, activeMapItem);
    }
  };

  private getIsCorrectId = (activeId: string, id: string): boolean => {
    const isLabelId = isIdFromSelection(LABELS_PREFIXES, id) && activeId;
    return !!(isLabelId && activeId);
  };

  private getActiveId = (id: string): string => {
    return getFormattedId(LABELS_PREFIXES, id);
  };

  private handleNotCorrectId = (event: Event, activeId: string): void => {
    event.stopPropagation();
    this.addQueryParamToUrl(activeId);
    return;
  };

  private handleLink = (activeId: string, activeMapItem: IIdMapFields): void => {
    const link = get(activeMapItem, 'link.value', '');
    const stateObj = { link, Url: link };
    if (link) {
      this.setState({
        link: stateObj.Url,
      });
      this.updateHistory(stateObj);
    } else {
      this.addQueryParamToUrl(activeId);
    }
  };

  private handleLockedContent = (activeId: string): void => {
    this.setState({
      activeId,
      isLockedOverlayVisible: true,
    });
  };

  private updateHistory = (stateObj: any): void => {
    window.history.pushState(stateObj, stateObj.link, stateObj.Url);
  };

  private addQueryParamToUrl = (value: string): void => {
    const url = this.window.location.href;
    const urlSplit = url.split('?');
    const stateObj = { link: 'New title', Url: `${urlSplit[0]}?link=${value}` };
    this.updateHistory(stateObj);
  };

  private getOffset = (scale: number, sizeName: string): number => {
    const svgSize = get(this.state, sizeName, 0);
    const scaledSize = svgSize * scale;
    const offset = (svgSize - scaledSize) / 2;
    return offset;
  };

  private setInitialScale = (): void => {
    this.setState({
      k: this.getInitialScale(),
    });
  };

  private setIsLoaded = (): void => {
    this.setState({
      isLoaded: true,
    });
  };

  private updateSvg = (): void => {
    if (this.state && !this.state.isLoaded) {
      this.setSvgSize();
      this.updateSvgSize();
      this.updateSvgClass();
      this.setInitialScale();
      this.setIsLoaded();

      // Update CSS classes after update of the state
      requestAnimationFrame(() => {
        // Set zoom levels from SVG to the state
        this.setZoomLevels();
        
      });
    }
  };

  private addLocks = (): void => {
    Object.values(this.state.idMapping).forEach(mapItem => {
      const isLocked = get(mapItem, 'isLocked.value');
      const mapItemId = get(mapItem, 'id.value', '');
      const getCircleSelector = (id: string): string =>
        `[id^="svg_label_circle_${id}"] ~ [id^="svg_label_circle_${id}"]`;
      const getLockSelector = (id: string): string => `[id^="svg_label_lock_${id}"]`;
      const getMainSelector = (selector: string): string => `${this.getSvgId()} ${selector}`;

      if (isLocked) {
        this.displayLockIcon([
          getMainSelector(getCircleSelector(mapItemId)),
          getMainSelector(getLockSelector(mapItemId)),
        ]);

        // Add data attributes for Analytics
        this.addDataAttributesToLockedLabel(`${this.getSvgId()} #${SVG_LABEL_PREFIX}${mapItemId}`);

        if (this.isScalable()) {
          this.processSubLockIcons(getMainSelector, getCircleSelector, mapItemId);
        }
      }
    });
  };

  private addDataAttributesToLockedLabel = (labelSelector: string): void => {
    const textSelector = `${labelSelector} > text`;
    const text = this.getSelectorText(textSelector);

    if (document.querySelector(labelSelector)) {
      this.addDataAttributes(document.querySelector(labelSelector), text);
    }
  };

  private getSelectorText = (textSelector: string): string => {
    let text = '';
    const labelText = document.querySelector(textSelector);

    if (labelText) {
      text = labelText.textContent || '';
    }

    return text;
  };

  private addDataAttributes = (element: SVGElement | null, text: string): void => {
    if (element) {
      element.setAttribute('data-event', EVENT.click);
      element.setAttribute('data-category', CATEGORY.userInteraction);
      element.setAttribute('data-action', ACTION.popin);
      element.setAttribute(
        'data-label',
        `${get(this.props, 'sitecoreContext.route.fields.pageTitle.value', '')} - ${text}`
      );
    }
  };

  private processSubLockIcons = (
    getMainSelector: (id: string) => string,
    getCircleSelector: (id: string) => string,
    mapItemId: string
  ): void => {
    const zoomLabelsIds = Array.from(document.querySelectorAll(`${this.getSvgId()} #zoom_labels > *`)).map(
      item => item.id
    );
    zoomLabelsIds.forEach(zoomLabelId => {
      const getSubSelector = (selector: string): string => `#${zoomLabelId} ${selector}`;
      const getSelector = (selector: string): string => getMainSelector(getSubSelector(selector));

      this.displayLockIcon([
        getSelector(getCircleSelector(mapItemId)),
        getSelector(`[id^="svg_label_lock_${mapItemId}"]`),
      ]);

      // Add data attributes for Analytics
      this.addDataAttributesToLockedLabel(`${this.getSvgId()} #${zoomLabelId} [id^="${SVG_LABEL_PREFIX}${mapItemId}"]`);
    });
  };

  private displayLockIcon = (selectors: string[]): any => {
    selectors.forEach(selector => {
      const nodes: SVGPathElement[] = Array.from(document.querySelectorAll(selector));

      nodes.forEach((value: SVGPathElement) => {
        value.style.display = 'block';
      });
    });
  };

  private getBaseSelector = (): string => {
    return `${this.getSvgId()} ${BASE_SELECTOR}`;
  };

  private setSvgSize = (): void => {
    const getSize = (sizeName: string): number =>
      parseFloat(`${d3.select<SVGElement, HTMLElement>(this.getBaseSelector()).attr(sizeName)}`);
    const width = getSize('width');
    const height = getSize('height');
    this.setState({
      width,
      height,
    });
  };

  private getSvgSelector = (): string => {
    return `${this.getSvgId()} ${SVG_SELECTOR}`;
  };

  private updateSvgSize = (): void => {
    const updateSize = (sizeName: string) =>
      d3.select<SVGElement, HTMLElement>(this.getSvgSelector()).attr(sizeName, '100%');

    ['width', 'height'].forEach(size => updateSize(size));
  };

  private updateSvgClass = (): void => {
    const svgNode = d3.select<SVGElement, HTMLElement>(this.getSvgSelector()).node();

    if (svgNode) {
      svgNode.classList.add('touch-action');
    }
  };

  private setInitialScaleAndPosition = (): void => {
    const k = this.getInitialScale();
    const x = this.getOffset(this.getInitialScale(), 'width');
    const y = this.getOffset(this.getInitialScale(), 'height');

    // Store initial scale and pan parameters
    this.setState({ initialScalePan: { x, y, k } });

    // Update state of Zoom button
    this.enableZoomButtons(k);

    // Scale and pan to coordinates
    this.zoomPanTo(x, y, k);

    this.showSkeleton();
  };

  private removeListeners = (): void => {
    const svg = d3.select<SVGElement, HTMLElement>(this.getSvgSelector());
    const events = [
      'click.zoom',
      'scroll.zoom',
      'touchmove.zoom',
      'touchstart.zoom',
      'wheel.zoom',
      'mousewheel.zoom',
      'MozMousePixelScroll.zoom',
    ];
    events.forEach(event => svg.on(event, null));
  };

  private zoomPanTo = (x: number, y: number, k: number): void => {
    const selection = d3.select<any, any>(this.getSvgSelector());
    const transitionDuration = TRANSITION_DURATION;
    const tr = d3.zoomIdentity.translate(x, y).scale(k);

    selection
      .transition()
      .duration(transitionDuration)
      .call(this.zoom.transform, tr);
    selection.call(this.zoom);

    this.setState({
      x,
      y,
      k,
    });

    // Toogle class for enabling/disabling buttons and labels
    this.toggleZoomClass(k);

    // Add transforms to SVG element
    this.lazyZoom(x, y, k);

    // Remove default d3 listeners to be able to scroll the page
    this.removeListeners();
  };

  private getElementBBox = (selector: string): DOMRect | null => {
    if (window) {
      const element = document.querySelector(selector);
      const elementBBox = element && (element as SVGSVGElement).getBBox();
      return elementBBox;
    }
    return null;
  };

  private getElementHeight = (selector: string): number | null => {
    const elementBBox = this.getElementBBox(selector);
    const height = elementBBox && elementBBox.height;

    return height;
  };

  private getScaleExtentMin = (): number => {
    return Math.min(...this.getZoomScales());
  };

  private getScaleExtentMax = (): number => {
    return Math.max(...this.getZoomScales());
  };

  private getFormattedZoomLevelId = (id: string): string => {
    return `${id}`.substring(ZOOM_LEVEL_PREFIX.length).split(ID_SEPARATOR)[0];
  };

  private setZoomLevels = (): void => {
    const zoomLevels = {};
    const zoomLevelList=this.getZoomLevels()
    zoomLevelList.forEach(zoomLevel => {
      const { x, y, k, id } = zoomLevel;
      const formattedZooomLevelId=this.getFormattedZoomLevelId(`${id}`);
      zoomLevels[formattedZooomLevelId] = {
        x,
        y,
        k: this.getNormalizedScale(k),
      };
    });
    this.setState({
      zoomLevels:zoomLevels,
    },()=>{
      this.setInitialScaleAndPosition();
      this.addLocks();
    });
  };

  private getDeltaValue = (data: any): number => {
    const { baseBBox, elementBBox, size, coordinate, k } = data;
    const centerOfBase = baseBBox[coordinate] + baseBBox[size] / 2;
    const centerOfElement = elementBBox[coordinate] + elementBBox[size] / 2;
    const distanceBaseAndElement = centerOfElement - centerOfBase;
    const delta = distanceBaseAndElement * k;
    return delta;
  };

  private getDelta = (baseBBox: any, elementBBox: any, k: number): { [key: string]: number } => {
    return {
      x: this.getDeltaValue({ baseBBox, elementBBox, k, size: 'width', coordinate: 'x' }),
      y: this.getDeltaValue({ baseBBox, elementBBox, k, size: 'height', coordinate: 'y' }),
    };
  };

  private getZoomLevels = (): IInitialScalePan[] => {
    if (typeof document !== 'undefined') {
      const zoomAreasPaths = Array.from(document.querySelectorAll(`${this.getSvgId()} #zoom_areas > *`));
      const zoomLevels =
        (zoomAreasPaths &&
          zoomAreasPaths.map(element => {
            const { x, y, k } = this.getZoomLevelCoordinates(element);
            return {
              k,
              x,
              y,
              id: element.id,
            };
          })) ||
        [];

      return zoomLevels;
    }

    return [];
  };

  private getShift = (data: any): number => {
    const { baseBBox, elementBBox, isVertical, k } = data;
    const coordinate = isVertical ? 'y' : 'x';
    const shift = -this.getDelta(baseBBox, elementBBox, k)[coordinate];

    return shift;
  };

  private getZoomLevelCoordinates = (element: SVGAElement | any): { [key: string]: number } => {
    const baseHeight = this.getElementHeight(this.getBaseSelector());
    const baseBBox = this.getElementBBox(this.getBaseSelector());
    const elementBBox = element && element.getBBox();
    const height = parseFloat(elementBBox && elementBBox.height);
    const k = parseFloat(((baseHeight && height && baseHeight / height) || 1).toFixed(3));
    const isInitial = this.getFormattedZoomLevelId(element.id) === 'Initial';

    const getCoordinate = (size: string, isVertical: boolean) =>
      isInitial
        ? this.getOffset(k, size)
        : this.getOffset(k, size) +
        this.getShift({
          baseBBox,
          elementBBox,
          k,
          isVertical,
        });
    const x = getCoordinate('width', false);
    const y = getCoordinate('height', true);

    return { x, y, k };
  };

  private getZoomScales = (): number[] => {
    return this.getZoomLevels().map((zoomLevel: IInitialScalePan) => zoomLevel.k);
  };

  private getInitialScale = (): number => {
    const scaleExtentMin = getNormalizedNumber(this.getScaleExtentMin(), 2);
    const scaleExtentMax = getNormalizedNumber(this.getScaleExtentMax(), 2);

    if (this.state) {
      this.setState({
        scaleExtentMin,
        scaleExtentMax,
      });
    }

    return scaleExtentMin;
  };

  private getZoomData = (id: string): { [key: string]: number } => {
    let zoomData = { x: 0, y: 0, k: 0 };
    const zoomLevelData = this.state.zoomLevels[id];
    if (zoomLevelData) {
      zoomData = zoomLevelData;
    }

    return zoomData;
  };

  private toggleZoomClass(scale: number): void {
    d3.select<SVGElement, HTMLElement>(`${this.getSvgId()} > div`).attr('class', this.getZoomClasses(scale).join(' '));
  }

  private getNormalizedScale = (k: number): number => {
    const digitsAfterPoint = 2;

    return Number(k.toFixed(digitsAfterPoint));
  };

  private getClassRanges = (): IClassRange[] => {
    const scalesList = uniq(Object.values(this.state.zoomLevels).map((zoomLevel: any) => zoomLevel.k)).sort();

    const classList: IClassRange[] = scalesList.map((k: number, index: number) => {
      const previousScale = scalesList[index - 1];
      const scaleMin = previousScale ? previousScale + ZOOM_DELTA : 0;
      return {
        scaleMin: getNormalizedNumber(scaleMin, 2),
        scaleMax: getNormalizedNumber(k, 2),
        class: `${ZOOM_CLASS_PREFIX}${index}`,
      };
    });

    classList.push({
      class: ZOOM_IN_CLASS,
      scaleMin: scalesList[1], // Second zoom level after initial
      scaleMax: this.state.scaleExtentMax,
    });

    return classList;
  };

  private showSkeleton = (): void => {
    requestAnimationFrame(() => {
      if (this.getSkeletonNode()) {
        this.getSkeletonNode().classList.remove(SKELETON_HIDDEN_CLASS);
      }
    });
  };

  private getZoomClasses = (scale: number): string[] =>
    this.getClassRanges()
      .filter(classRange => this.isInRange(scale, classRange.scaleMin, classRange.scaleMax))
      .map(classRange => classRange.class);

  private isInRange = (x: number, min: number, max: number): boolean => x >= min && x <= max;
}

export default withSitecoreContext()(withRouter(SkeletonItem));
