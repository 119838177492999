import React from 'react';
import get from 'lodash/get';
import TruncatedContent from '../TruncatedContent';
import Gauge from '../Gauge';
import { IProps } from './Interfaces/Component.props';
import CommonLink from '../CommonLink';
import CommonImage from '../CommonImage';
import { DATA, EVENT, CATEGORY, ACTION } from '../../analytics/constants';
import { treatmentPageImagesSizes, COMPONENT_KEY } from '../../constants';
import { saveToSessionStorage, getUid, loadFromSessionStorage, scrollToRef } from '../../helpers/utils';
import DetailStickyTopContext from '../../DetailStickyTopContext';
import NoIndex from '../NoIndex';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Treatment.scss';

interface IState {
  uid: string;
}
class Treatment extends React.Component<IProps, IState> {
  private treatmentElementRef;
  private isScrollLocked = false;

  constructor(props: IProps) {
    super(props);

    this.treatmentElementRef = React.createRef();

    this.state = {
      uid: getUid(props),
    };
  }

  public render = () => (
    <DetailStickyTopContext.Consumer>
      {offset => (
        <article className="treatment" ref={this.treatmentElementRef} data-offset={offset.headerHeight}>
          <header className="treatment__header">
            {get(this.props, 'fields.heading.value') && (
              <h2 className="treatment__headline">{get(this.props, 'fields.heading.value', '')}</h2>
            )}
          </header>
          <div className="treatment__body">
            <div className="treatment__visual">
              <div className="treatment__graphic">
                <CommonImage field={get(this.props, 'fields.image')} sizes={treatmentPageImagesSizes} />
              </div>
              <div className="treatment__levels">
                <NoIndex>
                  {get(this.props, 'fields.gaugesList', []).map((item: any, key: number) => (
                    <Gauge
                      headline={get(this.props, 'fields.heading.value', '')}
                      fields={item.fields}
                      legend={get(this.props, 'fields.legendOverlay', '')}
                      key={key}
                    />
                  ))}
                </NoIndex>
              </div>
            </div>
            <div className="treatment__copy">
              {get(this.props, 'fields.subHeading.value') && (
                <NoIndex>
                  <h3 className="treatment__sub-headline">{get(this.props, 'fields.subHeading.value', '')}</h3>
                </NoIndex>
              )}
              <TruncatedContent fields={this.props.fields} dataAttributes={this.getDataAttributes()} />
            </div>
          </div>
          <NoIndex>
            <footer className="treatment__footer">
              {get(this.props, 'fields.link.value') && (
                <CommonLink
                  onClick={event => {
                    event.stopPropagation();
                    this.onClick();
                  }}
                  className="treatment__footer-link_primary"
                  link={get(this.props, 'fields.link')}
                >
                  {get(this.props, 'fields.linkText.value', '')}
                </CommonLink>
              )}
            </footer>
          </NoIndex>
        </article>
      )}
    </DetailStickyTopContext.Consumer>
  );

  public componentDidMount() {
    window.addEventListener('scrollToCard', this.handleScrollToCard);
    window.addEventListener('animationStart', this.lockScroll);
    window.addEventListener('animationEnd', this.unlockScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener('scrollToCard', this.handleScrollToCard);
    window.removeEventListener('animationStart', this.lockScroll);
    window.removeEventListener('animationEnd', this.unlockScroll);
  }

  private lockScroll = () => {
    this.isScrollLocked = true;
  };

  private unlockScroll = () => {
    this.isScrollLocked = false;
  };

  private getDataAttributes = (): object => {
    return {
      [DATA.event]: EVENT.click,
      [DATA.category]: CATEGORY.userInteraction,
      [DATA.action]: ACTION.readFullIndication,
      [DATA.label]: get(this.props, 'fields.heading.value', ''),
    };
  };

  private handleScrollToCard = (): void => {
    const checkIsNeedToScroll = new Promise<void>(resolve => {
      if (!this.isScrollLocked) {
        resolve();
      }
    });

    checkIsNeedToScroll.then(() => {
      const isNeedToScroll = loadFromSessionStorage(`${COMPONENT_KEY.treatment}`) === this.state.uid;

      if (isNeedToScroll) {
        scrollToRef(this.treatmentElementRef);
      }
    });
  };

  private onClick = (): void => {
    this.saveTreatmentCard();
  };

  private saveTreatmentCard = (): void => {
    saveToSessionStorage({
      [COMPONENT_KEY.treatment]: this.state.uid,
    });
  };
}

export default withStyles(s)(Treatment);
