import { ScreenClassRender } from 'react-grid-system';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import get from 'lodash/get';
import * as React from 'react';
import { IProps, ITruncatedContentFields } from './interfaces/Component.props';
import TruncateMarkup from 'react-truncate-markup';
import { tokenize } from '../../constants';
import { getTextFromFullContent } from '../../helpers/utils';
import FontContext from '../../FontContext';
import NoIndex from '../NoIndex';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './TruncatedContent.scss';

const HEIGHTS = {
  allowed: 'allowed',
  content: 'content',
};

interface IState {
  isTruncated: boolean;
  isExpandVisible: boolean;
  isContentCollapsed: boolean;
  isMeasurerRendered: boolean;
  isRendered: boolean;
  collapsedClass: string;
  text: string;
}

class TruncatedContent extends React.Component<IProps, IState> {
  private height = {
    allowed: 0,
    content: 0,
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      isTruncated: false,
      isExpandVisible: false,
      isContentCollapsed: true,
      isMeasurerRendered: true,
      isRendered: false,
      collapsedClass: '',
      text: '',
    };
  }

  public render() {
    const { fields, dataAttributes, tagName, className } = this.props;
    const TagToRender: any = tagName || 'div'; // Tag wrapper for a short text
    const { isContentCollapsed, isExpandVisible, isRendered, isMeasurerRendered } = this.state;

    return (
      <>
        {isRendered && (
          <div className={className}>
            <div className="collapsed__holder">
              {isContentCollapsed ? (
                <ScreenClassRender
                  render={(screenClass: string) => (
                    <div className="collapsed__content_short">
                      <FontContext.Consumer>
                        {isFontLoaded => (
                          <>
                            {isFontLoaded && (
                              <>
                                {isMeasurerRendered && this.getMeasurers(screenClass) // Temporary block for measuring if we need expand/collapse link using text from all content
                                }
                                {isExpandVisible ? (
                                  <TruncateMarkup lines={this.getLines(screenClass)} tokenize={tokenize}>
                                    <TagToRender className={`${this.getCollapsedClasses(fields)} notranslate`}>
                                      {getTextFromFullContent(get(fields, 'fullContent.value', ''))}
                                    </TagToRender>
                                  </TruncateMarkup>
                                ) : (
                                  <TagToRender className={this.getCollapsedClasses(fields)}>
                                    <RichText field={get(this.props.fields, 'fullContent')} />
                                  </TagToRender>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </FontContext.Consumer>
                      {(isExpandVisible || get(fields, 'isExpandAlwaysVisible.value')) && (
                        <a onClick={this.toggleCollapsedContent} className="collapsed__control" {...dataAttributes}>
                          <NoIndex>{get(fields, 'expand.value')}</NoIndex>
                        </a>
                      )}
                    </div>
                  )}
                />
              ) : (
                <div className="collapsed__content_full">
                  <RichText className="collapsed__content" field={get(fields, 'fullContent')} />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  public componentDidMount() {
    this.setState({ isRendered: true }); // we need this to hide block for SSR
  }

  private getMeasurers = (screenClass: string)=> {
    return (
      <div className="collapsed__content_visually-hidden collapsed__content">
        <div ref={this.checkHeights} data-height={HEIGHTS.allowed}>
          {this.getGeneratedLines(this.getLines(screenClass))}
        </div>
        <div ref={this.checkHeights} data-height={HEIGHTS.content}>
          <RichText field={get(this.props.fields, 'fullContent')} />
        </div>
      </div>
    );
  };

  private checkHeights = (generatedFragment: HTMLDivElement): void => {
    if (!generatedFragment) {
      return;
    }

    const property = generatedFragment.getAttribute('data-height');
    const { offsetHeight } = generatedFragment;
    this.height[property || HEIGHTS.allowed] = offsetHeight;
    this.updateIsExpandVisible();
  };

  private updateIsExpandVisible = (): void => {
    const { allowed, content } = this.height;
    const wasCalculated = allowed && content;
    const isExpandVisible = !!(wasCalculated && content > allowed);

    this.setState({
      isExpandVisible,
      isMeasurerRendered: !wasCalculated,
    });
  };

  private getGeneratedLines = (lines: number): React.ReactFragment => {
    if (!lines) {
      return <div>&nbsp;</div>;
    }

    const linesArray = Array.from(Array(lines));
    return linesArray.map((_, key) => <div key={key}>&nbsp;</div>);
  };

  private getCollapsedClasses = (fields: ITruncatedContentFields): string => {
    return `collapsed__content ${get(fields, 'isBold.value') ? 'bold' : ''}`;
  };

  private getLines(screenClass: string): number {
    return Number(get(this.props.fields, ['xs', 'sm'].includes(screenClass) ? 'linesSm.value' : 'lines.value'));
  }

  private toggleCollapsedContent = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.preventDefault();

    this.setState({
      isContentCollapsed: !this.state.isContentCollapsed,
    });
  };
}

export default withStyles(s)(TruncatedContent);
