import * as React from 'react';
import { IOrganizationMicroformatPropsFields as IProps } from './Interfaces/Component.props';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';

const OrganizationMicroformat: React.FC<IProps> = (props: IProps) => (
  <Helmet>
    <script type="application/ld+json">{`
      {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "${get(props, 'data.name')}",
          "url": "${get(props, 'data.url')}",
          "sameAs": [${getUrlsList(get(props, 'data.sameAs'))}]
      }
    `}</script>
  </Helmet>
);

const getUrlsList = (urlsObjectsList: LinkField[]): string[] => {
  return urlsObjectsList && urlsObjectsList.map(urlObject => `"${get(urlObject, 'link')}"`);
};

export default OrganizationMicroformat;
