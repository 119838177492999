import React from 'react';
import { Placeholder, VisitorIdentification, withSitecoreContext, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Header from './components/Header/index';
import ProgressBarHolder from './components/ProgressBarHolder';
import Footer from './components/Footer';
import Modal from 'react-modal';
import { withRouter } from './lib/withRouter';
import { CSSTransition } from 'react-transition-group';
import { ScreenClassProvider, Container } from 'react-grid-system';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { createSearchParams } from 'react-router-dom';
import {
  getStepFromRoute,
  getRouteName,
  getIsNotDesktop,
  setGridConfiguration,
  slash,
  loadWebFonts,
  getOffset,
  getIsNeedToRedirect,
  getIsActionButton,
  fireEvent,
  getIsExternalLink,
  getTargetAttribute,
  searchPage,
  deck,
  isNotPage,
  removeFromSessionStorage,
  loadFromSessionStorage,
  LAYOUT,
  isRestrictedPage,
} from './helpers/utils';
import { isDisconnectedMode } from './helpers/sitecoreContext';
import { getSearchCategories } from './helpers/search';
import Meta from './components/Meta';
import { breakpoints, historyUpdateTimeout, COMPONENT_KEY, RESTRICTED_CONTENT_CLASS } from './constants';
import { googleTagManagerInit, updateGoogleTagManagerPageInfo } from './analytics';
import FontContext from './FontContext';
import DetailStickyTopContext from './DetailStickyTopContext';
import ProgressBarContext from './ProgressBarContext';
import delay from 'lodash/delay';
import InnerHTML from 'dangerously-set-html-content';
import {
  AtomicSearchInterface,
  buildSearchEngine
} from "@coveo/atomic-react";

// Include not critical CSS
import './assets/scss/_global-defer.scss';
// Include critical CSS
import s from './assets/scss/_global.scss';
import withStyles from 'isomorphic-style-loader-react18/withStyles';
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const { md } = breakpoints;
const CLASS_ANIMATION_WRAPPER = `animation-wrapper`;
const CLASS_ANIMATION_END = `${CLASS_ANIMATION_WRAPPER}_animation-end`;
const CLASS_NOT_ANIMATED = `${CLASS_ANIMATION_WRAPPER}_not-animated`;
const CLASS_ANIMATED_RIGHT = `${CLASS_ANIMATION_WRAPPER}_animated-right`;
const CLASS_ANIMATED_LEFT = `${CLASS_ANIMATION_WRAPPER}_animated-left`;
const PAGES_ANIMATION_NAMES = ['slideInLeft', 'slideInRight'];
const STEPS_ANIMATION_END_CLASS = 'animation-end';
const STEPS_ANIMATION_START_CLASS = 'animation-start';
const PAGE_CLASS = {
  current: 'page_current',
  next: 'page_next',
};
const MOVE = { backward: 'left', forward: 'right' };
const SLOT = { first: 'slot1', second: 'slot2' };
const SlotPlaceHolder = props => {
  const { slot, state, className } = props;

  return (
    state &&
    state[slot] && (
      <div
        className={className}
        style={{
          top: state[`${slot}PageOffset`],
        }}
      >
        <Placeholder name="jss-main" rendering={state[slot]} />
      </div>
    )
  );
};


class Layout extends React.Component {
  constructor(props) {
    super(props);

    setGridConfiguration();

    this.stepsHolder = React.createRef();
    this.animationHolder = React.createRef();
    this.animationWrapper = React.createRef();
    this.headerPlaceholder = React.createRef();
    this.slots = [SLOT.first, SLOT.second];
    this.scrollTop = 0;
    
    this.state = {
      anchorSelectorHeight: 0,
      animationEndClass: '',
      animationLeft: false,
      animationLeftClass: '',
      animationRight: false,
      animationRightClass: '',
      detailStickyTopHeight: 0,
      headerHeight: 0,
      isAnimation: false,
      isEditMode: false,
      isFontLoaded: false,
      isOverflowEnabled: false,
      isProgressBarDataUpdate: true,
      location: this.props.location,
      locationKeys: [],
      mainPageClass: '',
      pathname: '',
      previousRoute: this.props.location.pathname,
      progressBarFields: get(this.props, 'route.fields.progressBar'),
      progressBarHeight: 0,
      routeName: getRouteName(this.props.location.pathname),
      searchResultsHref: '',
      slot1: this.props.route, // previous page in terms of animation
      slot1Class: PAGE_CLASS.current,
      slot1PageOffset: 0,
      slot2: null, // next page in terms of animation
      slot2Class: PAGE_CLASS.next,
      slot2PageOffset: 0,
      stepperIsRelative: false,
    };

    this.hookCall = this.hookCall.bind(this);
    this.setStepperIsRelative = this.setStepperIsRelative.bind(this);

    // TODO remove this after fixing of the Search by Coveo
    this.isCoveoInitialized = false;
    this.searchPagePath = this.getSearchPagePath();


  }

  render = () => {
    if (!isEditorActive()) {
      const coveoAuthentication=get(this.props, 'sitecoreContext.coveoAuthentication') 
      const engine = buildSearchEngine({
        configuration: {
          organizationId: coveoAuthentication?.organizationId ? coveoAuthentication?.organizationId :"default",
          accessToken: coveoAuthentication?.token,
        },
      });
      return (
        <AtomicSearchInterface
          engine={engine}
          languageAssetsPath={process.env.PUBLIC_URL + '/lang'}
          fieldsToInclude='["description","pagedescription","clickUri","concatenatedmodule","allowedroletez120xt","headline","searchresultdescription","excerpt","opengraphimage","courselevel","creditsavailable","status","sfeventapidisplaynamec","ez120xtramuralprojects","aocountry","region","veterinarysubject","isnew","containsvideo","division","aolanguage","thumbnailuri","type","sfformatc","pubdate","consultants","videonumber","progress","sfvenuecityc","level","startdate","enddate","description","date","applicants","aogrants","researchprograms","image","appliance","solutiondate","regions","partsoftheregion","solutiongroups","description","searchresultdescription","contenttype","concatenatedmodule","diagnosis","module","allowedroles"]'
          localization={(i18n) => {
            i18n.addResourceBundle("en", "translation", {
              "search": "Search... (approaches, preparations, etc)"
            });
          }}
          onReady={this.handleLoad}
        >
          <div {...this.paywall()}>
            <Meta {...this.props} isDisconnectedMode={isDisconnectedMode(this.props)} />
            {/*
          VisitorIdentification is necessary for Sitecore Analytics to determine
          if the visitor is a robot.
          If Sitecore XP (with xConnect/xDB) is used, this is required or else
          analytics will not be collected for the JSS app.
          For XM (CMS-only) apps, this should be removed.

          VI detection only runs once for a given analytics ID, so this is not
          a recurring operation once cookies are established.
        */}
            <VisitorIdentification />
            <ProgressBarContext.Provider
              value={{
                setStepperIsRelative: this.setStepperIsRelative,
                stepperIsRelative: this.state.stepperIsRelative,
              }}
            >
              <ScreenClassProvider>
                {this.getHeader() && this.getStickyHeader()}
                {this.getStickyProgressBar()}
                {/* root placeholder for the app, which we add components to using route data */}
                {/* <SearchResults /> */}
                <Container fluid={true} className={this.getContainerClasses()}>
                  <DetailStickyTopContext.Provider
                    value={{
                      anchorSelectorHeight: this.state.anchorSelectorHeight,
                      detailStickyTopHeight: this.state.detailStickyTopHeight,
                      headerHeight: this.state.headerHeight,
                      hookCall: this.hookCall,
                      progressBarHeight: this.state.progressBarHeight,
                    }}
                  >
                    <FontContext.Provider value={this.state.isFontLoaded}>{this.getAnimationHolder()}</FontContext.Provider>
                  </DetailStickyTopContext.Provider>
                </Container>
                <Footer
                  data={isDisconnectedMode(this.props) ? this.props.route.footer : this.props.sitecoreContext.footer}
                />
              </ScreenClassProvider>
            </ProgressBarContext.Provider>

            {this.getCookieScript()}
          </div>
        </AtomicSearchInterface>
      );
    }
    else {
      return (
        <div {...this.paywall()}>
          <Meta {...this.props} isDisconnectedMode={isDisconnectedMode(this.props)} />
          {/*
          VisitorIdentification is necessary for Sitecore Analytics to determine
          if the visitor is a robot.
          If Sitecore XP (with xConnect/xDB) is used, this is required or else
          analytics will not be collected for the JSS app.
          For XM (CMS-only) apps, this should be removed.

          VI detection only runs once for a given analytics ID, so this is not
          a recurring operation once cookies are established.
        */}
          <VisitorIdentification />
          <ProgressBarContext.Provider
            value={{
              setStepperIsRelative: this.setStepperIsRelative,
              stepperIsRelative: this.state.stepperIsRelative,
            }}
          >
            <ScreenClassProvider>
              {this.getHeader() && this.getStickyHeader()}
              {this.getStickyProgressBar()}
              {/* root placeholder for the app, which we add components to using route data */}
              {/* <SearchResults /> */}
              <Container fluid={true} className={this.getContainerClasses()}>
                <DetailStickyTopContext.Provider
                  value={{
                    anchorSelectorHeight: this.state.anchorSelectorHeight,
                    detailStickyTopHeight: this.state.detailStickyTopHeight,
                    headerHeight: this.state.headerHeight,
                    hookCall: this.hookCall,
                    progressBarHeight: this.state.progressBarHeight,
                  }}
                >
                  <FontContext.Provider value={this.state.isFontLoaded}>{this.getAnimationHolder()}</FontContext.Provider>
                </DetailStickyTopContext.Provider>
              </Container>
              <Footer
                data={isDisconnectedMode(this.props) ? this.props.route.footer : this.props.sitecoreContext.footer}
              />
            </ScreenClassProvider>
          </ProgressBarContext.Provider>

          {this.getCookieScript()}
        </div>
      );
    }
  }
  //};

  reloadCookiePolicy() {
    if (window.OneTrust) {
      window.OneTrust.initializeCookiePolicyHtml();
    }
  }

  componentDidUpdate() {
    document.body.removeEventListener('click', this.handleGlobalClicks);
    document.body.addEventListener('click', this.handleGlobalClicks);

    if (!getIsNotDesktop()) {
      fireEvent(window, 'animationEnd');
      this.isBackNavigation = false;
      window.scrollTo(0, 0);
      fireEvent(window, 'animationEnd');
      fireEvent(window, 'scrollToCard');
    }

    this.reloadCookiePolicy();
  }

  paywall() {
    const restrictedContentClass =
      get(this.props, 'sitecoreContext.allowSearchBotToCrawlContent') &&
        get(this.props, 'sitecoreContext.isrestrictedpage')
        ? RESTRICTED_CONTENT_CLASS
        : '';

    return {
      ...(restrictedContentClass && {
        className: restrictedContentClass,
      }),
    };
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleGlobalClicks);
    if (this.delay) {
      clearTimeout(this.delay);
    }
  }

  shouldComponentUpdate(nextProps) {

    const isNoUpdate = this.props.route.itemId === nextProps.route.itemId;

    return !isNoUpdate;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const isRouteUpdate = nextProps.route.itemId && this.props.route.itemId === nextProps.route.itemId;

    if (isRouteUpdate) {

      // const hasPageUrlSearchParameters =
      //   window.location.hash.includes('#q=') || window.location.hash.includes('#sort=');
      // if (isSearchPage && hasPageUrlSearchParameters) {
      //   this.setState({
      //     searchResultsHref: window.location.href,
      //   });
      // }

      // if (isSearchPage && this.props.location.pathname !== nextProps.location.pathname) {
      //   window.location.reload(true);
      // }
    }

    if (!isRouteUpdate) {
      updateGoogleTagManagerPageInfo(nextProps, true);
      this.addPreviousPage();
      this.handleCardsReset(nextProps);
      this.handleStepsHolderUpdate(nextProps);
      this.handleProgressBarUpdate(nextProps);
      this.onPageAnimationStart();
      this.handlePageAnimation(nextProps);
    }
  }

  addPreviousPage = () => {
    const { url } = this.props.sitecoreContext;
    if (!url) {
      return;
    }

    const { pathname } = new URL(url);
    fireEvent(window, 'previousPageUpdate', { data: pathname.substr(1) });
  };

  handleCardsReset = nextProps => {
    this.handleCardReset(nextProps, COMPONENT_KEY.diagnosisCard, LAYOUT.diagnosis);
    this.handleCardReset(nextProps, COMPONENT_KEY.treatment, LAYOUT.indication);
    this.handleCardReset(nextProps, COMPONENT_KEY.informationReferenceCard, LAYOUT.informationReference);
  };

  handleCardReset = (nextProps, cardName, layout) => {
    const isNeedToResetCard = isNotPage(this.props.route, layout) && isNotPage(nextProps.route, layout);

    if (isNeedToResetCard) {
      removeFromSessionStorage(cardName, loadFromSessionStorage(cardName));
    }
  };

  handleGlobalClicks = event => {
    this.handleAnchorLinkClick(event);

    // TODO remove this after fixing of the Search by Coveo
    const isSearchButtonClicked = event.target.classList.contains('CoveoSearchButton');
    const isSearchOptionClicked =
      get(event, 'target.id', '').includes('magic-box-suggestion-') ||
      get(event, 'target.parentElement.id', '').includes('magic-box-suggestion-');
    const isRedirectToSearch = isSearchButtonClicked || isSearchOptionClicked;
    if (isRedirectToSearch) {
      this.redirectToSearchPage();
    }
  };

  getSearchPagePath = () => {
    // const sitecoreGlobalSearchPath = 'route.placeholders.global-search';
    // const sitecoreCoveoExternalComponentsSectionName = 'CoveoExternalComponentsSection';
    // const sitecoreJssCoveoUiExternalComponentsPath = 'placeholders.jss-coveo-ui-external-components';
    // const sitecoreCoveoSearchboxName = 'CoveoSearchbox';
    // const sitecoreSearchPageUriPath = 'fields.properties.value.searchPageUri';

    // const sitecoreCoveoExternalComponentsSection = get(this.props.sitecoreContext, sitecoreGlobalSearchPath, []).find(
    //   el => el.componentName === sitecoreCoveoExternalComponentsSectionName
    // );
    // const sitecoreCoveoSearchbox = get(
    //   sitecoreCoveoExternalComponentsSection,
    //   sitecoreJssCoveoUiExternalComponentsPath,
    //   []
    // ).find(el => el.componentName === sitecoreCoveoSearchboxName);
    // const searchPagePath = get(sitecoreCoveoSearchbox, sitecoreSearchPageUriPath, '');
    const searchPagePath = get(this.props, 'sitecoreContext.header.searchPagePath', '');
    return searchPagePath.toLowerCase();
  };

  redirectToSearchPage = () => {
    const searchbox =
      document.querySelector('input[role="searchbox"]') || document.querySelector('input[role="combobox"]');
    const searchKey = searchbox.value;
    const searchHash = searchKey === '' ? '' : `#q=${searchKey}`;
    const location = window.location;
    const { pathname, hash } = location;

    if (this.props.location.pathname !== this.searchPagePath) {
      searchPage.savePrevPage(pathname, hash);
      //location.href = `${location.protocol}//${location.host}${this.searchPagePath}${searchHash}`;
    }
  };

  handleInternalLink = event => {
    const isRedirect = !getIsActionButton(event) && getIsNeedToRedirect(event);

    // const isSearchPage = this.props.location.pathname === this.searchPagePath;
    // if (!isSearchPage) {
    //   //document.querySelector('atomic-search-box').shadowRoot.querySelector('input').value = '';
    //   if (document.querySelector('atomic-search-box').shadowRoot.querySelector('button atomic-icon').part.contains('clear-icon')) {
    //     //document.querySelector('atomic-search-box').shadowRoot.querySelector('button').click();
    //     document.querySelector('atomic-search-box').shadowRoot.querySelector('input').value = '';
    //     document.querySelector('atomic-search-box').shadowRoot.querySelector('button').style.display='none';
    //     const searchInterface = document.querySelector('atomic-search-interface');
    //     searchInterface.executeFirstSearch();
    //   }
    // }

    if (isRedirect) {
      const { pathname, hash } = new URL(get(event, 'target.href', ''));
      searchPage.handleClick(event);
      deck.handleClick(event);
      // Mobile Safari doesn't allow updating the hash more than 100 times per 30 seconds.
      this.delay = delay(() => {
        this.props.navigate({
          hash: hash,
          pathname: pathname,
        });

      }, historyUpdateTimeout);


      event.preventDefault();
    }


  };

  handleExternalLink = event => {
    if (getIsExternalLink(event)) {
      event.preventDefault();
      const hasNoTarget = !get(event, 'target').hasAttribute('target');
      const target = getTargetAttribute(event, 'target');
      const href = getTargetAttribute(event, 'href');
      const isNewWindow = target || hasNoTarget;

      if (isNewWindow) {
        window.open(href, target);
      } else {
        window.location.href = href;
      }
    }
  };

  handleAnchorLinkClick = event => {
    this.handleInternalLink(event);
    this.handleExternalLink(event);
  };

  updateProgressBarFieldsState(progressBarFields) {
    this.setState({
      progressBarFields,
    });
  }

  handleProgressBarUpdate(nextProps) {
    const progressBarFields = get(nextProps, 'route.fields.progressBar');
    if (!progressBarFields) {
      this.updateProgressBarFieldsState(null);
    }
    const isDesktopUpdate = document.documentElement.clientWidth >= md && progressBarFields;
    // if (isDesktopUpdate) {    <-- commented to fix https://jira.valtech.com/browse/AOF-1616 on mobile
    this.updateProgressBarFieldsState(progressBarFields);
    this.setState({
      routeName: getRouteName(this.props.location.pathname),
    });
    // }
  }

  getContainerClasses() {
    return `layout_base layout
      ${this.get404Class()}
      ${this.getCookiePolicyClass()}
      ${this.getHeaderTitleClass()}
      ${this.getFullWidthClass()}
    `;
  }

  getFullWidthClass() {
    const isFullWidth = isRestrictedPage(this.props);
    return isFullWidth ? 'layout_full-width' : '';
  }

  get404Class() {
    const page404Name = '404 page';
    return get(this.props, 'route.name') === page404Name ? 'layout_404' : '';
  }

  getCookiePolicyClass() {
    const cookiePolicyPageName = 'Cookie policy';
    return get(this.props, 'route.name') === cookiePolicyPageName ? 'layout_cookiePolicy' : '';
  }

  getLayoutTypeClass(pageType) {
    return get(this.state, `${pageType}.fields.progressBar`) ? 'layout_main' : 'layout_details';
  }

  getStepperPositionClass() {
    return this.state.stepperIsRelative ? 'relative_stepper' : '';
  }

  getLastSlot() {
    const { length, [length - 1]: lastSlot } = this.slots;
    return lastSlot;
  }

  onAnimationEndWrapper(that, event) {
    if (PAGES_ANIMATION_NAMES.includes(event.animationName)) {
      this.slots.reverse();
      const lastSlot = this.getLastSlot();

      this.setState({
        ...(lastSlot && { [lastSlot]: null }),
        animationLeft: false,
        animationRight: false,
        slot1Class: this.isDefaultSlotsOrder() ? PAGE_CLASS.current : PAGE_CLASS.next,
        slot2Class: this.isDefaultSlotsOrder() ? PAGE_CLASS.next : PAGE_CLASS.current,
      });

      if (get(this.animationHolder, 'current')) {
        this.animationHolder.current.classList.add(CLASS_ANIMATION_END);
      }
      this.hookCall();
      that.onPageAnimationEnd();
    }
    fireEvent(window, 'animationEnd');
    this.isBackNavigation = false;
  }

  handleStepsHolderUpdate = nextProps => {
    const getLength = route => `${route}`.split(slash).filter(segment => !!segment).length;
    const actualFirstStepNumber = '0';
    const requiredFirstStepNumber = '1';
    const replaceZeroStepToFirst = className => className?.replace(actualFirstStepNumber, requiredFirstStepNumber);
    const prevSegments = getLength(this.state.previousRoute);
    const nextSegments = getLength(nextProps.location.pathname);

    if (get(this.stepsHolder, 'current')) {
      this.stepsHolder.current.className = replaceZeroStepToFirst(`step-${prevSegments}-${nextSegments}`);
    }
  };

  isDefaultSlotsOrder = () => {
    return this.slots[0] === SLOT.first;
  };

  handlePageAnimation = nextProps => {
    const animationLeft = this.getClassDirection(nextProps) === MOVE.backward;
    const animationRight = this.getClassDirection(nextProps) === MOVE.forward;
    const freeSlot = this.state[SLOT.first] ? SLOT.second : SLOT.first;
    const currentSlot = this.state[SLOT.first] ? SLOT.first : SLOT.second;
    const updateState = (left, right, props) => {
      const slotToUpdate = ((left || right) && (this.isDefaultSlotsOrder() ? SLOT.second : SLOT.first)) || '';

      this.setState({
        ...(slotToUpdate && { [slotToUpdate]: props }),
        ...(slotToUpdate && { [`${slotToUpdate}PageOffset`]: this.scrollTop }),
        ...(!slotToUpdate && { [currentSlot]: props }),
        ...(!slotToUpdate && { [freeSlot]: null }),
        animationLeft: left,
        animationRight: right,
        location: this.props.location,
        previousRoute: cloneDeep(this.props.location.pathname),
      });
    };

    if (getIsNotDesktop()) {
      updateState(animationLeft, animationRight, nextProps.route);
      this.setAnimatedClasses(animationLeft, animationRight);
    } else {
      this.setState({
        [currentSlot]: nextProps.route,
        [freeSlot]: null,
      });
      this.setAnimatedClasses(false, false);
      window.scrollTo(0, 0);
    }
  };

  getStickyProgressBar = () => {
    return (
      <ProgressBarHolder
        addClassSitecore={this.state.inEditMode}
        progressBar={this.state.progressBarFields}
        stepsHolder={this.stepsHolder}
        location={this.state.location}
        routeName={this.state.routeName}
        onAnimationEnd={this.onAnimationEnd}
      />
    );
  };

  getStickyHeader = () => {
    return (
      <>
        <Header
          addClassSitecore={this.state.inEditMode}
          fields={this.getHeader()}
          headerUpdated={this.headerUpdated}
          isAnimation={this.state.isAnimation}
          searchCategories={getSearchCategories(this.props)}
        ></Header>
        {!get(this.props, 'sitecoreContext.pageEditing') && (
          <div
            style={{
              height: '6rem',
            }}
            ref={this.headerPlaceholder}
          />
        )}
      </>
    );
  };

  getPlaceholders = () =>
    this.slots.map(slot => (
      <SlotPlaceHolder
        key={slot}
        slot={slot}
        state={this.state}
        className={`${slot}
        ${this.state[`${slot}Class`]}
        ${this.getLayoutTypeClass(slot)}
        ${this.getStepperPositionClass()}`}
      />
    ));

  getAnimationHolder = () => {
    return (
      <div ref={this.animationHolder} className={this.getAnimationHolderCssClasses()}>
        <CSSTransition timeout={0}>
          <div
            ref={this.animationWrapper}
            onAnimationEnd={event => {
              this.onAnimationEndWrapper(this, event);
            }}
            className={CLASS_ANIMATION_WRAPPER}
          >
            {this.getPlaceholders()}
          </div>
        </CSSTransition>
      </div>
    );
  };

  getAnimationHolderCssClasses = () => {
    return `animation-holder
    ${this.state.animationRightClass}
    ${this.state.animationLeftClass}
    ${this.state.animationEndClass}
    `;
  };

  setAnimatedClasses = (animationLeft, animationRight) => {
    const animationHolder = get(this.animationHolder, 'current');
    if (!animationHolder) {
      return;
    }
    const animationRightClass = animationRight ? CLASS_ANIMATED_RIGHT : '';
    const animationLeftClass = animationLeft ? CLASS_ANIMATED_LEFT : '';
    const animationEndClass = !animationLeft && !animationRight ? CLASS_NOT_ANIMATED : '';
    const classes = [animationLeftClass, animationRightClass, animationEndClass];
    const isUpdate = classes.some(item => !!item);

    if (isUpdate) {
      this.setState({ animationEndClass, animationLeftClass, animationRightClass });
    }
  };

  handleToggleOverflow = data => {
    const overflowClass = 'is-mobile-overflow';
    const isOverflowEnabled = get(data, 'data.data');
    const bodyClass = document.body.classList;

    if (isOverflowEnabled) {
      bodyClass.add(overflowClass);
    } else {
      bodyClass.remove(overflowClass);
    }
  };

  componentDidMount = () => {

    window.addEventListener('toggleOverflow', this.handleToggleOverflow);
    window.addEventListener('backToPage', this.handleBackToPage);

    const isEditMode = get(this.props, 'sitecoreContext.pageEditing');
    if (isEditMode) {
      this.setState({
        inEditMode: true,
      });
    }


    // TODO remove this after fixing of the Search by Coveo
    document.body.addEventListener('afterInitialization', () => {
      fireEvent(window, 'afterInitializationTrigger');
      this.isCoveoInitialized = true;
      const searchInput =
        document.querySelector('input[role="searchbox"]') || document.querySelector('input[role="combobox"]');
      // TODO remove after Coveo upgrade
      // temporary workaround for an issue AOF-2038

      if (!searchInput) {
        return;
      }

      searchInput.addEventListener('input', event => {
        const searchValueWithoutFalseSymbols = searchInput.value?.replace(/<(?![\d\s])/g, '< ');
        searchInput.value = searchValueWithoutFalseSymbols;
      });
      searchInput.addEventListener('keydown', event => {
        if (event.keyCode === 13) {
          if (!isEditorActive())
            this.redirectToSearchPage();
        }
      });
    });



    googleTagManagerInit(this.props);

    updateGoogleTagManagerPageInfo(this.props, false);

    this.loadWebFonts();

  };

  loadWebFonts = () => {
    loadWebFonts(isDisconnectedMode(this.props), () => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          // Used for IE11
          this.setState({
            isFontLoaded: true,
          });
          this.updateDetailImageOffset();
          this.forceUpdate();
        });
      });
    });
  };

  updateHeaderOffset = () => {
    const headerOffset = getOffset('header');
    const headerPlaceholder = get(this.headerPlaceholder, 'current');
    const sitecoreContext = get(this.sitecoreContext, '.pageEditing');

    if (headerPlaceholder) {
      if (!sitecoreContext) {
        headerPlaceholder.style.height = `${headerOffset}px`;
        this.updateProgressBarOffset(headerOffset);
      }
    }
  };

  updateProgressBarOffset = headerOffset => {
    const progressBarHolder = get(this.stepsHolder, 'current');

    if (progressBarHolder) {
      progressBarHolder.style.top = `${headerOffset}px`;
    }
  };

  hookCall = () => {
    this.updateDetailImageOffset();
    this.forceUpdate();
  };

  setStepperIsRelative = value => {
    this.setState({ stepperIsRelative: value });
    this.forceUpdate();
  };

  updateDetailImageOffset = () => {
    const headerHeight = getOffset('header__wrapper');
    const detailStickyTopHeight = getOffset('detail-sticky-top');
    const anchorSelectorHeight = getOffset('anchor-selector');
    const progressBarHeight = getOffset('progressbar-wrapper');

    this.setState({
      anchorSelectorHeight,
      detailStickyTopHeight,
      headerHeight,
      progressBarHeight,
    });
  };

  getHeader = () => {
    return get(this.props, 'sitecoreContext.header');
  };

  headerUpdated = () => {
    this.updateHeaderOffset();

    const progressBarHolder = get(this.stepsHolder, 'current');
    if (!progressBarHolder) {
      return;
    }
  };

  handleLoad = () => {
    const searchInterface = document.querySelector('atomic-search-interface');
    searchInterface.executeFirstSearch();
    this.headerUpdated();
  }
  getProgressBar = () => {
    return get(this.props, 'route.fields.progressBar');
  };

  updatePathName = ({ pathname }) => {
    if (typeof window !== 'undefined') {
      this.setState({
        pathname,
      });
    }
  };

  detectBrowserBack = (location, action) => {
    if (action === 'PUSH') {
      this.setState({ locationKeys: [location.key] });
    }

    if (action === 'POP') {
      if (this.state.locationKeys[1] === location.key) {
        const updatedKeys = this.state.locationKeys.slice(1);
        this.setState({ locationKeys: [...updatedKeys] });
      } else {
        updateGoogleTagManagerPageInfo(this.props, false, true);
        this.setState({ locationKeys: [location.key, ...this.state.locationKeys] });
      }
    }
  };

  getProgressBarFields = () => {
    return get(this.props, 'route.fields.progressBar');
  };

  onPageAnimationStart = () => {
    const stepsHolderClasses = get(this.stepsHolder, 'current.classList');
    if (stepsHolderClasses) {
      stepsHolderClasses.remove(STEPS_ANIMATION_END_CLASS);
      stepsHolderClasses.add(STEPS_ANIMATION_START_CLASS);
    }

    if (get(this.animationHolder, 'current')) {
      this.animationHolder.current.classList.remove(`${CLASS_ANIMATION_END}`);
    }
    this.setState({ isAnimation: true });
    this.scrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop || 0;
    fireEvent(window, 'animationStart');
  };

  onPageAnimationEnd = () => {
    window.scrollTo(0, 0);
    this.setState({
      isAnimation: false,
      routeName: getRouteName(this.props.location.pathname),
      slot1PageOffset: 0,
      slot2PageOffset: 0,
    });
    this.updateProgressBarFieldsState(this.getProgressBarFields());
    fireEvent(window, 'scrollToCard');
    this.forceUpdate();
  };

  onAnimationEnd = () => {
    this.stepsHolder.current.classList.add(STEPS_ANIMATION_END_CLASS);
    this.stepsHolder.current.classList.remove(STEPS_ANIMATION_START_CLASS);
  };

  handleBackToPage = () => {
    this.isBackNavigation = true;
  };

  getClassDirection = nextProps => {
    const prevStep = getStepFromRoute(this.props.route);
    const nextStep = getStepFromRoute(nextProps.route);
    const progressBarSteps = [1, 2, 3, 4];
    const prevPath = this.state.previousRoute.split(slash).length;
    const currentPath = this.props.location.pathname.split(slash).length;
    const bothProgressBarSteps = progressBarSteps.includes(prevStep) && progressBarSteps.includes(nextStep);

    let direction = '';

    switch (true) {
      case prevPath > currentPath && bothProgressBarSteps: // use of the route to detect direction for steps 1-4
      case prevStep === 5 && progressBarSteps.includes(nextStep): // if current page 5 and next page (Step 1-4)
      case prevStep !== 1 && nextStep === 1: // if current any page and next page home
      case prevStep !== 6 && nextStep === 6 && this.isBackNavigation: // if we came to search and go to the results through back button
      case prevStep === 7 && nextStep !== 7: // if we came to restricted and go to the other page
      case prevStep === 5 && nextStep === 5 && this.isBackNavigation: // if current page step 5 and next step 5 using arrow back or close description
        direction = MOVE.backward; // backward animation
        break;
      case prevPath < currentPath && bothProgressBarSteps: // use of the route to detect direction for steps 1-4
      case prevStep === 1 && nextStep !== 1: // if current home page and next any page
      case progressBarSteps.includes(prevStep) && nextStep === 5: // if current page (steps 1-4) and next step 5
      case prevStep !== 6 && nextStep === 6: // if any current page and next Search (step 6)
      case prevStep !== 7 && nextStep === 7: // if any current page and next Restricted (step 7)
      case prevStep === 5 && nextStep === 5: // if current page step 5 and next step 5
        direction = MOVE.forward; // forward animation
        break;
    }

    return direction;
  };

  getCookieScript = () => {
    return <InnerHTML html={get(this.props, 'sitecoreContext.cookiePro.cookieProScript', '<div></div>')} />;
  };

  getHeaderTitleClass = () => {
    return get(this.getHeader(), 'headline.value', '') ? 'layout_header-title' : '';
  };
}

export default withSitecoreContext()(withRouter(withStyles(s)(Layout)));
