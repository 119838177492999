import React from 'react';
import get from 'lodash/get';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { ILegendBaseProps as IProps } from './Interfaces/Sitecore.props';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Legend.scss';

class Legend extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }
  public render = () => {
    const skills = get(this.props, 'fields.skillLevelList', []);
    const equipments = get(this.props, 'fields.equipmentList', []);

    return (
      <div className="Legend">
        <Text tag="h2" field={get(this.props, 'fields.heading', {})} />
        <div className="gauge__label">{get(this.props, 'fields.skillLevelHeading.value', '')}</div>
        <table>
          <tbody>
            {skills.map((item, key: number) => {
              const { description, level } = item.fields;
              return (
                <tr key={key}>
                  <td>
                    <ul className="list-no-style gauge__list">{this.renderLevel(level.value, skills.length)}</ul>
                  </td>
                  <td className="gauge__description">{description.value}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="gauge__label">{get(this.props, 'fields.equipmentHeading.value', '')}</div>
        <table>
          <tbody>
            {equipments.map((item, key: number) => {
              const { description, level } = item.fields;
              return (
                <tr key={key}>
                  <td>
                    <ul className="list-no-style gauge__list">{this.renderLevel(level.value, equipments.length)}</ul>
                  </td>
                  <td className="gauge__description">{description.value}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  private renderLevel(level?: string, count = 3): JSX.Element[] {
    const list: JSX.Element[] = [];

    for (let i = 0; i < count; i += 1) {
      list.push(<li key={i} className={`gauge__list-item ${i < Number(level) ? 'gauge__list-item_active' : ''}`} />);
    }

    return list;
  }
}

export default withStyles(s)(Legend);
