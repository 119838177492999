import { Visible, Hidden, Row, Col } from 'react-grid-system';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { IProps } from './Interfaces/Component.props';
import OnImagesLoaded from 'react-on-images-loaded';
import { fireEvent, getRenderingWithNumbers, getMobileRendering } from '../../helpers/utils';
import Sticker from 'react-stickyfill';
import { onImagesLoadedTimeout } from '../../constants';
import NoIndex from '../NoIndex';
import TreatmentDetailsPageContext from './TreatmentDetailsPageContext';
import ProgressBarContext from '../../ProgressBarContext';

class StyleguideLayoutTreatmentDetailsPage extends React.Component<IProps, {}> {
  public static contextType = ProgressBarContext;
  constructor(props: IProps) {
    super(props);
  }

  public componentDidMount() {
    this.context.setStepperIsRelative(true);
  }

  public componentWillUnmount() {
    this.context.setStepperIsRelative(false);
  }

  public render = () => {
    const { rendering } = this.props;
    const mobileRendering = getMobileRendering(rendering, 'treatment-details-page');

    return (
      <TreatmentDetailsPageContext.Provider value={{ isTreatmentDetailsPage: true }}>
        <Row className="flex wrapper treatment-details-page layout-row">
          <Visible xs={true} sm={true} md={true}>
            <Col xs={12}>
              <div className="content-holder">
                <Placeholder
                  name="treatment-details-page-head"
                  rendering={getMobileRendering(rendering, 'treatment-details-page-head')}
                />
                <OnImagesLoaded
                  onLoaded={() => {
                    fireEvent(window, 'deckImagesLoaded');
                  }}
                  timeout={onImagesLoadedTimeout}
                >
                  <div className="decks">
                    <Placeholder
                      name="treatment-details-page"
                      rendering={getRenderingWithNumbers(mobileRendering, 'treatment-details-page')}
                    />
                    {/* This image needed for firing event deckImagesLoaded */}
                    <img className="hidden" />
                  </div>
                </OnImagesLoaded>
              </div>
              <div className="widgets__wrapper">
                <NoIndex>
                  <Placeholder name="sidebar-top-layout" rendering={rendering} />
                </NoIndex>
                <div className="widgets">
                  <NoIndex>
                    <Placeholder name="treatment-details-sidebar-layout" rendering={rendering} />
                  </NoIndex>
                </div>
              </div>
              <div className="teasers">
                <NoIndex>
                  <Placeholder name="treatment-details-teasers-layout" rendering={rendering} />
                </NoIndex>
              </div>
              <Placeholder name="indication-navigation-layout" rendering={rendering} />
            </Col>
          </Visible>
          <Hidden xs={true} sm={true} md={true}>
            <Col md={3}>
              <Sticker>
                <div className="widgets__wrapper">
                  <NoIndex>
                    <Placeholder name="sidebar-top-layout" rendering={rendering} />
                  </NoIndex>
                  <div className="widgets">
                    <NoIndex>
                      <Placeholder name="treatment-details-sidebar-layout" rendering={rendering} />
                    </NoIndex>
                  </div>
                </div>
              </Sticker>
            </Col>
            <Col md={9}>
              <Placeholder name="treatment-details-page-head" rendering={rendering} />
              <OnImagesLoaded
                onLoaded={() => {
                  fireEvent(window, 'deckImagesLoaded');
                }}
                timeout={onImagesLoadedTimeout}
              >
                <div className="decks">
                  <Placeholder
                    name="treatment-details-page"
                    rendering={getRenderingWithNumbers(rendering, 'treatment-details-page')}
                  />
                  {/* This image needed for firing event deckImagesLoaded */}
                  <img className="hidden" />
                </div>
              </OnImagesLoaded>
              <div className="teasers">
                <NoIndex>
                  <Placeholder name="treatment-details-teasers-layout" rendering={rendering} />
                </NoIndex>
              </div>
              <Placeholder name="indication-navigation-layout" rendering={rendering} />
            </Col>
          </Hidden>
        </Row>
      </TreatmentDetailsPageContext.Provider>
    );
  };
}

export default StyleguideLayoutTreatmentDetailsPage;
