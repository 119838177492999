import React from 'react';
import get from 'lodash/get';
import Modal from 'react-modal';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Legend from '../Legend';
import { IProps } from './Interfaces/Component.props';
import { DATA, EVENT, CATEGORY, ACTION } from '../../analytics/constants';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './../Widget/Widget.scss';

interface IState {
  isModalOpen: boolean;
}

class WidgetLegend extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  public render = () => (
    <div className="widget">
      <Text tag="h2" field={get(this.props, 'fields.heading', {})} className="widget__headline" />
      <a
        className="widget__link"
        href="#"
        onClick={event => {
          event.preventDefault();
          this.toggleModal();
        }}
        {...this.getDataAttributes()}
      >
        <Text field={get(this.props, 'fields.button', {})} />
      </a>
      <Modal
        isOpen={this.state.isModalOpen}
        onRequestClose={() => this.toggleModal()}
        className="modal"
        overlayClassName="modal__overlay"
        contentLabel="Legend"
      >
        <button className="modal__close" onClick={() => this.toggleModal()}>
          <Text field={get(this.props, 'fields.close', {})} />
        </button>
        <Legend fields={get(this.props, 'fields.legendOverlay.fields')} />
      </Modal>
    </div>
  );

  private getDataAttributes = (): object => {
    const getFieldValue = (name: string): string => get(this.props, `fields.${name}.value`, '');
    const dataAttributes = {
      [DATA.event]: EVENT.click,
      [DATA.category]: CATEGORY.userInteraction,
      [DATA.action]: ACTION.popin,
      [DATA.label]: `${getFieldValue('heading')} - ${getFieldValue('button')}`,
    };

    return dataAttributes;
  };

  private toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }
}

export default withStyles(s)(WidgetLegend);
