import React from 'react';
import get from 'lodash/get';
import TruncatedContent from '../TruncatedContent';
import Video from '../Video';
import { IProps } from './Interfaces/Component.props';
import CommonLink from '../CommonLink';
import CommonImage from '../CommonImage';
import { DATA, EVENT, CATEGORY, ACTION } from '../../analytics/constants';
import { COMPONENT_KEY, detailPageImagesSizes } from '../../constants';
import { saveToSessionStorage, getUid, loadFromSessionStorage, scrollToRef } from '../../helpers/utils';
import DetailStickyTopContext from '../../DetailStickyTopContext';
import NoIndex from '../NoIndex';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './InformationReferenceCard.scss';

interface IState {
  uid: string;
}
class InformationReferenceCard extends React.Component<IProps, IState> {
  private informationReferenceCardElementRef;
  private isScrollLocked = false;

  constructor(props: IProps) {
    super(props);

    this.informationReferenceCardElementRef = React.createRef();

    this.state = {
      uid: getUid(props),
    };
  }

  public render = () => (
    <DetailStickyTopContext.Consumer>
      {offset => (
        <article
          className="information-reference-card"
          ref={this.informationReferenceCardElementRef}
          data-offset={offset.headerHeight}
        >
          <div className="information-reference-card__body">
            <div className="information-reference-card__copy">
              <header className="information-reference-card__header">
                {get(this.props, 'fields.heading.value') && (
                  <h2 className="information-reference-card__headline">
                    {get(this.props, 'fields.heading.value', '')}
                  </h2>
                )}
              </header>
              <div className="information-reference-card__text">
                <TruncatedContent fields={this.props.fields} dataAttributes={this.getDataAttributes()} />
              </div>
              {get(this.props, 'fields.link.value') && (
                <div className="information-reference-card__link">
                  <NoIndex>
                    <CommonLink
                      onClick={event => {
                        event.stopPropagation();
                        this.onClick();
                      }}
                      className="information-reference-card__copy-link_primary"
                      link={get(this.props, 'fields.link')}
                    >
                      {get(this.props, 'fields.linkText.value', '')}
                    </CommonLink>
                  </NoIndex>
                </div>
              )}
            </div>
            <div className="information-reference-card__visual">
              {get(this.props, 'fields.image.value.src') && (
                <CommonImage field={get(this.props, 'fields.image')} sizes={detailPageImagesSizes} />
              )}
              {get(this.props, 'fields.video.fields.src.value') && (
                <Video
                  title={get(this.props, 'fields.video.fields.title')}
                  src={get(this.props, 'fields.video.fields.src')}
                  id={get(this.props, 'fields.video.fields.kalturaId')}
                />
              )}
            </div>
          </div>
          <NoIndex>
            <footer className="information-reference-card__footer">
              {get(this.props, 'fields.link.value') && (
                <CommonLink
                  onClick={event => {
                    event.stopPropagation();
                    this.onClick();
                  }}
                  className="information-reference-card__footer-link_primary"
                  link={get(this.props, 'fields.link')}
                >
                  {get(this.props, 'fields.linkText.value', '')}
                </CommonLink>
              )}
            </footer>
          </NoIndex>
        </article>
      )}
    </DetailStickyTopContext.Consumer>
  );

  public componentDidMount() {
    window.addEventListener('scrollToCard', this.handleScrollToCard);
    window.addEventListener('animationStart', this.lockScroll);
    window.addEventListener('animationEnd', this.unlockScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener('scrollToCard', this.handleScrollToCard);
    window.removeEventListener('animationStart', this.lockScroll);
    window.removeEventListener('animationEnd', this.unlockScroll);
  }

  private lockScroll = () => {
    this.isScrollLocked = true;
  };

  private unlockScroll = () => {
    this.isScrollLocked = false;
  };

  private getDataAttributes = (): object => {
    return {
      [DATA.event]: EVENT.click,
      [DATA.category]: CATEGORY.userInteraction,
      [DATA.action]: ACTION.readFullIndication,
      [DATA.label]: get(this.props, 'fields.heading.value', ''),
    };
  };

  private handleScrollToCard = (): void => {
    const checkIsNeedToScroll = new Promise<void>(resolve => {
      if (!this.isScrollLocked) {
        resolve();
      }
    });

    checkIsNeedToScroll.then(() => {
      const isNeedToScroll = loadFromSessionStorage(`${COMPONENT_KEY.informationReferenceCard}`) === this.state.uid;

      if (isNeedToScroll) {
        scrollToRef(this.informationReferenceCardElementRef);
      }
    });
  };

  private onClick = (): void => {
    this.saveInformationReferenceCard();
  };

  private saveInformationReferenceCard = (): void => {
    saveToSessionStorage({
      [COMPONENT_KEY.informationReferenceCard]: this.state.uid,
    });
  };
}

export default withStyles(s)(InformationReferenceCard);
