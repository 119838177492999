/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import get from 'lodash/get';
import { srcsetRender, isIE, updateImgRender } from '../../helpers/utils';
import { ICommonImageFields } from './Interfaces/Sitecore.props';

const IS_IE = isIE();

const CommonImage = (props: ICommonImageFields) => {
  const { field, sizes, isNotLazy, ...otherProps } = props; // separate other props from field and sizes
  const loading = isNotLazy ? '' : 'lazy';

  const imgUrl = get(props, 'field.value.src');
  if (props.field && props.field.value && props.field.value.src) {
    props.field.value.src = imgUrl?.replace(/\/-\/media\//, '/-/jssmedia/');
  }

  return (
    <>
      {IS_IE ? (
        <img
          {...otherProps}
          {...get(props, 'field.value')}
          {...updateImgRender(sizes, get(props, 'field.value.src'))}
        />
      ) : (
        <img
          {...otherProps}
          {...get(props, 'field.value')}
          {...(loading && { loading })}
          {...updateImgRender(sizes, get(props, 'field.value.src'))}
          {...srcsetRender(sizes, get(props, 'field.value.src'))}
        />
      )}
    </>
  );
};

export default CommonImage;
