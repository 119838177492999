import React from 'react';
import get from 'lodash/get';
import { ScreenClassRender } from 'react-grid-system';
import { withRouter } from '../../lib/withRouter';
import { getFormattedIdFromUrl, getSimpleLink } from '../../helpers/utils';

import SkeletonItem from '../SkeletonItem';
import { IProps } from './Interfaces/Component.props';
import { ILink } from '../../interfaces/Link';
import CommonLink from '../CommonLink';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Skeleton.scss';

interface IState {
  isRendered: boolean;
}

class Skeleton extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isRendered: false,
    };
  }
  public render() {
    const { isRendered } = this.state;

    return (
      <>
        {!isRendered && ( // Links for SEO
          <div className="skeleton">
            <ul style={{ display: 'none' }}>
              {this.getLinksItems().map(item => {
                return (
                  <li key={item.text}>
                    <CommonLink link={getSimpleLink(item.url)}>{item.text}</CommonLink>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {isRendered && (
          <div className="skeleton skeleton_hidden" id={getFormattedIdFromUrl(`${this.getProp('url.value')}`)}>
            <ScreenClassRender
              render={(screenClass: string) =>
                !!screenClass && (
                  <SkeletonItem
                    screenClass={screenClass || ''}
                    url={this.getProp('url.value')}
                    overlayMessage={this.getProp('overlayMessage')}
                    closeLabel={this.getProp('closeLabel')}
                    signupText={this.getProp('signupText')}
                    signupUrl={this.getProp('signupUrl')}
                    loginText={this.getProp('loginText')}
                    loginUrl={this.getProp('loginUrl')}
                    idMapping={this.getProp('idMapping')}
                  />
                )
              }
            />
          </div>
        )}
      </>
    );
  }

  public componentDidMount() {
    this.setState({ isRendered: true }); // we need this to hide block for SSR
  }

  private getLinksItems = (): ILink[] => {
    const mappedItems = this.getProp('idMapping') || [];
    const getItem = (item: object, name: string): string => get(item, `fields.${name}.value`, '');
    const mappedLinks = mappedItems.map(mappedItem => {
      return {
        text: getItem(mappedItem, 'id'),
        url: getItem(mappedItem, 'link'),
      };
    });

    return mappedLinks;
  };

  private getProp = (name: string, fallback?: string): any => {
    const defaultValue = fallback ? '' : 0;
    return get(this.props, `fields.${name}`, defaultValue);
  };
}

export default withStyles(s)(withRouter(Skeleton));
