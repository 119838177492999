// polyfills from core-js
import 'react-app-polyfill/ie11';
import 'core-js/features/string/starts-with';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/object/keys';
import 'core-js/features/string/includes';
import 'core-js/features/string/repeat';
import 'core-js/features/set';
import 'core-js/features/map';
import 'core-js/features/url';
import 'core-js/features/url-search-params';
import 'core-js/features/number/parse-float';

// custom polyfills
import smoothscroll from 'smoothscroll-polyfill';
import 'classlist-polyfill';
import 'intersection-observer';
import 'element-closest-polyfill';
import 'polyfill-library/polyfills/__dist/Event/min';
import objectFitImages from 'object-fit-images';

smoothscroll.polyfill();
objectFitImages();
