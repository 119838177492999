import React from 'react';
import '../ContentBlock/ContentBlock.scss';
import { IVideoFields } from './Interfaces/Sitecore.props';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Video.scss';

const Video: React.FunctionComponent<IVideoFields> = ({ id, src, title }): React.ReactElement => (
  <div className="video">
    <iframe
      className="video__iframe"
      id={id.value}
      src={`${src.value}`}
      title={title.value}
      allowFullScreen={true}
      allow="autoplay *; fullscreen *; encrypted-media *;"
    />
  </div>
);

export default withStyles(s)(Video);
