import { legacy_createStore as createStore, applyMiddleware, compose, combineReducers, StoreEnhancer, Store } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from './store';
import { IApplicationState } from './interfaces/ApplicationState';

export default function configureStore(initialState?: IApplicationState): Store<IApplicationState> {
  const windowIfDefined = typeof window === 'undefined' ? null : (window as any);
  const devToolsExtension = windowIfDefined && (windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ as () => StoreEnhancer);
  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(
    ...enhancers,
    devToolsExtension ? devToolsExtension() : (f: any) => f
  );
  const store = createStore(buildRootReducer(reducers), initialState, composedEnhancers as any);

  return store;
}

function buildRootReducer(allReducers: any) {
  return combineReducers<IApplicationState>({ ...allReducers });
}
