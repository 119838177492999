import * as React from 'react';
import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import { deeply } from '../../helpers/utils';
import OpenGraph from '../OpenGraph';
import Helmet from 'react-helmet';
import { IProps } from './Interfaces/Component.props';
import OrganizationMicroformat from '../OrganizationMicroformat';
import RestrictedMicroformat from '../RestrictedMicroformat';

const DEFAULT_PAGE_DESCRIPTION = `AO Surgery Reference - quick online reference in clinical life.`;
const DEFAULT_PAGE_KEYWORDS = `AO, AO Surgery Reference, surgery, fracture, operative, surgical, fracture treatment, patient care`;
const DEFAULT_PAGE_TITLE = `AO Surgery Reference`;
const NO_ARCHIVE = 'noarchive';
const BOT = 'searchBotUser';

const getRobotsMeta = (robotsContent: string, isRestrictedAndBot: boolean): '' | JSX.Element => {
  const robots = isRestrictedAndBot ? NO_ARCHIVE : robotsContent;
  return robots && <meta name="robots" content={robots} />;
};

const Meta: React.FunctionComponent<IProps> = (props: IProps) => {
  const context = get(props, 'sitecoreContext');
  const canonicalUrl = get(context, 'canonical.canonicalUrl');
  const routeFields = get(props, 'route.fields', {});
  const pageTitle = get(routeFields, 'pageTitle.value', DEFAULT_PAGE_TITLE);
  const pageDescription = get(routeFields, 'pageDescription.value', DEFAULT_PAGE_DESCRIPTION);
  const pageKeywords = get(routeFields, 'pageKeywords.value', DEFAULT_PAGE_KEYWORDS);
  const openGraphData = deeply(mapKeys)(get(context, 'openGraph'), (v, k) => camelCase(k));
  const robotsContent = get(context, 'metaRobots.content', '');
  const googleSiteVerification = get(routeFields, 'googleSiteVerification.value');
  const isRestricted = get(context, 'allowSearchBotToCrawlContent') && get(context, 'isrestrictedpage');
  const userName = get(context, 'user.name');
  const isRestrictedAndBot = isRestricted && userName === BOT;
  const robotsMeta = getRobotsMeta(robotsContent, !!isRestrictedAndBot);

  return (
    <>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {pageDescription && <meta name="description" content={pageDescription} />}
        {pageKeywords && <meta name="keywords" content={pageKeywords} />}
        {robotsMeta && robotsMeta}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {googleSiteVerification && <meta name="google-site-verification" content={googleSiteVerification} />}
        {/* Disabling caching of HTML */}
        <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
      </Helmet>
      <OpenGraph {...openGraphData} />
      <OrganizationMicroformat
        data={
          props.isDisconnectedMode
            ? get(props, 'route.organizationMicroformat', { name: {}, url: { value: {} }, sameAs: [] })
            : get(props, 'sitecoreContext.organizationMicroformat', { name: {}, url: { value: {} }, sameAs: [] })
        }
      />
      {isRestricted && <RestrictedMicroformat id={get(context, 'url', '')} />}
    </>
  );
};

export default Meta;
