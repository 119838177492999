import { Visible, Hidden } from 'react-grid-system';
import { withRouter } from '../../lib/withRouter';
import * as React from 'react';
import get from 'lodash/get';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { IProps } from './interfaces/Component.props';
import delay from 'lodash/delay';
import { historyUpdateTimeout } from '../../constants';

class Step extends React.Component<IProps, {}> {
  private delay;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: any) {
    super(props);
  }

  public render = () => (
    <li
      data-category="User Interaction"
      data-action="Clicks new stepper"
      data-label={get(this.props, 'item.fields.label.value', '')}
      className={`progress-bar__item progress-bar__item_${get(this.props, 'stateClass', '')}`}
      onClick={this.onClick}
    >
      <Visible xs={true} sm={true}>
        <div className={`progress-bar__label_sm progress-bar__label_${get(this.props, 'stateClass', '')}-sm`}>
          <Text field={get(this.props, 'item.fields.shortLabel', {})} />
        </div>
      </Visible>
      <Hidden xs={true} sm={true}>
        <div
          className={`progress-bar__label_${get(
            this.props,
            'stateClass',
            ''
          )}-sm progress-bar__label progress-bar__label_${get(this.props, 'stateClass', '')}`}
        >
          <Text field={get(this.props, 'item.fields.label', {})} />
        </div>
      </Hidden>
    </li>
  );

  public componentWillUnmount() {
    if (this.delay) {
      clearTimeout(this.delay);
    }
  }

  private onClick = (): void => {
    if (get(this.props, 'isClickable')) {
      // Mobile Safari doesn't allow updating the hash more than 100 times per 30 seconds.
      // this.delay = delay(() => this.props.history.push(this.props.url), historyUpdateTimeout);
      this.delay = delay(() => this.props.navigate(this.props.url), historyUpdateTimeout);

      
    }
  };
}

export default withSitecoreContext()(withRouter(Step));
