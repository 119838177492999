import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import { useRef,useEffect, useState  } from 'react';
import get from 'lodash/get';
import { ICitationBaseProps} from './Interfaces/Sitecore.props';
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Citation.scss';
import copyicon from "../../assets/images/icons/file-copy-line.svg"
import copiedicon from "../../assets/images/icons/check-double-line.svg"

// Get current url
const CurrentPageURL = () => {
  const currentURL = window.location.href;

  return currentURL;
};
const CurrentDate = () => {
  const currentTimestamp = Date.now();
  const currentDate = new Date(currentTimestamp);
    // Format the date and time:
  const formattedDate = currentDate.toLocaleDateString();
  return formattedDate;
  }
 
// // Use styles for critical CSS


  const Citation: React.FC<ICitationBaseProps> = (props: ICitationBaseProps) => {
    const spanRef = useRef<HTMLSpanElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const authors = get(props, 'item.fields.CitationContent', []);
   
    const [currentURL3, setCurrentURL] = useState('');
    
    
    useEffect(() => {
      // Ensure the code runs only in the browser
      if (typeof window !== 'undefined') {
        setCurrentURL(window.location.href);
      }
    }, []);
    const copyrightText = get(props, 'fields.copyrightText.value', {});
    const accessedOnText = get(props, 'fields.accessedOnText.value', {});
    const iSSNContent = get(props, 'fields.iSSNContent.value', {});
    const inputvalue = copyrightText +" " + currentURL3 + " "+ accessedOnText + " " + CurrentDate()  + ". " +iSSNContent;
     // State to track the current image (initially set to 'copy')
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = () => {
    if (inputRef.current) {
       
      const texttocopy = inputRef.current?.value;
      navigator.clipboard.writeText(texttocopy)
        .then(() => {
          setIsCopied(true); // Set to true to show the tick image
          // Optional: Reset back to the copy image after a delay if needed
          setTimeout(() => setIsCopied(false), 6000); // Resets after 2 seconds
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    }
  };
 


 return (
  <div className='widget' style={{height: '100px'}}>
    <div className='inpCitation' style={{display: 'flex', flexDirection:'column'}}>
      <Text tag="h2" field={get(props, 'fields.heading', {})} className="citation__headline" />
      <div style={{display: 'flex'}}>
      <input className='citation_modal' ref={inputRef} value={inputvalue} readOnly />
      <button onClick={handleCopy} className='copy_button'>
        <img src={isCopied ? copiedicon : copyicon} alt={isCopied ? 'Copy Icon' : 'Tick Icon'}>
        </img></button>
      </div>
    </div>
    </div>
  );
};

export default withStyles(s)(Citation);

