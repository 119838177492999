import React, { MouseEvent } from 'react';
import get from 'lodash/get';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';
import throttle from 'lodash/throttle';
import delay from 'lodash/delay';
import { withRouter } from '../../lib/withRouter';
import { withSitecoreContext,ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import enhanceWithClickOutside from 'react-click-outside';
import {
  makeAnchorFromTitle,
  getPathSegments,
  slash,
  getIsNumberPrefixEnabled,
  getIsMac,
  scrollWithTransform,
  fireEvent,
} from '../../helpers/utils';
import { filterComponentsByName } from '../../helpers/sitecoreContext';
import { ReactComponent as ChevronIcon } from '../../assets/images/icons/right-chevron.svg';
import { IProps } from './Interfaces/Component.props';
import { IDecksIntersection } from './Interfaces/DeckIntersection';
import { breakpoints, historyUpdateTimeout } from '../../constants';
import InformationPageContext from '../Styleguide-Layout-InformationPage/InformationPageContext';
import DetailStickyTopContext, { IDetailStickyTopContext } from '../../DetailStickyTopContext';
import NoIndex from '../NoIndex';
import { ILayoutContext } from '../../interfaces/SitecoreContext';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './AnchorSelector.scss';

interface IState {
  opened: boolean;
  isTop: boolean;
  isTopTriggered: boolean;
  selectedAnchor: string;
  sitecoreContext: ILayoutContext | any;
}

const THROTTLE_DELAY = 50;
const ANCHOR_OFFSET = 10;

class AnchorSelector extends React.Component<IProps, IState> {
  public static contextType = DetailStickyTopContext;
  private anchorSelector;
  private decksIntersections: any = {};
  private urlHash;
  private decks: any[] = [];
  private allImagesLoaded;
  private deckIntersectionHash = '';
  private isHistoryUpdateInProgress = false;
  private delay;
  private URL;
  private topOfThePageListener;
  private notTopOfThePageListener;
  private isScrollLocked = false;
  private wasAutoScroll = false;

  constructor(props: IProps) {
    super(props);

    this.anchorSelector = React.createRef();

    this.topOfThePageListener = throttle(this.topOfThePageHandler, THROTTLE_DELAY);
    this.notTopOfThePageListener = throttle(this.notTopOfThePageHandler, THROTTLE_DELAY);

    this.state = {
      opened: false,
      isTop: false,
      isTopTriggered: false,
      selectedAnchor: '',
      sitecoreContext: {},
    };
  }

  public render() {
    const { opened } = this.state;
    const classOpened = opened ? 'opened' : '';
    const listDeckComponents =
      get(this.state, 'sitecoreContext.route') &&
      filterComponentsByName(this.state.sitecoreContext, 'DeckContainer', [
        'Styleguide-Layout-TreatmentDetailsPage',
        'Styleguide-Layout-InformationPage',
      ]);
    const listFilteredTitles = this.filterDeckComponents(listDeckComponents);
    const listTitles = listFilteredTitles && this.getTitlesList(listFilteredTitles);

    return (
      !!get(listTitles, 'length') && (
        <InformationPageContext.Consumer>
          {informationPageContext => {
            const isNumberPrefixEnabled = getIsNumberPrefixEnabled(informationPageContext, this.props);
            const selectedItemPrefix = `/${listTitles.length} – `;

            return (
              <DetailStickyTopContext.Consumer>
                {context => (
                  <div
                    className="anchor-selector"
                    style={{
                      top: `${this.getAnchorSelectorOffsetInRem(context)}`,
                    }}
                    ref={this.anchorSelector}
                    data-stepper-height={`${context.progressBarHeight}`}
                  >
                    <NoIndex>
                      <div className="anchor-selector__label" onClick={() => this.handleOpenedState(true)}>
                        {this.getAnchorText(listTitles, selectedItemPrefix, isNumberPrefixEnabled)}
                        <ChevronIcon className={classOpened} onClick={this.handleItemClick} />
                      </div>
                      <ul className={`anchor-selector__list ${classOpened}`}>
                        {listTitles.map((item: string, key: number) => {
                          const anchor = `#${makeAnchorFromTitle(item)}`;
                          const index = key + 1;
                          const prefix = isNumberPrefixEnabled ? `${index}${selectedItemPrefix}` : '';
                          const label = `${prefix}${item}`;

                          return (
                            <li key={key}>
                              <div
                                className={this.getAnchorSelectorItemClass(anchor)}
                                onClick={event => this.handleClick(event, anchor)}
                              >
                                {label}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </NoIndex>
                  </div>
                )}
              </DetailStickyTopContext.Consumer>
            );
          }}
        </InformationPageContext.Consumer>
      )
    );
  }

  public componentDidUpdate() {
    this.watchAndHandleUrlHash();
  }

  public componentDidMount() {
    window.addEventListener('deckIntersection', this.handleDeckIntersection);
    window.addEventListener('topOfThePage', this.topOfThePageListener);
    window.addEventListener('notTopOfThePage', this.notTopOfThePageListener);
    window.addEventListener('hashchange', this.handleHashChangeEvent);
    window.addEventListener('deckImagesLoaded', this.handleDeckItems);
    window.addEventListener('animationStart', this.lockScroll);
    window.addEventListener('animationEnd', this.unlockScroll);

    this.urlHash = new URL(window.location.href).hash || get(this.props, 'history.location.hash', '');

    this.setState({
      sitecoreContext: this.props.sitecoreContext,
    });
  }

  public componentWillUnmount() {
    window.removeEventListener('deckIntersection', this.handleDeckIntersection);
    window.removeEventListener('topOfThePage', this.topOfThePageListener);
    window.removeEventListener('notTopOfThePage', this.notTopOfThePageListener);
    window.removeEventListener('hashchange', this.handleHashChangeEvent);
    window.removeEventListener('deckImagesLoaded', this.handleDeckItems);
    window.removeEventListener('animationStart', this.lockScroll);
    window.removeEventListener('animationEnd', this.unlockScroll);

    // Mobile Safari doesn't allow updating the hash more than 100 times per 30 seconds.
    const timer = delay(() => {
      window.history.replaceState(null, document.title, window.location.pathname);
      if (timer) {
        clearTimeout(timer);
      }
    }, historyUpdateTimeout); // Clean up the hash

    if (this.delay) {
      clearTimeout(this.delay);
    }
  }

  private lockScroll = () => {
    this.isScrollLocked = true;
  };

  private unlockScroll = () => {
    this.isScrollLocked = false;
  };

  private topOfThePageHandler = () => {
    this.handleTopOfThePage(true);
  };

  private notTopOfThePageHandler = () => {
    this.handleTopOfThePage(false);
  };

  private getAnchorSelectorItemClass = (anchor: string): string => {
    const activeClass = `#${this.state.selectedAnchor}` === anchor && !this.state.isTop ? 'active' : '';

    return `anchor-selector__item ${activeClass}`;
  };

  private watchAndHandleUrlHash = (): void => {
    if (!this.urlHash || this.wasAutoScroll) {
      return;
    }

    const conditions = [
      this.isReadyForHashChange(),
      this.allImagesLoaded,
      !this.isScrollLocked,
      this.getDeck(this.getAnchor(this.urlHash)),
    ];
    const isReadyToScroll = conditions.every(condition => !!condition);

    if (isReadyToScroll) {
      this.changeHash(this.getAnchor(this.urlHash));
      this.wasAutoScroll = true;
    }
  };

  private getIsWindowTop = (): boolean => {
    return (window.pageYOffset || (document && document.documentElement.scrollTop)) === 0;
  };

  private getDecksContainer = (): any => {
    const placeholders = get(this.props, 'sitecoreContext.route.placeholders.jss-main[0].placeholders');

    return get(placeholders, 'treatment-details-page') || get(placeholders, 'information-page');
  };

  private isReadyForHashChange = (): boolean => {
    const deckContainerComponentName = 'DeckContainer';
    const decksContainer = this.getDecksContainer();
    const components =
      (decksContainer && decksContainer.filter(item => item.componentName === deckContainerComponentName)) || [];
    const componentsHeadlines = components.map(component =>
      makeAnchorFromTitle(get(component, 'fields.headline.value'))
    );
    const filteredDecks = this.decks.filter(deck => componentsHeadlines.includes(deck));
    const isAllDeckObservered = components && components.length === filteredDecks.length;

    return isAllDeckObservered && this.getIsWindowTop();
  };

  private handleDeckItems = () => {
    this.allImagesLoaded = true;
    this.watchAndHandleUrlHash();
  };

  private handleHashChangeEvent = (event: any): void => {
    const { newURL } = event;
    const hash = this.getAnchor(new URL(newURL).hash);
    this.changeHash(hash);
  };

  private changeHash = (hash): void => {
    this.handleHashChange(hash);
    this.updateHistory(`#${hash}`);
    this.setState({
      selectedAnchor: hash,
    });
  };

  private handleTopOfThePage = (isTopTriggered: boolean): void => {
    if (isTopTriggered) {
      this.updateHistory(window.location.pathname);
    } else {
      this.updateNotTopPageHash(isTopTriggered);
    }

    this.setState({
      isTopTriggered,
    });
  };

  private updateNotTopPageHash = (isTopTriggered: boolean): void => {
    const selectedAnchor = this.getTopVisibleAnchor();
    this.updateIntersection(selectedAnchor);

    this.setState({
      selectedAnchor,
      isTop: isTopTriggered,
    });
  };

  private updateIntersection = (anchor: string): void => {
    this.deckIntersectionHash = `#${anchor}`;
    this.updateHistory(this.deckIntersectionHash);
    if (anchor && !this.isScrollLocked) {
      fireEvent(window, 'hashChange', { data: `#${anchor}` });
    }
  };

  private getAnchor = (hash: string) => `${hash}`.replace('#', '');

  private getDecks = (): any => {
    const decksSelector = '.decks';
    return document.querySelector(decksSelector);
  };

  private getDeck = (anchor: string): any => {
    const deck = this.getDecks() && this.getDecks().querySelector(`[data-id="${anchor}"]`);

    return deck;
  };

  private handleHashChange = (hash): void => {
    const isNewHash = this.getAnchor(hash) !== this.state.selectedAnchor;
    const isValidHash = isNewHash && !!hash;

    if (isValidHash) {
      const anchor = this.getAnchor(hash);
      const deckTop = get(this.getDeck(anchor), 'offsetTop');
      const anchorSelector = get(this.anchorSelector, 'current');
      const { stepperHeight } = anchorSelector.dataset;

      if (anchorSelector) {
        const isMobile = window.innerWidth < breakpoints.md;
        const desktopOffset = Number(stepperHeight);
        const mobileOffset = -Number(stepperHeight);
        const stickyTopHeight = isMobile
          ? anchorSelector.offsetHeight + mobileOffset
          : anchorSelector.offsetHeight - desktopOffset;
        const offset = 2;
        const top = deckTop - stickyTopHeight + offset;

        this.scrollTo(top, this.getDecks());
      }
    }
  };

  private scrollTo = (top: number, base: Element | null): void => {
    if (getIsMac()) {
      // Remove that when Safari will be updated
      const duration = 200; // In ms
      scrollWithTransform({ top, base, duration });
    } else {
      window.scrollTo({ top, behavior: 'smooth' });
    }
  };

  private handleDeckIntersection = (data: any): void => {
    const {
      data: { event, anchor, path, deck },
    } = data;

    this.updateDecksIntersections({ event, anchor, path, deck });

    const isTop = this.getIsWindowTop();
    const selectedAnchor = isTop ? '' : this.getTopVisibleAnchor();
    const deckAnchor = selectedAnchor ? `#${selectedAnchor}` : '';
    const isNotDeckAnchor = !deckAnchor || isTop;
    const newHash = isNotDeckAnchor ? window.location.pathname : deckAnchor;

    const isNewDeckAnchor = !this.decks.some(item => item === anchor);
    if (isNewDeckAnchor) {
      this.decks.push(anchor);
    }

    if (!(isTop && newHash === window.location.pathname)) {
      if (this.deckIntersectionHash !== newHash) {
        this.updateHistory(newHash);
        this.deckIntersectionHash = newHash;
      }
    }

    this.setState({
      selectedAnchor,
      isTop,
    });
  };

  private historyChange = (): void => {
    if (this.isHistoryUpdateInProgress) {
      return;
    }

    this.isHistoryUpdateInProgress = true;
    window.history.replaceState(null, document.title, this.URL);
    this.delay = setTimeout(() => {
      this.isHistoryUpdateInProgress = false;
    }, historyUpdateTimeout);
  };

  private updateHistory = (hashOrUrl: string): void => {
    const isHash = hashOrUrl.includes('#');
    const url = isHash ? `${hashOrUrl}` : `${hashOrUrl}${window.location.search}`;
    const isNewUrl = url !== this.URL;

    if (isNewUrl) {
      this.URL = url;
      this.historyChange();
    }
  };

  private updateDecksIntersections = ({ event, anchor, path, deck }): void => {
    if (!event) {
      return;
    }

    const { isIntersecting, target } = event;
    const offsetTop = get(target, 'offsetTop', 0);
    this.decksIntersections = merge({}, this.decksIntersections, {
      [path]: {
        [anchor]: {
          isIntersecting,
          offsetTop,
          anchor,
          deck,
        },
      },
    });
  };

  private getTopVisibleAnchor = (): string => {
    const currentDeckIntersections = get(this.decksIntersections, `${getPathSegments().join(slash)}`, {});
    const intersectingDecks: IDecksIntersection[] | any[] = Object.values(currentDeckIntersections).filter(item =>
      get(item, 'isIntersecting')
    );
    const visibleItem: string = get(orderBy(intersectingDecks, ['offsetTop'], ['asc']), '[0]', '');
    const visibleItemAnchor: unknown = visibleItem.anchor;
    const anchorText: string = typeof visibleItemAnchor === 'string' ? visibleItemAnchor : '';
    const anchor = visibleItem ? anchorText : '';

    return anchor;
  };

  private handleItemClick = (e: MouseEvent): void => {
    this.toggleOpenedState();
    this.setState({ isTop: false });
  };

  private handleClick = (e: MouseEvent, anchor: string): void => {
    e.preventDefault();
    e.stopPropagation();
    this.handleItemClick(e);
    this.handleHashChange(anchor);
  };

  private toggleOpenedState = (): void => {
    this.setState(prevState => ({
      opened: !prevState.opened,
    }));
  };

  private handleClickOutside = (): void => {
    this.handleOpenedState(false);
  };

  private handleOpenedState = (isOpened: boolean): void => {
    this.setState({
      opened: isOpened,
    });
  };

  private filterDeckComponents = (titlesList: ComponentRendering[]) => {
    return titlesList && titlesList.filter(component => get(component, 'fields.isPresentInAncorNavigation.value'));
  };

  private getTitlesList = (decksList: ComponentRendering[]): string[] => {
    return decksList.map(component => get(component, 'fields.headline.value', ''), []).filter(headline => !!headline);
  };

  private getAnchorText = (items: string[], prefix: string, isNumberPrefixEnabled: boolean): string => {
    const getSelectedAnchor = (x: string) => `${makeAnchorFromTitle(x)}` === this.state.selectedAnchor;
    const text = items.find(getSelectedAnchor);
    const index = items.findIndex(getSelectedAnchor) + 1;
    const defaultLabel = get(this.props, 'fields.label.value');
    const anchorLabel = isNumberPrefixEnabled ? `${index}${prefix}${text}` : text;
    const anchorText = (this.state.isTop || index === 0 ? defaultLabel : anchorLabel) || defaultLabel;

    return anchorText ? anchorText : '';
  };

  private getAnchorSelectorOffsetInRem = (context): string => {
    const isMobile = window.innerWidth < breakpoints.md;
    const baseUnitInPx = 10;
    const unit = 'rem';
    const valueInPx = (isMobile ? 0 : context.progressBarHeight) + context.headerHeight + ANCHOR_OFFSET;

    return valueInPx ? `${valueInPx / baseUnitInPx}${unit}` : `0${unit}`;
  };
}

export default withRouter(withSitecoreContext()(enhanceWithClickOutside(withStyles(s)(AnchorSelector))));
