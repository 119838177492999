import React from 'react';
import get from 'lodash/get';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import '../Detail/Detail.scss';
import { IProps } from './Interfaces/Component.props';
import { makeAnchorFromTitle, fireEvent, getPathSegments, slash, getIsNumberPrefixEnabled } from '../../helpers/utils';
import DetailStickyTopContext, { IDetailStickyTopContext } from '../../DetailStickyTopContext';
import { ScreenClassRender } from 'react-grid-system';
import InformationPageContext, { IInformationPage } from '../Styleguide-Layout-InformationPage/InformationPageContext';
import { breakpoints } from '../../constants';

class DeckContainer extends React.Component<IProps> {
  public static contextType = DetailStickyTopContext;
  private observer;
  private deck;
  private deckContext;
  private isAnimation = false;

  constructor(props) {
    super(props);

    this.deck = React.createRef();
  }

  public render() {
    const headlineValue = this.getHeadline();

    return (
      <>
        <InformationPageContext.Consumer>
          {informationPageContext => (
            <DetailStickyTopContext.Consumer>
              {context => (
                <ScreenClassRender
                  render={(screenClass: string) =>
                    this.getRenderUsingContext({ context, informationPageContext, headlineValue, screenClass })
                  }
                />
              )}
            </DetailStickyTopContext.Consumer>
          )}
        </InformationPageContext.Consumer>
      </>
    );
  }

  public componentDidMount = (): void => {
    this.context.hookCall(); // Asking layout to remeasure offset values
    window.addEventListener('decksOffsetUpdate', this.updateObserver);
    window.addEventListener('notTopOfThePage', this.handleScroll);
    window.addEventListener('animationStart', this.handleAnimationStart);
    window.addEventListener('animationEnd', this.handleAnimationEnd);
    this.updateObserver();
  };

  public componentWillUnmount = (): void => {
    window.removeEventListener('decksOffsetUpdate', this.updateObserver);
    window.removeEventListener('notTopOfThePage', this.handleScroll);
    window.removeEventListener('animationStart', this.handleAnimationStart);
    window.removeEventListener('animationEnd', this.handleAnimationEnd);
    if (this.observer) {
      this.observer.disconnect();
    }
  };

  private handleAnimationStart = (): void => {
    this.isAnimation = true;
  };

  private handleAnimationEnd = (): void => {
    this.isAnimation = false;
  };

  private handleScroll = (): void => {
    this.updateObserver();
  };

  private getHeadline = (): string => get(this.props, 'fields.headline.value', '');

  private getRenderUsingContext = ({
    context,
    informationPageContext,
    headlineValue,
    screenClass,
  }: {
    context: IDetailStickyTopContext;
    informationPageContext: IInformationPage;
    headlineValue: string;
    screenClass: string;
  }): React.ReactElement => {
    const headline = get(this.props, 'fields.headline');
    const prefix = getIsNumberPrefixEnabled(informationPageContext, this.props)
      ? `${get(this.props, 'rendering.index', 0) + 1}. `
      : '';
    const headerText = `${prefix}${get(this.props, 'fields.headline.value')}`;

    this.deckContext = context;

    return (
      <div className="detail" data-id={makeAnchorFromTitle(headlineValue)} ref={this.deck}>
        {get(headline, 'value') ? <h2 className="detail__header">{headerText}</h2> : <div className="detail__header" />}

        <Placeholder name="deck-container" rendering={this.props.rendering} />
      </div>
    );
  };

  private handleIntersection = (event: any, anchor: string): void => {
    const isFireEventAllowed = window && !this.isAnimation;
    if (isFireEventAllowed) {
      fireEvent(window, 'deckIntersection', {
        anchor,
        event: get(event, '[0]'),
        path: getPathSegments().join(slash),
        deckContext: this.deckContext,
        deck: this.deck.current,
      });
    }
  };

  private getTopMargin = (detailStickyTopHeight: number): number => {
    const isMobile = window.innerWidth < breakpoints.md;
    const windowHeight = window.innerHeight;
    const percentHeight = windowHeight / 100;
    const topOffset = -(
      this.deckContext.headerHeight +
      detailStickyTopHeight +
      (isMobile ? 0 : this.deckContext.progressBarHeight)
    );
    const topOffsetPercent = topOffset / percentHeight;

    return topOffsetPercent;
  };

  private updateObserver = (data?: any): void => {
    const detailStickyTopHeight = get(data, 'data.detailStickyTopHeight', 0) || this.deckContext.detailStickyTopHeight;

    const options = {
      rootMargin: `${this.getTopMargin(detailStickyTopHeight)}% 0% 0% 0%`, // Really important to use percents here because of different pixel density across devices
    };
    const anchor = makeAnchorFromTitle(this.getHeadline());

    if (this.observer) {
      this.observer.disconnect();
    }

    this.observer = new IntersectionObserver(
      intersectionEvent => this.handleIntersection(intersectionEvent, anchor),
      options
    );

    if (get(this.deck, 'current')) {
      this.observer.observe(this.deck.current);
    }
  };
}

export default withSitecoreContext()(DeckContainer);
