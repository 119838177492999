import React from 'react';
import get from 'lodash/get';
import { ICommonRedirectBaseProps as IProps } from './Interfaces/Sitecore.props';
import { Navigate } from 'react-router-dom';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './CommonRedirect.scss';

const CommonRedirect = (props: IProps) => <Navigate to={get(props, 'fields.redirectUrl.value', '/')} />;

export default withStyles(s)(CommonRedirect);
