import { Visible, Hidden, Row, Col } from 'react-grid-system';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { IProps } from './Interfaces/Component.props';
import Sticker from 'react-stickyfill';
import NoIndex from '../NoIndex';

class StyleguideLayoutAnatomicAreaPage extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  public render = () => {
    const { rendering } = this.props;

    return (
      <Row className="flex wrapper anatomic-area-page layout-row">
        <Visible xs={true} sm={true} md={true}>
          <Col xs={12}>
            <div className="content-holder">
              <Placeholder name="anatomic-area-page" rendering={rendering} />
            </div>
            <div className="treatment-categories">
              <Placeholder name="anatomic-area-treatment-categories-layout" rendering={rendering} />
            </div>
            <div className="widgets__wrapper">
              <div className="widgets">
                <NoIndex>
                  <Placeholder name="anatomic-area-sidebar-layout" rendering={rendering} />
                </NoIndex>
              </div>
            </div>
            <div className="teasers">
              <NoIndex>
                <Placeholder name="anatomic-area-teasers-layout" rendering={rendering} />
              </NoIndex>
            </div>
            <Placeholder name="indication-navigation-layout" rendering={rendering} />
          </Col>
        </Visible>
        <Hidden xs={true} sm={true} md={true}>
          <Col md={3}>
            <Sticker>
              <div className="widgets__wrapper">
                <div className="widgets">
                  <NoIndex>
                    <Placeholder name="anatomic-area-sidebar-layout" rendering={rendering} />
                  </NoIndex>
                </div>
              </div>
            </Sticker>
          </Col>
          <Col md={9}>
            <Placeholder name="anatomic-area-page" rendering={rendering} />
            <div className="treatment-categories">
              <Placeholder name="anatomic-area-treatment-categories-layout" rendering={rendering} />
            </div>
            <div className="teasers">
              <NoIndex>
                <Placeholder name="anatomic-area-teasers-layout" rendering={rendering} />
              </NoIndex>
            </div>
            <Placeholder name="indication-navigation-layout" rendering={rendering} />
          </Col>
        </Hidden>
      </Row>
    );
  };
}

export default StyleguideLayoutAnatomicAreaPage;
