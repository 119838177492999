import React from 'react';
import get from 'lodash/get';
import Modal from 'react-modal';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';
import { ICriticalReadingBaseProps as IProps } from './Interfaces/Sitecore.props';
import CommonLink from '../CommonLink';
import { EVENT, DATA, CATEGORY, ACTION } from '../../analytics/constants';
import { fireEvent } from '../../helpers/utils';
import NoIndex from '../NoIndex';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './CriticalReading.scss';
import { withRouter } from '../../lib/withRouter/index';

interface IState {
  isModalOpen: boolean;
  isContentCollapsed: boolean;
  collapsedClass: string;
  contentId: number;
}

const DEFAULT_CONTENT_ID = 0;

const getHeading = (props: IProps): React.ReactElement => {
  return <Text className="critical-reading__headline word-break" tag="div" field={get(props, 'fields.heading', {})} />;
};

const getSection = (item: any, linksPath: string): React.ReactElement => {
  return (
    <ul className="list-no-style links__list">
      {get(item, linksPath, []).map((subItem, keySubItem) => (
        <li key={keySubItem}>{getLink(subItem)}</li>
      ))}
    </ul>
  );
};

const getLink = (subItem: any): React.ReactElement => (
  <CommonLink link={subItem.fields.link} onClick={() => triggerIsFromOverlay()}>
    <span className="word-break">{get(subItem, 'fields.linkName.value', '')}</span>
  </CommonLink>
);

const triggerIsFromOverlay = (): void => {
  fireEvent(window.document, 'overlayLinkClick');
};

const getModalContent = (props: IProps, id: number): JSX.Element[] => {
  const modalContentPath = 'fields.linksGroups';
  const linksPath = 'fields.links';
  const overlay = props.fields.overlays && props.fields.overlays[id];

  return get(overlay, modalContentPath, []).map((item, key) => (
    <section className="links" key={key}>
      <Text tag="h1" field={get(item, 'fields.heading', {})} />
      {getSection(item, linksPath)}
    </section>
  ));
};

const getLinks = (props: IProps): JSX.Element[] => {
  return get(props, 'fields.links', []).map((link, key) => (
    <li key={key}>
      <CommonLink className="critical-reading__link" link={get(link, `fields.link`, '')}>
        <span className="word-break no-pointer-events">{get(link, 'fields.linkName.value', '')}</span>
      </CommonLink>
    </li>
  ));
};

const getToggle = (props: IProps, toggle: (id: number) => void): JSX.Element[] => {
  const dataAttributes = {
    [DATA.event]: EVENT.click,
    [DATA.category]: CATEGORY.userInteraction,
    [DATA.action]: ACTION.popin,
  };

  return get(props, 'fields.overlays', []).map((overlay, key) => {
    const getOverlayProperty = (name: string): string => get(overlay, `fields.${name}.value`, '');
    const overviewTitle = getOverlayProperty('overviewTitle');
    const overviewCount = getOverlayProperty('overviewCount');
    const dataLabel = `${get(props, 'sitecoreContext.route.fields.pageTitle.value', '')} - ${overviewTitle}`;

    return (
      <li key={key} onClick={() => toggle(key)} className="overlay-button">
        <a {...dataAttributes} {...{ [DATA.label]: dataLabel }}>
          <span className="word-break critical-reading__label">
            {overviewTitle}
            <span className="critical-reading__counter" {...dataAttributes} {...{ [DATA.label]: dataLabel }}>
              {overviewCount}
            </span>
          </span>
          <span className="icon-plus" {...dataAttributes} {...{ [DATA.label]: dataLabel }} />
        </a>
      </li>
    );
  });
};

class CriticalReading extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isModalOpen: false,
      isContentCollapsed: true,
      collapsedClass: '',
      contentId: DEFAULT_CONTENT_ID,
    };
  }

  public render() {
    return (
      <Row className={this.getRowClasses()}>
        <Col xs={12}>
          <NoIndex>{getHeading(this.props)}</NoIndex>
          <ul className="list-no-style critical-reading__list">
            {getLinks(this.props)}
            {getToggle(this.props, this.openModal)}
          </ul>
          <Modal
            isOpen={this.state.isModalOpen}
            onRequestClose={this.closeModal}
            className="modal"
            overlayClassName="modal__overlay"
          >
            <Text
              tag="button"
              className="modal__close"
              field={get(this.props, 'fields.modalClose')}
              onClick={this.closeModal}
            />
            <div className="modal-content">{getModalContent(this.props, this.state.contentId)}</div>
          </Modal>
        </Col>
      </Row>
    );
  }

  private getRowClasses = () =>
    `flex critical-reading ${!get(this.props, 'fields.isButton.value') ? 'isdetail' : 'isbutton'}`;

  private openModal = (id: number) => {
    this.setState({ isModalOpen: true, contentId: id });
  };

  private closeModal = () => {
    this.setState({ isModalOpen: false });
  };
}

export default withStyles(s)(withSitecoreContext()(withRouter(CriticalReading)));
