import React from 'react';
import get from 'lodash/get';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {Popover} from 'react-tiny-popover';
import Bookmark from 'react-bookmark';
import { Visible, Hidden } from 'react-grid-system';
import Clipboard from 'react-clipboard.js';

import { ReactComponent as ShareIcon } from '../../assets/images/icons/share.svg';
import { ReactComponent as ImessageIcon } from '../../assets/images/icons/imessage.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/images/icons/whatsapp.svg';
import { ReactComponent as EmailIcon } from '../../assets/images/icons/mail.svg';
import { ReactComponent as LinkIcon } from '../../assets/images/icons/link.svg';
import { ReactComponent as DotsIcon } from '../../assets/images/icons/dots.svg';
import { IProps } from './Interfaces/Component.props';
import { DATA, EVENT, CATEGORY, ACTION } from '../../analytics/constants';
import NoIndex from '../NoIndex';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './PageTitleActions.scss';
import { withRouter } from '../../lib/withRouter';

const ACTIONS_PANEL = 'Actions - Download - Share - Bookmark';
const ACTIONS_SHARE = 'Actions - Share';
const COPIED = 'Copied';

interface IState {
  isModalOpen: boolean;
  isBookmark: boolean;
  isShare: boolean;
  isActions: boolean;
  isCopied: boolean;
}

class PageTitleActions extends React.Component<IProps, IState> {
  private pageHref: any;
  private pageTitle: any;
  private userAgent: any;
  private isAndroid: any;
  private isIos: any;
  private PAGE_TITLE_SAFE = get(this.props, 'sitecoreContext.route.fields.pageTitle.value', '');
  private pageTitleActionsRef: any;
  private popoverContentDestination: any;

  constructor(props: IProps) {
    super(props);
    this.pageTitleActionsRef = React.createRef();
    this.state = {
      isModalOpen: false,
      isBookmark: false,
      isShare: false,
      isActions: true,
      isCopied: false,
    };
  }

  public componentDidMount() {
    this.pageHref = document.location.href;
    this.pageTitle = document.title;
    this.userAgent = navigator.userAgent || navigator.vendor;
    this.isAndroid = this.userAgent.match(/Android/i);
    this.isIos = this.userAgent.match(/iPhone/i) || this.userAgent.match(/iPod/i);
    this.popoverContentDestination = get(this.pageTitleActionsRef, 'current', document.body);
  }

  public render() {
    return (
      <div className="page-title">
        <div className="page-title-actions" ref={this.pageTitleActionsRef}>
          <NoIndex>
            <Visible xs={true} sm={true} md={true}>
              <Popover // mobile version
                onClickOutside={() => this.toggleModal()}
                isOpen={this.state.isModalOpen}
                positions={['left']}
                reposition={false}
                contentLocation={this.popoverContentDestination}
                content={() => this.shareContentMobile()}
              >
                <a onClick={() => this.toggleModal()} {...this.getDataAttributes(ACTIONS_PANEL)}>
                  <DotsIcon className="dots-icon" />
                </a>
              </Popover>
            </Visible>

            <Hidden xs={true} sm={true} md={true}>
              <Popover // desktop version
                onClickOutside={() => this.toggleModal()}
                isOpen={this.state.isModalOpen}
                positions={['left']}
                reposition={false}
                contentLocation={this.popoverContentDestination}
                content={this.shareContent()}
              >
                <div className="actions">
                  {/* Disabled according to AOF-222 - Share only for version 1 - only for 1st Go Live */}
                  {/* <a className="action action__download" title={get(this.props, 'fields.download.value', '')}>
                      {get(this.props, 'fields.download.value', '')}
                      <DownloadIcon className="download-icon" />
                    </a> */}
                  <a
                    className="action action__share"
                    title={get(this.props, 'fields.share.value', '')}
                    onClick={() => this.toggleModal()}
                    {...this.getDataAttributes(ACTIONS_SHARE)}
                  >
                    {get(this.props, 'fields.share.value', '')}
                    <ShareIcon className="share-icon" />
                  </a>
                  {/* Disabled according to AOF-1279 - Bookmark only for version 1 - only for 1st Go Live */}
                  {/* <a
                      className="action action__bookmark"
                      title={get(this.props, 'fields.bookmark.value', '')}
                      onClick={() => this.toggleBookmark()}
                    >
                      {get(this.props, 'fields.bookmark.value', '')}
                      <BookmarkIcon className="bookmark-icon" />
                    </a> */}
                </div>
              </Popover>
            </Hidden>
          </NoIndex>

          <Placeholder name="page-title" rendering={this.props.rendering} />

          <Placeholder name="page-subtitle" rendering={this.props.rendering} />
        </div>
      </div>
    );
  }

  private getDataAttributes = (label: string): object => {
    const dataAttributes = {
      [DATA.event]: EVENT.click,
      [DATA.category]: CATEGORY.userInteraction,
      [DATA.action]: ACTION.popin,
      [DATA.label]: label,
    };

    return dataAttributes;
  };

  private shareContent() {
    return (
      <>
        {this.state.isBookmark ? (
          <Bookmark className="container" href={this.pageHref} title={this.pageTitle} />
        ) : (
          <>
            <div className="container">
              {(this.isAndroid || this.isIos) && (
                <a
                  className="action action__imessage"
                  href={this.shareMessage(this.pageHref)}
                  {...this.getLinkDataAttributes(ACTION.shareImessage)}
                >
                  <ImessageIcon className="icon" />
                  <div>{get(this.props, 'fields.imessage.value', '')}</div>
                </a>
              )}
              {(this.isAndroid || this.isIos) && (
                <a
                  className="action action__whatsapp"
                  href={this.shareWhatsApp(get(this.props, 'fields.whatsappApiUrl.value', ''), this.pageHref)}
                  target="_blank"
                  {...this.getLinkDataAttributes(ACTION.shareWhatsapp)}
                >
                  <WhatsappIcon className="icon" />
                  <div>{get(this.props, 'fields.whatsApp.value', '')}</div>
                </a>
              )}
              <a
                className="action action__email"
                href={this.shareEmail(get(this.props, 'fields.emailSubject.value', ''), this.pageHref)}
                {...this.getLinkDataAttributes(ACTION.shareEmail)}
              >
                <EmailIcon className="icon" />
                <div>{get(this.props, 'fields.email.value', '')}</div>
              </a>
            </div>
            {this.getCopyLink()}
          </>
        )}
      </>
    );
  }

  private shareContentMobile() {
    return (
      <div>
        {this.state.isBookmark && <Bookmark className="container" href={this.pageHref} title={this.pageTitle} />}
        <div className="container" style={{ display: this.state.isActions ? 'block' : 'none' }}>
          <div className="actions__mobile">
            {/* Disabled according to AOF-222 - Share only for version 1 - only for 1st Go Live */}
            {/* <a className="action action__download" title={get(this.props, 'fields.download.value', '')}>
                  <DownloadIcon className="download-icon" />
                  {get(this.props, 'fields.download.value', '')}
                </a>*/}
            <a
              className="action action__share"
              title={get(this.props, 'fields.share.value', '')}
              onClick={() => this.toggleMobile()}
              {...this.getDataAttributes(ACTIONS_SHARE)}
            >
              <ShareIcon className="share-icon" />
              {get(this.props, 'fields.share.value', '')}
            </a>
            {/* Disabled according to AOF-1279 - Bookmark only for version 1 - only for 1st Go Live */}
            {/* <a
              className="action action__bookmark"
              title={get(this.props, 'fields.bookmark.value', '')}
              onClick={() => this.toggleBookmark()}
            >
              <BookmarkIcon className="bookmark-icon" />
              {get(this.props, 'fields.bookmark.value', '')}
            </a> */}
          </div>
        </div>
        {this.state.isShare && (
          <>
            <div className="container">
              <Hidden sm={true} md={true}>
                {(this.isAndroid || this.isIos) && (
                  <a
                    className="action action__imessage"
                    href={this.shareMessage(this.pageHref)}
                    {...this.getLinkDataAttributes(ACTION.shareImessage)}
                  >
                    <ImessageIcon className="icon" />
                    <div>{get(this.props, 'fields.imessage.value', '')}</div>
                  </a>
                )}
                <a
                  className="action action__whatsapp"
                  href={this.shareWhatsApp(get(this.props, 'fields.whatsappApiUrl.value', ''), this.pageHref)}
                  target="_blank"
                  {...this.getLinkDataAttributes(ACTION.shareWhatsapp)}
                >
                  <WhatsappIcon className="icon" />
                  <div>{get(this.props, 'fields.whatsApp.value', '')}</div>
                </a>
              </Hidden>

              <a
                className="action action__email"
                href={this.shareEmail(get(this.props, 'fields.emailSubject.value', ''), this.pageHref)}
                {...this.getLinkDataAttributes(ACTION.shareEmail)}
              >
                <EmailIcon className="icon" />
                <div>{get(this.props, 'fields.email.value', '')}</div>
              </a>
            </div>
            {this.getCopyLink()}
          </>
        )}
      </div>
    );
  }

  private getCopyLink(): React.ReactElement {
    return (
      <div className={`action action__link ${this.getIsActivatedClass()}`}>
        <Clipboard
          component="a"
          data-clipboard-text={this.pageHref}
          onClick={() => this.setIsCopied(true)}
          {...this.getLinkDataAttributes(ACTION.shareLink)}
        >
          <LinkIcon className="icon" />
          {this.getCopyLinkText()}
        </Clipboard>
      </div>
    );
  }

  private getCopyLinkText(): string {
    return this.state.isCopied
      ? get(this.props, 'fields.copiedLink.value', COPIED)
      : get(this.props, 'fields.copyLink.value', '');
  }

  private setIsCopied(isCopied: boolean): void {
    this.setState({
      isCopied,
    });
  }

  private getIsActivatedClass(): string {
    return this.state.isCopied ? 'action__link_activated' : '';
  }

  private toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      isBookmark: false,
      isShare: false,
      isActions: true,
      isCopied: this.state.isModalOpen ? this.state.isCopied : false,
    });
  }

  private toggleBookmark() {
    this.setState({
      isModalOpen: true,
      isBookmark: true,
      isShare: false,
      isActions: false,
    });
  }

  private toggleMobile() {
    this.setState({
      isShare: true,
      isActions: false,
    });
  }

  private shareWhatsApp(api: string, text: string) {
    return api + text;
  }

  private shareMessage(text: string) {
    return this.isAndroid ? `sms:?body=${text}` : `sms:&body=${text}`;
  }

  private shareEmail(subject: string, text: string) {
    const subjectSafe = encodeURI(`${subject} - ${this.PAGE_TITLE_SAFE}`);

    return `mailto:?subject=${subjectSafe}&body=${text}`;
  }

  private getLinkDataAttributes = (dataAction: string): object => {
    return {
      ...{
        [DATA.label]: this.PAGE_TITLE_SAFE,
        [DATA.event]: EVENT.click,
        [DATA.category]: CATEGORY.userInteraction,
        [DATA.action]: dataAction,
      },
    };
  };
}

export default withStyles(s)(withSitecoreContext()(withRouter(PageTitleActions)));
