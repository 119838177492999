import { IAppThunkAction } from '..';
import ActionTypes from './RootActions';
import { Action, Reducer } from 'redux';

export interface IRootState {
  payload: string;
}

export interface IRequestRootItemAction extends Action {
  type: typeof ActionTypes.REQUEST_ROOT_ITEM;
  payload: string;
}

export interface IReceiveRootItemAction extends Action {
  type: typeof ActionTypes.RECEIVE_ROOT_ITEM;
  payload: string;
}

type KnownAction = IRequestRootItemAction | IReceiveRootItemAction;

export const actionCreators = {
  requestRootItem: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
    setTimeout(
      () =>
        dispatch({
          type: ActionTypes.REQUEST_ROOT_ITEM,
          payload: 'requested',
        }),
      1000
    );
  },

  receiveRootItem: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
    setTimeout(
      () =>
        dispatch({
          type: ActionTypes.RECEIVE_ROOT_ITEM,
          payload: 'received',
        }),
      1000
    );
  },
};

const unloadedState: IRootState = {
  payload: '',
};

export const reducer: Reducer<any, KnownAction> = (state: IRootState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.REQUEST_ROOT_ITEM:
      return {
        payload: action.payload,
      };
    case ActionTypes.RECEIVE_ROOT_ITEM:
      return {
        payload: action.payload,
      };
    default:
  }

  return state || unloadedState;
};
