import React, { ReactElement } from 'react';
import get from 'lodash/get';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from '../../lib/withRouter';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import { goTop, getPathSegments, slash, previousPages, fireEvent, searchPage } from '../../helpers/utils';
import { IProps } from './Interfaces/Component.props';
import InformationPageContext, { IInformationPage } from '../Styleguide-Layout-InformationPage/InformationPageContext';
import throttle from 'lodash/throttle';
import delay from 'lodash/delay';
import NoIndex from '../NoIndex';
import Sticker from 'react-stickyfill';
import { historyUpdateTimeout } from '../../constants';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './ArrowsNavigation.scss';

interface IState {
  scrollPosition: number;
  isBackToTopVisible: boolean;
  isBackToPageVisible: boolean;
  href: string;
  searchUrl: string;
  isPagesHistory: boolean | null;
}

const THROTTLE_DELAY = 350;
const VISIBILITY_TRESHOLD = 30;
const HISTORY_STEPS_BACK = 2;

class ArrowsNavigation extends React.Component<IProps, IState> {
  private arrowsContext;
  private backText;
  private arrowNavigationText;
  private isNotGoingBack = true;
  private isOverlayLinkClick = false;
  private hash = '';
  private timeout;
  private delay;
  private scrollListener;
  private resizeListener;
  private lastPreviousPageText;
  private facetColumn;

  constructor(props: IProps) {
    super(props);

    this.backText = get(props, 'fields.backText.value');
    this.arrowNavigationText = get(props, 'sitecoreContext.route.fields.arrowNavigationText.value');

    this.scrollListener = throttle(e => this.handleScroll(e), THROTTLE_DELAY);
    this.resizeListener = throttle(e => this.handleScroll(e), THROTTLE_DELAY);

    this.state = {
      scrollPosition: 0,
      isBackToPageVisible: true,
      isBackToTopVisible: false,
      href: getPathSegments().join(slash),
      searchUrl: '',
      isPagesHistory: null,
    };
  }

  public render() {
    return (
      <NoIndex>
        <Sticker>
          <div className="arrows-navigation">
            {this.state.searchUrl ? (
              this.getBackArrows()
              //this.props.navigate(-1)
            ) : (
              <InformationPageContext.Consumer>{context => this.getArrowBack(context)}</InformationPageContext.Consumer>
            )}
            {!this.props.isRestricted && this.getArrowTop()}
          </div>
        </Sticker>
      </NoIndex>
    );
  }

  public componentDidMount() {
    window.addEventListener('animationStart', this.animationStart);
    window.addEventListener('animationEnd', this.animationEnd);
    window.addEventListener('scroll', this.scrollListener, { passive: true, capture: true });
    window.addEventListener('resize', this.resizeListener, { passive: true, capture: true });
    window.addEventListener('overlayLinkClick', this.overlayLinkClickListener);
    window.addEventListener('hashChange', this.hashChangeListener);
    window.addEventListener('previousPageUpdate', this.previousPageUpdate);
    window.addEventListener('filtersMounted', this.handleFiltersMounted);

    this.setState({
      searchUrl: this.getSearchUrl(),
    });
    this.updateIsPageHistory();
  }

  public componentWillUnmount() {
    window.removeEventListener('animationStart', this.animationStart);
    window.removeEventListener('animationEnd', this.animationEnd);
    window.removeEventListener('scroll', this.scrollListener, true);
    window.removeEventListener('resize', this.resizeListener, true);
    window.removeEventListener('overlayLinkClick', this.overlayLinkClickListener);
    window.removeEventListener('hashChange', this.hashChangeListener);
    window.removeEventListener('previousPageUpdate', this.previousPageUpdate);
    window.removeEventListener('filtersMounted', this.handleFiltersMounted);

    if (this.facetColumn) {
      this.facetColumn.removeEventListener('scroll', this.scrollListener, true);
      this.facetColumn.removeEventListener('resize', this.resizeListener, true);
    }

    this.setState({
      searchUrl: '',
    });

    if (this.delay) {
      clearTimeout(this.delay);
    }
  }

  public shouldComponentUpdate() {
    return !(this.state.scrollPosition > VISIBILITY_TRESHOLD + 1);
  }

  private handleFiltersMounted = (): void => {
    this.facetColumn = document.querySelector('.coveo-facet-column');

    if (this.facetColumn) {
      this.facetColumn.addEventListener('scroll', this.scrollListener, { passive: true, capture: true });
      this.facetColumn.addEventListener('resize', this.resizeListener, { passive: true, capture: true });
    }
  };

  private overlayLinkClickListener = (): void => {
    this.updateIsOverlayLinkClick();
  };

  private hashChangeListener = e => {
    this.handleHashChange(e);
  };

  private previousPageUpdate = (event: Event): void => {
    const url = get(event, 'data.data', '');
    this.updatePreviousPages(url);
    this.updateIsPageHistory();
  };

  private updateIsPageHistory = (): void => {
    this.setState({
      isPagesHistory: !!previousPages.get().length,
    });
  };

  private animationStart = (): void => {
    // console.log('animation start');
  };

  private animationEnd = (): void => {
    const { text } = this.getLastPreviousPage();
    this.lastPreviousPageText = text;
  };

  private getBackArrows = () => {
    return (
      <>
        {this.getArrowBackToSearch()}
        {!this.props.isRestricted && this.getArrowBackToParent()}
      </>
    );
  };

  private getArrowBackToSearch = () => {
    return (
      <>
        <div
          data-category="User Interaction"
          data-action="Back Navigation"
          data-label="Back to search"
          className={`
          arrows-navigation-arrow
          arrows-navigation-arrow__back
        `}
          onClick={this.goBackToSearch}
        >
          <ArrowIcon />
        </div>
      </>
    );
  };

  private getArrowBackToParent = () => {
    const parentPageLinkText = get(this.props, 'sitecoreContext.header.parentPageLink.text', '');

    return (
      parentPageLinkText && (
        <div
          data-category="User Interaction"
          data-action="Back Navigation"
          data-label={parentPageLinkText}
          className={`
          arrows-navigation-arrow
          arrows-navigation-arrow__back
          arrows-navigation-arrow__implants
          arrows-navigation-arrow__back-to-parent`}
          onClick={this.goBackInUrlStructure}
        >
          {parentPageLinkText}
        </div>
      )
    );
  };

  private getInformationBackButton = (buttonText: string, onClick: () => void): ReactElement => {
    return (
      <div
        data-category="User Interaction"
        data-action="Back Navigation"
        data-label={buttonText}
        className={`${this.getBackButtonClasses()} arrows-navigation-arrow__implants`}
        onClick={onClick}
      >
        {buttonText}
      </div>
    );
  };

  private getSearchUrl = (): string => {
    const  { search }  = get(this.props, 'location', {});
    const searchQueryParameter = 'searchurl';
    const urlParams = new URLSearchParams(search);

    return urlParams.get(searchQueryParameter) || '';
  };

  private handleHashChange = (event: Event) => {
    this.hash = get(event, 'data.data', '');
  };

  private updatePreviousPages = (previousPageUrl: string): void => {
    if (this.isOverlayLinkClick) {
      return;
    }

    if (!this.checkIfPreviosPageIsCurrentPage()) {
      this.addPreviousPage(previousPageUrl);
    } else {
      previousPages.remove();
    }
  };

  private addPreviousPage = (previousPageUrl: string): void => {
    const previousPage = get(this.arrowsContext, 'isInformationPage') ? this.backText : this.arrowNavigationText;

    previousPages.add(previousPage, `${previousPageUrl}${this.hash}`);
  };

  private getArrowBack = (context: IInformationPage): React.ReactElement => {
    const isPageHistory = this.state.isPagesHistory;
    const previousPageText = context.isInformationPage ? this.backText : this.lastPreviousPageText;
    const parentPageLinkText = get(this.props, 'sitecoreContext.header.parentPageLink.text', '');
    const isInformationPageAndPrev = context.isInformationPage && isPageHistory;
    const isInformationPageNoHistory = context.isInformationPage && !isPageHistory;
    const { isSearch, isRestricted, isInformationReference } = this.props;
    const isNotOtherPages = !(context.isInformationPage || isSearch || isRestricted || isInformationReference);
    const backHierarchical = isInformationReference && !isPageHistory;
    const backToPrevious = isInformationReference && isPageHistory;

    return (
      <>
        {isInformationPageAndPrev && this.getInformationBackButton(previousPageText, this.goBackToPath)}
        {isInformationPageNoHistory && this.getInformationBackButton(parentPageLinkText, this.goBackInUrlStructure)}
        {isSearch && this.getFlatBackButton(this.goBackFromSearch)}
        {backHierarchical && this.getFlatBackButton(this.goBackInUrlStructure)}
        {backToPrevious && this.getFlatBackButton(this.goBackToPath)}
        {isRestricted && this.getFlatBackButton(this.goBackFromRestricted)}
        {isNotOtherPages && this.getFlatBackButton(this.goBackInUrlStructure)}
      </>
    );
  };

  private getFlatBackButton = (onClick: () => void): ReactElement => {
    return (
      <div
        data-category="User Interaction"
        data-action="Back Navigation"
        data-label="Back"
        className={this.getBackButtonClasses()}
        onClick={onClick}
      >
        <ArrowIcon />
      </div>
    );
  };

  private goBackFromRestricted = (): void => {
    const { url: previousPageUrl } = this.getLastPreviousPage();
    const restrictedBackButtonUrl = get(this.props, 'sitecoreContext.restrictedBackButtonUrl', slash);

    // Check previous page URL from a Session Storage
    if (previousPageUrl) {
      this.goBackToPath();
      // If no previous page URL in a Session Storage
    } else {
      // If the previous page is not an internal Surgery Reference page
      // the user should be redirected to the upper public page in the folder structure
      //this.props.history.push(restrictedBackButtonUrl);
      this.props.navigate(restrictedBackButtonUrl);
    }
  };

  private goBackFromSearch = (): void => {
    window.scrollTo(0, 0);
    const { pathname, hash } = searchPage.getPrevPage();
    const route = pathname ? { pathname, hash } : { pathname: '/' };
    //this.props.history.push(route);
    this.props.navigate(route);
  };

  private getArrowTop = (): React.ReactElement => {
    return (
      <>
        {this.state.isBackToTopVisible && (
          <div className={this.getArrowTopClasses()} onClick={this.goTop}>
            <ArrowIcon />
          </div>
        )}
      </>
    );
  };

  private goTop = (): void => {
    goTop();

    if (this.facetColumn) {
      this.facetColumn.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  private getArrowTopClasses = (): string => {
    return `arrows-navigation-arrow arrows-navigation-arrow__top`;
  };

  private getLastPreviousPage = (): { [key: string]: string } => {
    const pages = previousPages.get();
    const pagesLength = pages.length || 0;
    const steps = 1;
    const lastPageIndex = !!pagesLength ? pagesLength - steps : 0;

    return pages[lastPageIndex] || {};
  };

  private updateIsOverlayLinkClick = () => {
    this.isOverlayLinkClick = true;
    previousPages.add(this.backText, this.state.href);
    window.removeEventListener('overlayLinkClick', this.overlayLinkClickListener);
  };

  private getCurrentUrl = (): string => {
    return getPathSegments()
      .join(slash)
      .split('#')[0];
  };

  private checkIfPreviosPageIsCurrentPage = (): boolean => {
    const pages = previousPages.get();
    const lastPage = pages[pages.length - 1];
    const url = get(lastPage, 'url', '').split('#')[0];

    return url === this.getCurrentUrl();
  };

  private handlePreviosPageIsCurrentPage = () => {
    if (this.checkIfPreviosPageIsCurrentPage()) {
      previousPages.remove();
      this.updateIsPageHistory();
    }
  };

  private goBackToPath = (): void => {
    this.handlePreviosPageIsCurrentPage();
    const { url } = this.getLastPreviousPage();

    this.updateIsNotGoingBack(false);
    const newUrl = new URL(`${window.location.protocol}//${window.location.host}/${url ? url : ''}`);
    const { hash, pathname, search } = newUrl;

    // Mobile Safari doesn't allow updating the hash more than 100 times per 30 seconds.
    this.delay = delay(() => {
      // this.props.history.push({
      //   pathname,
      //   hash,
      //   search,
      // });
      this.props.navigate({
        pathname,
        hash,
        search,
      });
    }, historyUpdateTimeout);
    fireEvent(window, 'backToPage');
  };

  private getBackButtonClasses = (): string => {
    return `arrows-navigation-arrow arrows-navigation-arrow__back
      ${get(this.props, 'fields.isBlue.value') ? 'arrows-navigation-arrow__treatment' : ''}
    `;
  };

  private handleScroll = (e: Event) => {
    const scrollRange = 100;
    const topOffset = 0;

    if (window.scrollY > VISIBILITY_TRESHOLD) {
      this.setState({ isBackToTopVisible: true });
    } else {
      this.setState({ isBackToTopVisible: false });
    }

    if (window.scrollY <= topOffset) {
      fireEvent(window, 'topOfThePage');
    } else if (window.scrollY <= scrollRange) {
      fireEvent(window, 'notTopOfThePage');
    }
  };

  private goBackInUrlStructure = () => {
    this.updateIsNotGoingBack(false);
    // Mobile Safari doesn't allow updating the hash more than 100 times per 30 seconds.
    const timeout = delay(() => {
      //this.props.history.push(this.getPreviousUrl());
      this.props.navigate(this.getPreviousUrl());
      if (timeout) {
        clearTimeout(timeout);
      }
    }, historyUpdateTimeout);
  };

  private goBackToSearch = (): void => {
    this.updateIsNotGoingBack(false);
    this.props.navigate(-1);
    fireEvent(window, 'backToPage');
  };

  private updateIsNotGoingBack = (isNotGoingBack: boolean): void => {
    this.isNotGoingBack = isNotGoingBack;
  };

  private getPreviousUrl() {
    const stepsBack = get(this.props, 'sitecoreContext.isparentapage') ? 1 : HISTORY_STEPS_BACK;
    const newPath = getPathSegments().slice(0, -stepsBack); // Remove last element(s)

    return `${slash}${newPath.join(slash)}`;
  }
}

const ArrowsNavigationComponent = withSitecoreContext()(withRouter(ArrowsNavigation));

export default withStyles(s)(ArrowsNavigationComponent);
