import get from 'lodash/get';
import { ILayoutContext } from '../interfaces/SitecoreContext';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';

const getMainPlaceholder = (props: ILayoutContext) => {
  return props.route!.placeholders['jss-main'] || [];
};

const getStyleguidelayout = (props: ILayoutContext, layoutName: string): any[] => {
  const jssMain = getMainPlaceholder(props);
  const styleGuideLayout = get(jssMain, `[0].placeholders[${layoutName}]`);

  return styleGuideLayout || [];
};

const getChildPlaceholders = (props: ILayoutContext, componentNames: string[]): any[] => {
  const jssMain = getMainPlaceholder(props) as any;
  let result = [];

  componentNames.forEach(componentName => {
    const index = jssMain.findIndex(item => item.componentName === componentName);
    if (index !== -1) {
      result = jssMain[index].placeholders;
    }
  });

  return result;
};

const filterComponentsByName = (props: ILayoutContext, name: string, componentNames: string[]) => {
  const components: any[] = [];
  const placeholders = getChildPlaceholders(props, componentNames);

  Object.values(placeholders).map(placeholder => {
    placeholder.forEach((item: ComponentRendering) => {
      if (item.componentName === name) components.push(item);
    });
  });

  return components || [];
};

/**
 * Checks if is in disconnected mode.
 * Should be used with withSitecoreContext together
 */
const isDisconnectedMode = (props: any) => {
  // tslint:disable-next-line
  return props.sitecoreContext && props.sitecoreContext.route &&
    props.sitecoreContext.route.databaseName === 'available-in-connected-mode';
};

export { getMainPlaceholder, getStyleguidelayout, getChildPlaceholders, filterComponentsByName, isDisconnectedMode };
