import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import './RestrictedContent.scss';
import { IProps } from './Interfaces/Component.props';
import get from 'lodash/get';
import { withRouter } from '../../lib/withRouter/index';

const getIsLoginButtonVisible = (props: IProps): boolean => {
  const isLoginUrl = !!get(props, 'loginUrl');
  const isUserAuthenticated = get(props, 'sitecoreContext.IsAuthenticated');
  return isLoginUrl && !isUserAuthenticated;
};

const RestrictedContent: React.FC<IProps> = (props: IProps) => (
  <article className="restricted-content">
    <div className="restricted-content__label">
      <Text field={get(props, 'label')} />
    </div>
    <div className="restricted-content__holder">
      <Text tag={'h1'} field={get(props, 'heading')} />
      <RichText tag={'p'} field={get(props, 'text')} />
    </div>
    {props.question && <Text tag={'h3'} field={props.question} />}
    <ul className="restricted-content__buttons">
      {props.loginLabel && getIsLoginButtonVisible(props) && (
        <li>
          <form action={props.loginUrl} method="post">
            <button className="restricted-content__button">
              <Text field={props.loginLabel} />
            </button>
          </form>
        </li>
      )}
      {props.registrationLabel && props.registrationUrl && (
        <li>
          <a className="restricted-content__button" href={props.registrationUrl} target="_blank" rel="noreferrer">
            <Text field={props.registrationLabel} />
          </a>
        </li>
      )}
    </ul>
  </article>
);

export default withSitecoreContext()(withRouter(RestrictedContent));
