import React from 'react';

export interface ITreatmentDetailsPage {
  isTreatmentDetailsPage: boolean;
}

const TreatmentDetailsPageContext = React.createContext({
  isTreatmentDetailsPage: false,
});

export default TreatmentDetailsPageContext;
