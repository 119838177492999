import * as React from 'react';
import { IProps } from './Interfaces/Component.props';
import Helmet from 'react-helmet';

const RestrictedMicroformat: React.FC<IProps> = (props: IProps) => (
  <Helmet>
    <script type="application/ld+json">{`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "${props.id}"
        },
        "isAccessibleForFree": "False",
        "hasPart":
          {
          "@type": "WebPageElement",
          "isAccessibleForFree": "False",
          "cssSelector" : ".paywall"
          }
      }
    `}</script>
  </Helmet>
);

export default RestrictedMicroformat;
