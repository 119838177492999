import React from 'react';

export interface IDetailStickyTopContext {
  anchorSelectorHeight: number;
  detailStickyTopHeight: number;
  headerHeight: number;
  hookCall: () => void;
}

const DetailStickyTopContext = React.createContext({
  anchorSelectorHeight: 0,
  detailStickyTopHeight: 0,
  progressBarHeight: 0,
  headerHeight: 0,
  hookCall: Function,
});

export default DetailStickyTopContext;
