import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import get from 'lodash/get';
import { ITeaserBaseProps as IProps } from './Interfaces/Sitecore.props';
import CommonLink from '../CommonLink';
import CommonImage from '../CommonImage';
import { teasersImagesSizes } from '../../constants';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Teaser.scss';

const Teaser: React.FC<IProps> = (props: IProps) => (
  <div className="teaser">
    <CommonLink link={get(props, 'fields.link')}>
      <div className="teaser__preview">
        <CommonImage className="teaser__image" field={get(props, 'fields.image')} sizes={teasersImagesSizes} />
      </div>
      <div className="teaser__content">
        <div className="teaser__category">
          <Image className="teaser__icon" field={get(props, 'fields.logo', {})} />
          <Text field={get(props, 'fields.category')} />
        </div>
        <div className="teaser__headline">
          <Text field={get(props, 'fields.text')} />
        </div>
        <div className="teaser__link">{get(props, 'fields.linkName.value')}</div>
      </div>
    </CommonLink>
  </div>
);

export default withStyles(s)(Teaser);
