import React from 'react';
import get from 'lodash/get';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { IContentBlockBaseProps } from './Interfaces/Sitecore.props';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './ContentBlock.scss';
class ContentBlock extends React.Component<IContentBlockBaseProps, {}> {
  constructor(props: IContentBlockBaseProps) {
    super(props);
  }
  public render = () =>
    get(this.props, 'fields.content', '') && (
      <div className="content">
        <RichText field={get(this.props, 'fields.content', {})} />
      </div>
    );
}

export default withStyles(s)(ContentBlock);
