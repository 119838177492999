import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import ICommonLink from '../../interfaces/CommonLink';

// https://kamsar.net/index.php/2018/09/Routing-Sitecore-links-with-JSS/

const CommonLink: React.FunctionComponent<ICommonLink> = ({ className, children, link }) => {
  const isExternal = link.value.linktype === 'external';

  return (
    <>
      {isExternal ? (
        <Link className={className} field={link}>
          {children}
        </Link>
      ) : (
        <NavLink to={link.value.href || ''} className={className}>
          {children || link.value.text || link.value.href}
        </NavLink>
      )}
    </>
  );
};

export default CommonLink;
