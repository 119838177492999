import React from 'react';
import get from 'lodash/get';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { IProps } from './Interfaces/Component.props';
import { diagnosPageImagesSizes } from '../../constants';
import CommonImage from '../CommonImage';
import { saveToSessionStorage, loadFromSessionStorage, getUid } from '../../helpers/utils';
import NoIndex from '../NoIndex';

interface IState {
  collapsedClass: string;
}

const COMPONENT_KEY = 'diagonosisBlock';
const TOGGLE_CLASS = 'diagnos_expanded';
const IS_DIAGNOS_CATEGORY_ENABLED = false; // Temporary flag to hide a Diagnos category caption

class DiagnosisBlock extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      collapsedClass: '',
    };
  }

  public render() {
    return (
      <article className={`diagnos diagnos_with-toggle ${this.state.collapsedClass}`}>
        <header className="diagnos__header">
          <div className="diagnos__icon">
            <CommonImage field={get(this.props, 'fields.icon', {})} sizes={diagnosPageImagesSizes} />
          </div>
          <Text
            tag="h3"
            field={get(this.props, 'fields.headline', {})}
            className="diagnos__headline"
            onClick={this.onClick}
          />
          <span className="diagnos__toggle" onClick={this.onClick}>
            <NoIndex>
              <Text field={get(this.props, 'fields.toggle', {})} />
              {this.getCountValue()}
            </NoIndex>
          </span>
        </header>

        <section className="diagnoses__section diagnoses_subtype">
          {this.getDiagnosCategory()}
          <Placeholder name="diagnosis-cards-block" rendering={this.props.rendering} />
        </section>
      </article>
    );
  }

  public componentDidMount = (): void => {
    const isBlockExpanded = loadFromSessionStorage(`${COMPONENT_KEY}.${getUid(this.props)}`);

    if (isBlockExpanded) {
      this.updateClass(TOGGLE_CLASS);
    } else {
      this.saveDiagnosisBlock(false);
    }
  };

  private getDiagnosCategory = (): React.ReactElement => {
    const decide = get(this.props, 'fields.decide', '');

    return get(decide, 'value') && IS_DIAGNOS_CATEGORY_ENABLED ? (
      <h2 className="diagnoses__category">
        <Text field={get(this.props, 'fields.decide', {})} />
        {this.getCountValue()}
      </h2>
    ) : (
      <div className="diagnoses__category_spacer" />
    );
  };

  private getCountValue = (): string => {
    if (get(this.props, 'rendering.placeholders')) {
      // wrong value in Sitecore EE as automaticly additional objects are added to placeholder
      return ` (${get(this.props, 'rendering.placeholders["diagnosis-cards-block"].length')})`;
    }
    return '';
  };

  private onClick = (): void => {
    const isNeedToCollapse = this.state.collapsedClass !== TOGGLE_CLASS;
    const className = isNeedToCollapse ? TOGGLE_CLASS : '';

    this.updateClass(className);
    this.storeCurrentState(className);
  };

  private updateClass = (cssClass: string): void => {
    this.setState({
      collapsedClass: cssClass,
    });
  };

  private storeCurrentState = (className: string): void => {
    const isExpanded = !!className;
    this.saveDiagnosisBlock(isExpanded);
  };

  private saveDiagnosisBlock = (isExpanded: boolean): void => {
    saveToSessionStorage({
      [COMPONENT_KEY]: {
        [getUid(this.props)]: isExpanded,
      },
    });
  };
}

export default DiagnosisBlock;
