import { Col, Container, Row } from 'react-grid-system';
import * as React from 'react';
import { IFooterLink, ILinkImage, IImageFooterLink } from './Interfaces/FooterLink';
import { ILazy } from '../../interfaces/Lazy';
import { IFooterPropsFields } from './Interfaces/Component.props';
import CommonLink from '../CommonLink';
import { EVENT, CATEGORY, ACTION, DATA } from '../../analytics/constants';
import { TARGET } from '../../links/constants';
import NoIndex from '../NoIndex';
import get from 'lodash/get';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Footer.scss';

type FooterAllProps = IFooterPropsFields;

const Footer: React.FC<FooterAllProps> = ({ data }: FooterAllProps) => (
  <NoIndex>
    <div className="footer">
      {data && (
        <Container fluid={true} className="layout">
          <Row align={'start'} className="flex">
            <Col xs={12} sm={6} lg={3} className="footer__links">
              <div className="footer__headline">{data.leftLinksHeader}</div>
              <ul className="footer__list list-no-style">
                {data.leftLinks.map((item, key) => (
                  <FooterLink key={key} keyProp={key} item={item} />
                ))}
              </ul>
            </Col>
            <Col xs={12} sm={6} lg={3} className="footer__links">
              <div className="footer__headline">{data.midLinksHeader}</div>
              <ul className="footer__list list-no-style">
                {data.midLinks.map((item, key) => (
                  <FooterLink key={key} keyProp={key} item={item} />
                ))}
              </ul>
            </Col>
            <Col xs={12} sm={6} lg={3} className="footer__links">
              <div className="footer__headline">{data.rightLinksHeader}</div>
              <ul className="footer__list list-no-style">
                {data.rightLinks.map((item, key) => (
                  <FooterLink key={key} keyProp={key} item={item} />
                ))}
              </ul>
            </Col>
            <Col xs={12} sm={6} lg={3} className="footer__links">
              <div className="footer__headline">{data.membershipHeader}</div>
              <a className="footer__button" href={get(data, 'signupUrl.value', ``)} target={'_blank'} rel="noreferrer">
                {data.actionLabel}
              </a>
              <div className="footer__headline">{data.connectLabel}</div>
              <ul className="list-no-style footer__list footer__list_connect">
                {data.socialLinks.map((item, key) => (
                  <ImageLink key={key} keyProp={key} {...{ item, className: 'footer__icon', isAltLabel: true }} />
                ))}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ul className="list-no-style footer__list footer__list_departments">
                {data.departmentLinks.map((item: ILinkImage, key: number) => (
                  <ImageLink key={key} keyProp={key} {...{ item, className: 'footer__logo' }} />
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  </NoIndex>
);

const getIsExternal = (target: string): boolean => target === TARGET.blank;

const getLinkDataAttributes = (isExternal: boolean, dataLabel: string): object => {
  return {
    ...(isExternal && {
      [DATA.label]: dataLabel,
      [DATA.event]: EVENT.click,
      [DATA.category]: CATEGORY.userInteraction,
      [DATA.action]: ACTION.external,
    }),
  };
};

const ImageLink: React.FC<IImageFooterLink> = (props: IImageFooterLink): React.ReactElement => {
  const {
    item: { linkUrl: href, linkTarget: target, linkType: linktype, imageAlt, imageUrl },
    isAltLabel,
    keyProp,
  } = props;
  const link = { value: { target, href, linktype } };
  const dataLabel = isAltLabel ? imageAlt : href;
  const dataAttributes = getLinkDataAttributes(getIsExternal(linktype), dataLabel);
  const lazyLoading: ILazy = { loading: 'lazy' };

  return (
    <li key={keyProp}>
      <CommonLink {...{ link }}>
        <img className={props.className} src={imageUrl} alt={imageAlt} {...dataAttributes} {...lazyLoading} />
      </CommonLink>
    </li>
  );
};

const FooterLink: React.FC<IFooterLink> = (props: IFooterLink): React.ReactElement => {
  const {
    item: { footerLinkUrl: href, footerLinkTarget: target, footerLinkType: linktype, footerLinkTitle },
    keyProp,
  } = props;
  const link = { value: { target, href, linktype } };
  const dataAttributes = getLinkDataAttributes(getIsExternal(linktype), href);

  return (
    <li key={keyProp}>
      <CommonLink {...{ link, dataAttributes }}>{footerLinkTitle || ''}</CommonLink>
    </li>
  );
};

export default withStyles(s)(Footer);
