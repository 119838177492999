import * as React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

import { IProps } from './Interfaces/Component.props';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import get from 'lodash/get';
import config from '../../temp/config';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Search.scss';

interface IState {
  isSearchBoxReady: boolean;
  holderClassStatus: string;
}

class Search extends React.Component<IProps, IState> {
  private placeholder;

  constructor(props: IProps) {
    super(props);

    this.placeholder = this.getPlaceholder();

    this.state = {
      isSearchBoxReady: false,
      holderClassStatus: 'search__field_hidden',
    };
  }

  public render = () => {
    return (
      <div className="search">
        <div className="search__area">
          <div className="search__field">
            <div className={this.state.holderClassStatus}>
              <Placeholder name="global-search" rendering={this.props.rendering} />
            </div>
            {!this.state.isSearchBoxReady && (
              <div
                className="coveo-for-sitecore-search-box-container CoveoSearchInterface Coveostate CoveoComponentState CoveoComponentOptions CoveoQueryController coveo-after-initialization CoveoDebug"
                style={{
                  display: 'block',
                }}
              >
                <div className="CoveoSearchbox">
                  <div className="CoveoOmnibox coveo-query-syntax-disabled magic-box">
                    <div className="magic-box-input">
                      <input
                        type="text"
                        role="searchbox"
                        form="coveo-dummy-form"
                        title={this.placeholder}
                        placeholder={this.placeholder}
                      />
                    </div>
                  </div>
                  <a
                    className="CoveoSearchButton coveo-accessible-button"
                    role="button"
                    aria-label="Search"
                    tabIndex={0}
                  >
                    <span className="coveo-search-button">
                      <svg
                        focusable="false"
                        enableBackground="new 0 0 20 20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        className="coveo-search-button-svg"
                      >
                        <g fill="currentColor">
                          <path
                            className="coveo-magnifier-circle-svg"
                            d="m8.368 16.736c-4.614 0-8.368-3.754-8.368-8.368s3.754-8.368 8.368-8.368 8.368 3.754 8.368 8.368-3.754 8.368-8.368 8.368m0-14.161c-3.195 0-5.793 2.599-5.793 5.793s2.599 5.793 5.793 5.793 5.793-2.599 5.793-5.793-2.599-5.793-5.793-5.793"
                          />
                          <path d="m18.713 20c-.329 0-.659-.126-.91-.377l-4.552-4.551c-.503-.503-.503-1.318 0-1.82.503-.503 1.318-.503 1.82 0l4.552 4.551c.503.503.503 1.318 0 1.82-.252.251-.581.377-.91.377" />
                        </g>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  public shouldComponentUpdate(nextProps) {
    const oldSearchId = get(this.props, 'rendering.placeholders.global-search[0].uid');
    const newSearchId = get(nextProps, 'rendering.placeholders.global-search[0].uid');

    const isNoUpdate = oldSearchId === newSearchId;
    const isSearchPage = window.location.pathname === '/searchresults';

    return isSearchPage ? true : !isNoUpdate;
  }

  public componentDidMount() {
    window.addEventListener('afterInitializationTrigger', this.handleAfterInitialization);
  }

  public componentWillUnmount() {
    window.removeEventListener('afterInitializationTrigger', this.handleAfterInitialization);
  }

  private getPlaceholder = (): string => {
    const componentName = 'componentName';
    const globalSearch = get(this.props, `sitecoreContext.route.placeholders["global-search"]`, []);
    const sectionName = 'CoveoExternalComponentsSection';
    const coveoExternalComponentsSection = globalSearch.find(item => item[componentName] === sectionName);
    const searchBoxName = 'CoveoSearchbox';
    const externalComponents = get(
      coveoExternalComponentsSection,
      'placeholders["jss-coveo-ui-external-components"]',
      []
    );
    const coveoSearchbox = externalComponents && externalComponents.find(item => item[componentName] === searchBoxName);

    return get(coveoSearchbox, 'fields.properties.value.placeholder', '');
  };

  private handleAfterInitialization = (): void => {
    this.setState({
      isSearchBoxReady: true,
      holderClassStatus: 'search__field_visible',
    });
  };
}

export default withStyles(s)(withSitecoreContext()(enhanceWithClickOutside(Search)));
