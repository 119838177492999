import { Text } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import './Hero.scss';
import { IHeroBaseProps as IProps } from './Interfaces/Sitecore.props';
import * as _ from 'lodash';

const Hero: React.FC<IProps> = (props: IProps) => (
  <div className={'hero'}>
    <p>Hero Component !</p>
    <div>
      <Text field={_.get(props, 'fields.heading', {})} />
    </div>
  </div>
);

export default Hero;
