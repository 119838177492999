import { Text } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import get from 'lodash/get';
import { IAuthorsBaseProps } from './Interfaces/Component.props';
import AuthorsBlock from '../AuthorsBlock';
import CommonLink from '../CommonLink';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './../Widget/Widget.scss';

const Authors: React.FC<IAuthorsBaseProps> = (props: IAuthorsBaseProps) => (
  <div className="widget">
    <Text tag="h2" field={get(props, 'fields.heading', {})} className="widget__headline" />
    {get(props, 'fields.authorsBlocks').map((item: any, key: number) => (
      <AuthorsBlock item={item} key={key} keyProp={key} />
    ))}
    {get(props, 'fields.link.value.href') &&
      (get(props, 'fields.linkName.value') || get(props, 'fields.link.value.text')) && (
        <CommonLink
          className="widget__link"
          link={get(props, 'fields.link')}
          children={get(props, 'fields.linkName.value')}
        />
      )}
  </div>
);

export default withStyles(s)(Authors);
