import { breakpoints, sessionStorageSpace } from '../constants';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import _ from 'lodash';
import { IImageSize } from '../interfaces/ImageSize';
import { LINK_TYPE } from '../links/constants';
import WebFont from 'webfontloader';
import { setConfiguration } from 'react-grid-system';
import he from 'he';

const getLabelWithoutUniqueNumberId = (label: string): string => {
  return label.split('-')[0];
};

const replaceAll = (text: string, mapObject: any): string => {
  const regularExpression = new RegExp(Object.keys(mapObject).join('|'), 'gi');

  return text?.replace(regularExpression, (matched): string => {
    return mapObject[matched.toLowerCase()];
  });
};

const getFormattedId = (prefixes: string[], id: string): string => {
  const replaceMap: any = {};
  prefixes.forEach(prefix => (replaceMap[prefix] = ''));
  const formattedId = replaceAll(id, replaceMap);

  return getLabelWithoutUniqueNumberId(formattedId);
};

const isIdFromSelection = (selectors: string[], id: string): boolean => {
  return selectors.some(selectorItem => `${id}`.includes(selectorItem));
};

const getIdAttribute = (event: any, attribute: string): string => {
  return `${_.get(event, 'target', {}).getAttribute(attribute)}`;
};

const goTop = (): void => {
  if (typeof window !== 'undefined') {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

const getOffset = className => _.get(document.getElementsByClassName(className), '[0].offsetHeight') || 0;

const getFormattedIdFromUrl = (url: string): string =>
  url
    .replace(/[\./ ,:-]+/g, '-')
    .replace(/[?=#$]+/g, '-');

const makeAnchorFromTitle = (title: string): string => {
  const anchor = `${title}`.replace(/\W+/g, '-').toLowerCase();

  return anchor;
};

const resetValue = 'auto';

// For IE11
const updateImgRender = (imageSizes: IImageSize[], url: string): object => {
  if (!url) { return {}; }

  const { width, height } = imageSizes[0];
  const fullUrl = url.split('?')[0];
  let src = '';
  let imageHeight = 0;
  let imageWidth = 0;

  if (width) {
    src = `${fullUrl}?w=${width}`;
    imageWidth = width;
  }

  if (height) {
    src = `${fullUrl}?h=${height}`;
    imageHeight = height;
  }

  return {
    ...(imageWidth && { width: imageWidth, height: resetValue }),
    ...(imageHeight && { height: imageHeight, width: resetValue }),
    ...(src && { src }),
  };
};

// For modern browsers
const srcsetRender = (imageSizes: IImageSize[], url: string) => {
  if (!url) { return {}; }

  const fullUrl = url.split('?')[0];
  // tslint:disable-next-line
  let srcSet = '';
  let sizes: string = '';
  let src = '';
  let imageHeight = 0;

  imageSizes.map(({ breakpoint, width, height }) => {
    if (width) {
      srcSet += `${fullUrl}?w=${width} ${width}w, `;
      sizes += `(max-width: ${breakpoint}px) ${width}px, `;
    }

    if (height) {
      src = `${fullUrl}?h=${height}`;
      imageHeight = height;
    }
  });

  srcSet = srcSet?.replace(/,\s$/, '');
  sizes += '100vw';

  return {
    ...(imageHeight && {height: imageHeight, width: resetValue}),
    ...(src && { src }),
    ...(srcSet.length && { srcSet }),
    ...(sizes && { sizes }),
  };
};

const getLink = (href: string, isExternal: boolean): LinkField => {
  return {
    value: {
      href,
      linktype: getLinkType(isExternal),
    },
  };
};

const getSimpleLink = (url: string): LinkField => {
  return {
    value: {
      href: url,
      linktype: LINK_TYPE.internal,
    },
  };
};

const getLinkType = (isExternal: boolean): string => (isExternal ? LINK_TYPE.external : LINK_TYPE.internal);

const slash = '/';

const getPathSegments = () => {
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split(slash).filter(segment => !!segment);

    return pathSegments;
  }

  return [];
};

const deeply = (mapper: any) => {
  return (obj: any, fn: any) => {
    return mapper(
      _.mapValues(obj, v => {
        return _.isPlainObject(v)
          ? deeply(mapper)(v, fn)
          : _.isArray(v)
          ? v.map(x => {
              return deeply(mapper)(x, fn);
            })
          : v;
      }),
      fn
    );
  };
};

const getRouteName = (pathname: string): string => {
  const path = pathname.split('/');
  return path[path.length - 1];
};

const fireEvent = (el: any, etype: string, data?: any): void => {
  if (el.fireEvent) {
    el.fireEvent(`on${etype}`);
  } else {
    const evObj: any = document.createEvent('Events');
    evObj.initEvent(etype, true, false);
    if (data) {
      evObj.data = data;
    }
    el.dispatchEvent(evObj);
  }
};

const getIsNotDesktop = (): boolean | undefined => {
  if (typeof window === 'undefined') { return; }
  const ua = navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
  const isBelowLastBreakPoint = document.documentElement.clientWidth <= breakpoints.md;

  return isBelowLastBreakPoint || isMobile;
};

const getIsMac = (): boolean => navigator.platform.indexOf('Mac') > -1;

// Due to bug in Mac - https://timcchang.com/blog/how-to-make-scrollto-work-across-browsers/
const scrollWithTransform = ({
  base,
  duration,
  top,
  easingFn = t => t * t * t * t,
}): void => {
  // Cache the position of the element
  const cachePosition = top;

  // Cache current time in milliseconds
  const startTime = Date.now();

  // We will be using requestAnimationFrame (rAF) for animating,
  // so we'll need to keep a reference of the returned rAF id.
  let animationId;

  const animate = () => {
    const elapsed = Date.now() - startTime;

    if (elapsed < duration) {
      const ease = easingFn(elapsed / duration);

      // If easingFn returns a value greater than 1,
      // just return the target.top * -1 to prevent overshooting the element.
      const translate = ease > 1 ? cachePosition  : ease * (top);

      // Apply a transform to the base element
      base.style.transform = `translateY(${-translate}px)`;
      animationId = requestAnimationFrame(animate);
    } else {
      setTimeout(() => {
        cancelAnimationFrame(animationId);
        base.style.transform = 'translateY(0px)';
        window.scrollTo({ top: cachePosition });
      }, 0);
    }
  };

  animate();
};

const loadWebFonts = (isDisconnectedMode: boolean, activeCallback: () => {}): void => {
// font loader initialization
  WebFont.load({
    custom: {
      families: ['Suisse Intl', 'Suisse Intl Bold'],
      urls: [isDisconnectedMode ? '/assets/fonts/_suisseIntl.css' : '/dist/aosr/assets/fonts/_suisseIntl.css'],
    },
    active: activeCallback()
  });
};

const setGridConfiguration = () => {
  const { xs, sm, md, lg, gutter } = breakpoints;

  setConfiguration({
    breakpoints: [xs, sm, md, lg],
    gutterWidth: gutter,
  });
};

const isIE = () => {
  if (typeof document !== 'undefined') {
    return !!(document as any).documentMode;
  }
  return false;
};

const getTextFromFullContent = (fullContent: string): string => {
  const contentDocument = new DOMParser().parseFromString(fullContent, 'text/html').documentElement;
  const firstElement = _.get(contentDocument, 'childNodes[1].childNodes[0]');
  const innerHtml = _.get(firstElement, 'innerHTML');
  const content = innerHtml ? innerHtml : _.get(firstElement, 'textContent');
  // In case of e.g. two <p> tags following one by one, we need to add spaces that are not added when 'textContent' is used
  const contentWithoutTags = he.decode(`${content}`.replace(/<[^>]*>?/gm, ' '));

  return firstElement ? contentWithoutTags : '';
};

const saveToSessionStorage = (data: object) => {
  if (typeof window !== "undefined") {
  const currentData = JSON.parse(`${sessionStorage.getItem(sessionStorageSpace)}`);
  const updatedData = _.merge({}, currentData, data);
  sessionStorage.setItem(sessionStorageSpace, JSON.stringify(updatedData));
  }
};

const updateInSessionStorage = (data: object, key: string) => {
  const updatedData = JSON.parse(`${sessionStorage.getItem(sessionStorageSpace)}`);

  if (updatedData) {
    updatedData[key] = data;
    sessionStorage.setItem(sessionStorageSpace, JSON.stringify(updatedData));
  }
};

const loadFromSessionStorage = (path: string): any => {
  const currentData = JSON.parse(`${sessionStorage.getItem(sessionStorageSpace)}`);
  return _.get(currentData, path);
};

const removeFromSessionStorage = (key: string, filter: string | boolean): void => {
  const currentData = JSON.parse(`${sessionStorage.getItem(sessionStorageSpace)}`);
  const isFilterReady = key && currentData && filter;
  let updatedData = currentData;

  if (isFilterReady && currentData[key] && currentData[key].hasOwnProperty(filter)) {
    updatedData = _.omit(currentData, `${key}.${filter}`);
  }

  if (isFilterReady && currentData[key] === filter) {
    updatedData = _.omit(currentData, `${key}`);
  }

  sessionStorage.setItem(sessionStorageSpace, JSON.stringify(updatedData));
};

const getUid = (props: any, url?: string): string => {
  const path = url ? url : getPathSegments().join('-');
  return `uid-${path}}-${_.get(props, 'rendering.uid')}`;
};

const getHeightsInRem = (context: any, screenClass: string): string => {
  const baseUnitInPx = 10;
  const unit = 'rem';
  const isTabletWidth = screenClass === 'md';
  const tabletOffset = 55;
  const desktopOffset = 75;
  const valueInPx = context.headerHeight +
    (isTabletWidth
      ? tabletOffset
      : desktopOffset);
  return valueInPx ? `${valueInPx / baseUnitInPx}${unit}` : `0${unit}`;
};

const getIsNumberPrefixEnabled = (context: any, props: any): boolean => {
  const isInformationPage = _.get(context, 'isInformationPage');
  const isParentAPage = _.get(props, 'sitecoreContext.isparentapage');
  const isNumberPrefixEnabled = !(isInformationPage && isParentAPage);

  return isNumberPrefixEnabled;
};

const getRenderingWithNumbers = (rendering: ComponentRendering, placeholder: string): any => {
  const renderingWithNumbers = _.cloneDeep(rendering);
  const components = _.get(renderingWithNumbers, `placeholders['${placeholder}']`, []);
  const deckContainerComponentName = 'DeckContainer';
  let globalIndex = -1;
  
  components.forEach((component) => {
    const componentName = 'componentName';
    const index = 'index';
    const isDeckComponent = component[componentName] === deckContainerComponentName;
    const isHeadlineAvailable = isDeckComponent && _.get(component, 'fields.headline.value');
    const isGlobalIndex = isDeckComponent && isHeadlineAvailable;

    if (isGlobalIndex) {
      globalIndex = globalIndex + 1;
    }

    component[index] = globalIndex;
  });

  return renderingWithNumbers;
};

const previousPages = {
  add: (text: string, url: string): void => {
    const pages = previousPages.get() || [];
    pages.push({ text, url });
    saveToSessionStorage({ previousPages: pages });
  },
  remove: (): void => {
    const pages = _.cloneDeep(previousPages.get()) || [];
    pages.pop();
    updateInSessionStorage(pages, `previousPages`);
  },
  get: (): [{[key: string]: string}] => {
    return typeof window !== 'undefined' && loadFromSessionStorage(`previousPages`) || [];
  },
};

const deck = {
  handleClick: (event: MouseEvent | any): void => { // TODO check type
    const dataAttribute = 'data-id';
    const deckContainer = event.target && event.target.closest(`div[${dataAttribute}]`);

    if (deckContainer) {
      const hash = `#${deckContainer.getAttribute(dataAttribute)}`;
      fireEvent(window, 'hashChange', { data: hash });
    }
  }
};

const searchPage = {
  key: 'searchPage',
  prevPageKey: 'fromPageToSearch',
  savePrevPage: (pathname: string, hash: string): void => {
    saveToSessionStorage({
      [searchPage.prevPageKey]: {
        pathname, hash
      }
    });
  },
  getPrevPage:(): {[key: string]: string} => {
    return typeof window !== 'undefined' && loadFromSessionStorage(searchPage.prevPageKey) || {};
  },
  updateParams: (pathname: string, hash: string): void => {
    saveToSessionStorage({
      [searchPage.key]: {
        pathname, hash
      }
    });
  },
  getParams: (): {[key: string]: string} => {
    return typeof window !== 'undefined' && loadFromSessionStorage(searchPage.key) || {};
  },
  handleClick: (event: MouseEvent | any): void => { // TODO check types
    const searchLinkClass = 'CoveoResultLink';
    const targetClassList = _.get(event, 'target.classList');
    const isSearchResultLink = targetClassList && targetClassList.contains(searchLinkClass);
    const isNeedToUpdateSearchPageUrl = isSearchResultLink && getIsNeedToRedirect(event);

    if (isNeedToUpdateSearchPageUrl) {
      const { pathname, hash } = window.location;
      searchPage.updateParams(pathname, hash);
      window.scrollTo(0, 0);
    }
  },
};

const getMobileRendering = (rendering: any, detailsPagePlaceholder: string): any => {
  const componentNamedetailStickyTop = 'DetailStickyTop';
  const stickyLayout = 'jss-sticky-layout';
  const anchorSelectorComponentName = 'AnchorSelector';
  const mobileRendering =_.cloneDeep(rendering);
  const treatmentDetailsPage = mobileRendering.placeholders[detailsPagePlaceholder];
  const detailStickyTop = treatmentDetailsPage && treatmentDetailsPage
    .find(component => component.componentName === componentNamedetailStickyTop);
  const jssStickyLayout = detailStickyTop && detailStickyTop.placeholders[stickyLayout];
  const anchorSelector = jssStickyLayout && jssStickyLayout
    .find(component => component.componentName === anchorSelectorComponentName);

  if (anchorSelector) {
    jssStickyLayout.push(jssStickyLayout.splice(jssStickyLayout.indexOf(anchorSelector), 1)[0]);
  }

  return mobileRendering;
};

const getTargetIsLink = (event: Event): boolean => {
  const target = _.get(event, 'target');
  const targetTag = `${_.get(target, 'tagName')}`.toLowerCase();
  const tagLink = 'a';

  return targetTag === tagLink;
};

const getIsInternalHref = (href: string): boolean => {
  const getIsInternal = new RegExp(window.location.host);
  return getIsInternal.test(href);
};

const getIsNeedToRedirect = (event: Event): boolean => {
  if (getTargetIsLink(event)) {
    const href = getTargetAttribute(event, 'href');

    const isInternal = getIsInternalHref(href) || !href.includes('http');
    const pathName = href && new URL(href).pathname;
    const isNeedToRedirect = !!(isInternal && pathName);

    return isNeedToRedirect;
  }

  return false;
};

const getIsActionButton = event => {
  const actionButtonClass = 'action';

  return event.target.classList.contains(actionButtonClass);
};

const getTarget = (event: Event): any => _.get(event, 'target');

const getTargetAttribute = (event: Event, attr: string): string =>
  _.get(getTarget(event), attr, '');

const getIsExternalLink = (event: Event): boolean => {
  if (getTargetIsLink(event)) {
    const href = getTargetAttribute(event, 'href');
    return href.includes('http') && !getIsInternalHref(href);
  }

  return false;
};

const getNormalizedNumber = (k: number, digitsAfterPoint: number): number => {
  return Number(k.toFixed(digitsAfterPoint));
};

const getMainPlaceholder = (route: any): any => {
  return _.get(route, 'placeholders["jss-main"]', []);
};

const isNotPage = (route: any, pageComponentName: string): boolean =>
  !getMainPlaceholder(route)
    .some(component => component.componentName === pageComponentName);

const isRestrictedPage = (props: any): boolean => {
  const mainPlaceholder = 'jss-main';
  const restrictedPageName = 'StyleguideLayoutRestrictedPage';
  const components = _.get(props, `route.placeholders[${mainPlaceholder}]`);

  return components.some(component => component.componentName === restrictedPageName);
};

const getStepFromRoute = (route: any): number => {
  const layoutComponent = getMainPlaceholder(route)
    .find(component => component.componentName.includes('Styleguide'));

  return layoutComponent ? getPageIndex(layoutComponent.componentName) : 0;
};

const LAYOUT = {
  home: 'Styleguide-Layout',                                      // step 1
  anatomicArea: 'Styleguide-Layout-AnatomicAreaPage',             // step 2
  diagnosis: 'Styleguide-Layout-DiagnosisPage',                   // step 3
  indication: 'Styleguide-Layout-IndicationPage',                 // step 4
  information: 'Styleguide-Layout-InformationPage',               // step 5
  treatmentDetails: 'Styleguide-Layout-TreatmentDetailsPage',     // step 5
  search: 'StyleguideLayoutSearchPage',                           // step 6
  restricted: 'StyleguideLayoutRestrictedPage',                   // step 7
  informationReference: 'StyleguideInformationReferencePage'      // step 8
};

const getPageIndex = (layout: string): number => {
  const { home, anatomicArea, diagnosis,
    indication, information, treatmentDetails, search, restricted, informationReference
  } = LAYOUT;
  let step = 0;

  switch (layout) {
    case home:
      step = 1;
      break;
    case anatomicArea:
      step = 2;
      break;
    case diagnosis:
      step = 3;
      break;
    case indication:
      step = 4;
      break;
    case informationReference:
    case information:
    case treatmentDetails:
      step = 5;
      break;
    case search:
      step = 6;
      break;
    case restricted:
      step = 7;
      break;
  }

  return step;
};

const scrollToRef = (ref: any): void => {
  if (!ref.current) {
    return;
  }

  const element = _.get(ref, 'current');

  // Scroll to top of the window
  element.scrollIntoView();
  requestAnimationFrame(() => {
    const top = element.getBoundingClientRect().top + window.pageYOffset;
    const offset = parseInt(element.getAttribute('data-offset'), 10) || 0;

    const progressBarWrapper = document.querySelector('.progressbar-wrapper');
    const progressBarHeight = parseInt(_.get(progressBarWrapper, 'offsetHeight', '0'), 10);

    const layoutBase = document.querySelector('.layout_base');
    const layoutBasePaddingTop = layoutBase
      ? parseInt(window.getComputedStyle(layoutBase).getPropertyValue('padding-top'), 10)
      : 0;

    const wrapper = document.querySelector('.animation-wrapper > div > .wrapper');
    const wrapperTop = wrapper ? parseInt(window.getComputedStyle(wrapper).getPropertyValue('padding-top'), 10) : 0;

    const y = top - (offset + progressBarHeight + layoutBasePaddingTop + wrapperTop);
    // Scroll to element including offset - header height, progress bar height, top paddings

    window.scrollTo(0, y);
  });
};

const headlineSpacer = `\u00A0\u00A0\u00A0`;

const getHeadlineWithFractureCode = (fractureCode: string, headline: string = '', spacer: string = headlineSpacer): string =>
  `${fractureCode}${spacer}${headline}`;

const getEnchancedHeadline = (
  isCorrectPage: boolean,
  fractureCode: string,
  headline?: { value?: string },
  spacer?: string
): { value?: string } => ({
  ...headline,
  ...(isCorrectPage && fractureCode && {
    ...{
      value: getHeadlineWithFractureCode(fractureCode, _.get(headline, 'value', ''), spacer)
    }
  })
});

const getLoginLinkWithParameters = (props: any): string => {
  const loginLink = _.get(props, 'sitecoreContext.loginLink');
  let currentUrl = _.get(props, 'sitecoreContext.url');
  let currentDomain = currentUrl && new URL(currentUrl).origin;
  currentUrl = encodeURIComponent(currentUrl);
  currentDomain = encodeURIComponent(currentDomain);
  const currentDomainQeuryparamName = 'HeadlessUrl';
  const currentUrlQeuryparamName = 'orgUrl';
  return `${loginLink}&${currentDomainQeuryparamName}=${currentDomain}&${currentUrlQeuryparamName}=${currentUrl}`;
};

const capitalizeFirstLetter = (value: string): string =>
  `${value[0].toUpperCase()}${value.substring(1)}`;

export {
  capitalizeFirstLetter,
  getLoginLinkWithParameters,
  isRestrictedPage,
  getEnchancedHeadline,
  getIsActionButton,
  LAYOUT,
  scrollToRef,
  getStepFromRoute,
  isNotPage,
  replaceAll,
  getLabelWithoutUniqueNumberId,
  getFormattedId,
  isIdFromSelection,
  getIdAttribute,
  goTop,
  getFormattedIdFromUrl,
  makeAnchorFromTitle,
  srcsetRender,
  updateImgRender,
  getLink,
  getSimpleLink,
  getPathSegments,
  deeply,
  getRouteName,
  fireEvent,
  getIsNotDesktop,
  loadWebFonts,
  setGridConfiguration,
  slash,
  isIE,
  getTextFromFullContent,
  getOffset,
  saveToSessionStorage,
  loadFromSessionStorage,
  removeFromSessionStorage,
  getUid,
  getIsNumberPrefixEnabled,
  getRenderingWithNumbers,
  getHeightsInRem,
  previousPages,
  getMobileRendering,
  getIsNeedToRedirect,
  getIsMac,
  scrollWithTransform,
  getNormalizedNumber,
  getTargetAttribute,
  getIsExternalLink,
  searchPage,
  deck
};
