import * as React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

import { IProps } from './Interfaces/Component.props';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import get from 'lodash/get';
// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Search.scss';
import {
  AtomicLayoutSection,
  AtomicSearchBox,
  AtomicSearchBoxQuerySuggestions
} from "@coveo/atomic-react";
import { searchPage } from '../../helpers/utils';
import { withRouter } from '../../lib/withRouter';
// import {} from "@coveo/atomic";

interface IState {
  isSearchBoxReady: boolean;
  holderClassStatus: string;
}

class CoveoSearch extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isSearchBoxReady: false,
      holderClassStatus: 'search__field_hidden',
    };
  }

  public render = () => {

    const restrictedPage = get(this.props, 'sitecoreContext.restrictedPageHeadline');

    return (
      <div className="search__area" id="aosr_search_layout">
        {!restrictedPage && (
          <AtomicLayoutSection section="search" className="search__field">
            <AtomicSearchBox className='CoveoSearchbox'
              redirectionUrl={getRedirectUrl(this.props)}
              clearFilters={false}>
              <AtomicSearchBoxQuerySuggestions />
            </AtomicSearchBox>
          </AtomicLayoutSection>
        )}
      </div>
    );
  };

}
function getSearchUrl(props) {
  const { search } = get(props, 'location', {});
  const searchQueryParameter = 'searchurl';
  const urlParams = new URLSearchParams(search);

  return urlParams.get(searchQueryParameter) || '';
};
function getRedirectUrl(props) {
  const currentItemPath = get(props, 'sitecoreContext.itemPath');
  let searchPagePath = get(props, 'sitecoreContext.header.searchPagePath', '');
  searchPagePath = searchPagePath ? searchPagePath.toLowerCase() : '/searchresultsnew';
  var searchPath = currentItemPath == searchPagePath ? '' : searchPagePath;

  const searchUrl = getSearchUrl(props);
  if (!searchUrl && !isEditorActive()) {
    searchPath != '' && searchPage.savePrevPage(currentItemPath, '');
  }
  return searchPath;
}


export default withStyles(s)(withSitecoreContext()(withRouter(enhanceWithClickOutside(CoveoSearch))));
