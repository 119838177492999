const breakpoints = {
  xs: 576,
  sm: 712,
  md: 992,
  lg: 1100,
  xl: 2560,
  gutter: 20,
};

const PIXEL_RATIO = 1; // Always use pixel ratio 1 to reduce loading time of images

const getRoundSize = (size: number): number => Number((size * PIXEL_RATIO).toFixed(0));

const deckScrollDuration = 400;

const detailScrollTimeout = deckScrollDuration + 50;

const detailPageImagesSizes = [
  { breakpoint: breakpoints.sm, width: getRoundSize(620) },
  { breakpoint: breakpoints.md, width: getRoundSize(665) },
  { breakpoint: breakpoints.xl, width: getRoundSize(400) },
];

const detailedDescriptionImagesSizes = [
  { breakpoint: breakpoints.xl, width: getRoundSize(172) },
];

const iOS = typeof navigator !== 'undefined' && (/iPad|iPhone|iPod/.test(navigator.platform) ||
  (typeof navigator !== 'undefined' && navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
  (typeof window !== 'undefined' && (!window as any).MSStream);

// Mobile Safari doesn't allow updating the hash more than 100 times per 30 seconds.
// https://forums.developer.apple.com/message/208015#208015
const historyUpdateTimeout = iOS ? 30 * 1000 / 100 : 0;

const treatmentPageImagesSizes = [{ breakpoint: breakpoints.sm, width: getRoundSize(140) }];

const diagnosPageImagesSizes = [{ breakpoint: breakpoints.sm, height: getRoundSize(90) }];

const teasersImagesSizes = [
  { breakpoint: breakpoints.sm, width: getRoundSize(285) },
  { breakpoint: breakpoints.xl, width: getRoundSize(200) },
];

const tokenize = 'words';

const sessionStorageSpace = 'aosr';

const noIndex = {
  begin: 'BEGIN NOINDEX',
  end: 'END NOINDEX'
};

const onImagesLoadedTimeout = 600; // Timeout after images loaded

const COMPONENT_KEY = { // TODO use enums
  diagnosisCard: 'diagnosisCard',
  treatment: 'treatment',
  informationReferenceCard: 'InformationReferenceCard'
};

const RESTRICTED_CONTENT_CLASS = 'paywall'; // TODO use itfor the Restricted Microformat component

export {
  RESTRICTED_CONTENT_CLASS,
  COMPONENT_KEY,
  breakpoints,
  detailedDescriptionImagesSizes,
  detailPageImagesSizes,
  treatmentPageImagesSizes,
  diagnosPageImagesSizes,
  teasersImagesSizes,
  tokenize,
  sessionStorageSpace,
  deckScrollDuration,
  detailScrollTimeout,
  noIndex,
  onImagesLoadedTimeout,
  historyUpdateTimeout,
};
