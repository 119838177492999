import React from 'react';
import get from 'lodash/get';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { IProps } from './Interfaces/Component.props';
import TruncatedContent from '../TruncatedContent';
import { ILayoutContext } from '../../interfaces/SitecoreContext';
import TreatmentDetailsPageContext from '../Styleguide-Layout-TreatmentDetailsPage/TreatmentDetailsPageContext';
import { getEnchancedHeadline } from '../../helpers/utils';
import { ITruncatedContentFields } from '../TruncatedContent/interfaces/Component.props';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './SubHeadline.scss';

interface IState {
  sitecoreContext:
    | ILayoutContext
    | {}
    | {
        context: any;
        fractureCodeValue: string;
      };
}

class SubHeadlineBlock extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      sitecoreContext: {},
    };
  }

  public render() {
    const props = this.props;
    const context = get(this.state, 'sitecoreContext');
    const fractureCodeValue = get(context, 'fractureCodeValue', '');
    const subHeadline = get(context, 'route.fields.subHeadline');
    const contentFields = { ...props.fields, fullContent: subHeadline };
    const isSettingsForTruncate = get(props, 'fields.lines.value');
    const isTruncatedContent = subHeadline && isSettingsForTruncate;
    const { fullContent } = contentFields;
    const headline = isTruncatedContent ? fullContent : subHeadline;
    const getHeadline = (isTreatmentDetailsPage: boolean): any =>
      getEnchancedHeadline(isTreatmentDetailsPage, fractureCodeValue, headline);
    const getFullContent = (isTreatmentDetailsPage: boolean): ITruncatedContentFields => ({
      ...contentFields,
      ...{
        fullContent: getHeadline(isTreatmentDetailsPage),
      },
    });

    return (
      <TreatmentDetailsPageContext.Consumer>
        {({ isTreatmentDetailsPage }) => (
          <>
            {isTruncatedContent ? (
              <TruncatedContent
                className="subhealdine page-title__subtitle"
                fields={getFullContent(isTreatmentDetailsPage)}
                tagName="h2"
              />
            ) : (
              <Text
                tag="h2"
                className="subhealdine title__subtitle normal"
                field={getHeadline(isTreatmentDetailsPage)}
              />
            )}
          </>
        )}
      </TreatmentDetailsPageContext.Consumer>
    );
  }

  public componentDidMount() {
    this.setState({
      sitecoreContext: this.props.sitecoreContext,
    });
  }
}

export default withStyles(s)(withSitecoreContext()(SubHeadlineBlock));
