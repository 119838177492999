import { Col, Container, Row } from 'react-grid-system';
import { NavLink } from 'react-router-dom';
import { withRouter } from '../../lib/withRouter';
import { Image, withSitecoreContext, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import { IProps } from './Interfaces/Component.props';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import UserSettingsOverlay from '../UserSettingsOverlay';
import { IUserSettingsOverlay } from '../UserSettingsOverlay/Interfaces/Component.props';
import NoIndex from '../NoIndex';
import { getLoginLinkWithParameters } from '../../helpers/utils';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Header.scss';
import CoveoSearch from '../Search/CoveoSearch';

interface IState {
  container: any;
  headerHeight: number;
  headerWidth: number;
  loginLinkWithParameters: string;
}

const THROTTLE_DELAY = 50;

class Header extends React.Component<IProps, IState> {
  private resizeListener;

  constructor(props: IProps) {
    super(props);

    this.resizeListener = throttle(() => this.triggerHeaderUpdated(), THROTTLE_DELAY);

    this.state = {
      container: React.createRef(),
      headerHeight: 0,
      headerWidth: 0,
      loginLinkWithParameters: '',
    };
  }

  public render = () => {
    return (
      <NoIndex>
        <div
          className={`header__wrapper ${this.getIsEditModeforHeader()} ${
            get(this.props, 'fields.headline') ? '' : 'header__wrapper_minified'
          }`}
        >
          <div className={this.getHeaderClass()} ref={this.state.container}>
            <div>
              <Container fluid={true} className="layout">
                <Row align={'center'} className="flex">
                  <Col xs={8} className="header__logo">
                    <NavLink to={this.getHomePage()}>
                      <Image
                        style={{
                          marginRight: '1rem',
                        }}
                        class="header__logo_leftPart"
                        field={this.getMappedValue('leftPartLogoUrl')}
                      />
                      <Image className="header__logo_rightPart" field={this.getMappedValue('rightPartLogoUrl')} />
                    </NavLink>
                  </Col>
                  <Col xs={4} className="header__login">
                    <div className="header__login-placeholder">
                      {this.isAuthorized() ? (
                        <UserSettingsOverlay {...this.getUserSettingsOverlayData()} />
                      ) : (
                        /* TODO: create the 'ButtonLogin' component */
                        <form action={this.state.loginLinkWithParameters} method="post">
                          <button className="header__login-button">{this.getMappedValue('buttonLogin')}</button>
                        </form>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="header__placeholder">
                  <Col xs={12}>
                    <div className="header__dynamic">
                      {!isEditorActive() && <CoveoSearch />}
                    </div>
                    {get(this.props, 'children', '')}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </NoIndex>
    );
  };

  public componentDidMount() {
    this.setState({
      headerHeight: this.state.container.current.offsetHeight,
      headerWidth: this.state.container.current.offsetWidth,
      loginLinkWithParameters: getLoginLinkWithParameters(this.props),
    });

    window.addEventListener('resize', this.resizeListener, { passive: true, capture: true });
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener, true);
  }

  public componentDidUpdate(previousProps) {
    this.triggerHeaderUpdated();
    window.requestAnimationFrame(() => {
      this.updateSameLocationHeaderHeight();
    });
    const isRouteChanged = this.props.location !== previousProps.location;
    if (isRouteChanged) {
      this.setState({
        loginLinkWithParameters: getLoginLinkWithParameters(this.props),
      });
    }
  }

  public getIsEditModeforHeader() {
    return get(this.props, 'addClassSitecore') ? 'header__wrapper_is-edit-mode' : '';
  }

  private triggerHeaderUpdated(): void {
    if (this.props.headerUpdated) {
      this.props.headerUpdated();
    }
  }

  private getUserSettingsOverlayData = (): IUserSettingsOverlay => {
    return {
      isDisconnectedMode: this.props.isDisconnectedMode,
      isHeaderCollapsed: !get(this.props, 'fields.headline'),
      leftPartLogoUrlInverse: this.getMappedValue('leftPartLogoUrlInverse'),
      rightPartLogoUrlInverse: this.getMappedValue('rightPartLogoUrlInverse'),
      ...get(this.props, 'sitecoreContext.userSettingsOverlay', {
        heading: {},
        close: {},
        image: {},
        links: [],
        loginUrl: '',
      }),
      userName: {
        value: get(this.props, 'sitecoreContext.userFullName'),
      },
      logout: {
        value: {
          text: 'Logout',
          href: get(this.props, 'sitecoreContext.logoutLink'),
        },
      },
    };
  };

  private isAuthorized = (): boolean => {
    const domain = get(this.props, 'sitecoreContext.user.domain', '');
    const isAuthorized = domain && domain !== 'sitecore';

    return !!isAuthorized;
  };

  private getHeaderClass = (): string => {
    return `header
            header_minified
            ${this.getAnimationClass()}
            ${this.getHeaderCollapsedClass()}
          `;
  };

  private getHeaderCollapsedClass = (): string => {
    const authorizedClass = this.isAuthorized() ? 'header_authorized' : '';

    return get(this.props, 'fields.headline')
      ? `${authorizedClass}`
      : `header_collapsed header_minified ${authorizedClass}`;
  };

  private getHomePage = (): string => {
    return '/';
  };

  private getMappedValue = (name: string): any => {
    return get(this.props, `fields.${name}`);
  };

  private updateSameLocationHeaderHeight(): void {
    const currentHeight = get(this.state, 'container.current.offsetHeight');
    const isNotSameHeight = currentHeight !== this.state.headerHeight;

    if (isNotSameHeight) {
      this.updateHeaderHeightState();
    }
  }

  private updateHeaderHeightState(): void {
    this.setState({
      headerHeight: get(this.state, 'container.current.offsetHeight'),
    });
  }

  private getAnimationClass(): string {
    return get(this.props, 'isAnimation') ? 'header_animation' : '';
  }
}

export default withStyles(s)(withRouter(withSitecoreContext()(Header)));
