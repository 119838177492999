// import React, { Component } from 'react';
// import { Route, useLocation, useNavigate, useParams } from 'react-router-dom-v6';

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// export const withRouter = (props:any) => {
//     const location = useLocation();
//     const history = useNavigate();
//     const params = useParams();
//     return (
//         <Route
//             {...props}
//             location={location}
//             params={params}
//             navigate={history}
//         />
//     );
// };

// import React from 'react';
// import { useLocation, useNavigate, useParams } from 'react-router-dom-v6';

// export function withRouter<ComponentProps>(Component: React.FunctionComponent<ComponentProps>) {
//     function ComponentWithRouterProp(props: ComponentProps) {
//         const location = useLocation();
//         const navigate = useNavigate();
//         const params = useParams();

//         return <Component {...props} router={{ location, navigate, params }} />;
//     }

//     return ComponentWithRouterProp;
// }

import React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params=useParams();
    return <Component navigate={navigate} location={location} params={params} {...props} />;
  };

  return Wrapper;
};

// export const withRouter = Component => props => {
//   const [callback, setCallback] = React.useState();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const action = useNavigationType();
//   const params = useParams();

//   const match = { params };

//   const history = {
//     push: (url, onMount) => {
//       if (onMount) {
//         setTimeout(() => navigate(url), 100);
//       } else {
//         navigate(url);
//       }
//     },
//     listen: (callback) => {
//       setCallback(() => callback);
//     }
//   }

//   React.useEffect(() => {
//     if (callback) {
//       console.log("in useeffect", "\ncallback:",callback, "\naction:", action, "\nlocation:",location);
//       callback(location, action);
//     }
//   }, [action, callback, location]);

//   return <Component {...props} {...{ history, location, match }} />;
// };
