import React from 'react';
import get from 'lodash/get';
import { Row, Col } from 'react-grid-system';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { IProps } from './Interfaces/Component.props';

const DiagnosisCategory: React.FC<IProps> = (props: IProps) => (
  <Row className="flex diagnoses">
    <Col xs={12}>
      <section className="diagnoses__section">
        <Text tag="h2" field={get(props, 'fields.category', {})} className="diagnoses__category" />
        <Placeholder name="diagnosis-cards-category" rendering={props.rendering} />
      </section>
    </Col>
  </Row>
);

export default DiagnosisCategory;
