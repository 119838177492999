import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { IProps } from './Interfaces/Component.props';
import { getEnchancedHeadline } from '../../helpers/utils';
import IndicationPageContext from '../Styleguide-Layout-IndicationPage/IndicationPageContext';
import InformationPageContext from '../Styleguide-Layout-InformationPage/InformationPageContext';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from '../ContentBlock/ContentBlock.scss';

class HeadlineBlock extends React.Component<IProps> {
  public render = () => {
    const {
      fields: { headline },
      sitecoreContext: { fractureCodeValue },
    } = this.props;
    const getHeadline = (isIndicationPage: boolean): object =>
      getEnchancedHeadline(isIndicationPage, fractureCodeValue, headline);

    return (
      <IndicationPageContext.Consumer>
        {({ isIndicationPage }) => (
          <InformationPageContext.Consumer>
            {({ isInformationPage }) => (
              <React.Fragment>
                <div>
                  <Text className="h1" tag="h1" field={getHeadline(isIndicationPage || isInformationPage)} />
                </div>
              </React.Fragment>
            )}
          </InformationPageContext.Consumer>
        )}
      </IndicationPageContext.Consumer>
    );
  };
}

export default withStyles(s)(withSitecoreContext()(HeadlineBlock));
