const LINK_TYPE = {
  external: 'external',
  internal: 'internal'
};

const TARGET = {
  blank: '_blank'
};


export {
  LINK_TYPE,
  TARGET
};
