import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Sticker from 'react-stickyfill';
import { IProps } from './Interfaces/Component.props';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './DetailStickyTop.scss';

class DetailStickyTop extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { rendering } = this.props;

    return (
      <Sticker>
        <div className="detail-sticky-top">
          <Placeholder name="jss-sticky-layout" rendering={rendering} />
        </div>
      </Sticker>
    );
  }
}

export default withStyles(s)(DetailStickyTop);
