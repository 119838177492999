interface IInputParams {
  type: string;
  url: string;
  loadCallback: (data: object) => void;
  errorCallback: (data: object) => void;
  contentType: string;
}

const makeXMLHttpRequest = ({type, url, loadCallback, errorCallback, contentType}: IInputParams): void => {
  const request = new XMLHttpRequest();

  request.open(type, url);
  request.onload = loadCallback;
  request.onerror = errorCallback;

  // Send the proper header information along with the request
  request.setRequestHeader("Content-Type", contentType);
  request.send();
};

export {
  makeXMLHttpRequest
};
