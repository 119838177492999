import React, { ReactElement } from 'react';
import './RestrictedWrapper.scss';
import { IProps } from './Interfaces/Component.props';
import { Row, Col, Container } from 'react-grid-system';
import get from 'lodash/get';
import ArrowsNavigation from '../ArrowsNavigation';
import { withSitecoreContext, Placeholder, LayoutServiceContextData } from '@sitecore-jss/sitecore-jss-react';
import { getLoginLinkWithParameters, capitalizeFirstLetter } from '../../helpers/utils';
import { withRouter } from '../../lib/withRouter';
import { ISitecoreContext } from '../../interfaces/SitecoreContext';

const RESTRICTED_PAGE_KEYS = ['label', 'text', 'loginLabel', 'registrationLabel'];

const getRestrictedPageValue = (context: ISitecoreContext | object, valueName: string): string =>
  get(context, `route.fields.restrictedPage${valueName}.value.src`, '') ||
  get(context, `route.fields.restrictedPage${valueName}.value`, '');

const getKeyObject = (name: string, context: ISitecoreContext | object): object => ({
  value: getRestrictedPageValue(context, capitalizeFirstLetter(name)),
});

const getData = (context: ISitecoreContext | object, props: IProps): object => {
  const data = {};
  RESTRICTED_PAGE_KEYS.forEach((key: string): void => {
    data[key] = getKeyObject(key, context);
  });

  return {
    ...data,
    heading: get(props, 'sitecoreContext.restrictedPageHeadline', { value: '' }),
    loginUrl: getLoginLinkWithParameters(props),
    registrationUrl: get(props, 'sitecoreContext.route.fields.restrictedPageRegistrationUrl.value.href'),
  };
};

interface IState {
  context: LayoutServiceContextData;
}

interface IIsRestricted {
  isRestricted: boolean;
}

const isRestricted: IIsRestricted = {
  isRestricted: true,
};

class RestrictedWrapper extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      context: props.sitecoreContext,
    };
  }

  public render(): ReactElement {
    return (
      <div
        className="restricted-wrapper"
        style={{
          backgroundImage: `url(${getRestrictedPageValue(this.state.context, 'BackgroundImage')})`,
        }}
      >
        <Container fluid={true} className="layout">
          <Row className="flex">
            <Col xs={12} className="restricted-wrapper__container">
              <div className="restricted-wrapper__card">
                <Placeholder
                  name="restricted-wrapper"
                  rendering={this.props.rendering}
                  {...getData(this.state.context, this.props)}
                />
              </div>
              <ArrowsNavigation {...isRestricted} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(withSitecoreContext()(RestrictedWrapper));
