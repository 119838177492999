import React from 'react';
import '../ContentBlock/ContentBlock.scss';
import get from 'lodash/get';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { IProps } from './Interfaces/Component.props';
import TruncatedContent from '../TruncatedContent';
import { ILayoutContext } from '../../interfaces/SitecoreContext';
import { DATA } from '../../analytics/constants';
import { getEnchancedHeadline } from '../../helpers/utils';
import { ITextField } from '../../interfaces/TextField';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './TruncatedContentBlock.scss';
import withFractureCodePage from '../../helpers/withFractureCodePage';

interface IState {
  sitecoreContext: ILayoutContext | {};
}

class TruncatedContentBlock extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      sitecoreContext: {},
    };
  }

  public render() {
    const props = this.props;

    const content = get(this.state, 'sitecoreContext.route.fields.content');
    const dataAttributes = this.getDataAttributes(props);
    const contentFields = { ...props.fields, fullContent: content };
    const isSettingsForTruncate = get(props, 'fields.lines.value');

    return (
      <>
        {content && isSettingsForTruncate && (
          <TruncatedContent
            className="truncated-content-block"
            fields={contentFields}
            dataAttributes={dataAttributes}
          />
        )}
      </>
    );
  }

  public componentDidMount() {
    this.setState({
      sitecoreContext: this.props.sitecoreContext,
    });
  }

  private getHeadline = (props: IProps): ITextField => {
    const isFractureCodePage = get(props, 'isFractureCodePage');
    const sitecoreContext = get(this.state, 'sitecoreContext');
    const routeFields = get(sitecoreContext, 'route.fields');
    const fractureCodeValue = get(sitecoreContext, 'fractureCodeValue', '');

    return getEnchancedHeadline(isFractureCodePage, fractureCodeValue, get(routeFields, 'headline'), ' ');
  };

  private value = (field: ITextField): string => get(field, 'value', '');

  private attribute = (key: string, field: ITextField): { [key: string]: string } =>
    !!field && { [key]: this.value(field) };

  private getDataAttributes(props: IProps): object {
    const fields = get(props, 'fields');

    return Object.assign(
      {},
      ...[
        this.attribute(DATA.category, get(fields, 'dataCategory')),
        this.attribute(DATA.action, get(fields, 'dataAction')),
        this.attribute(DATA.event, get(fields, 'dataEvent')),
        this.attribute(DATA.label, { ...get(fields, 'dataEvent'), ...this.getHeadline(props) }),
      ]
    );
  }
}

export default withStyles(s)(withSitecoreContext()(withFractureCodePage(TruncatedContentBlock)));
