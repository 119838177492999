import { Row, Col, Visible } from 'react-grid-system';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React, { useState, useEffect, ReactElement } from 'react';
import { IProps } from './Interfaces/Component.props';
import get from 'lodash/get';
import { fireEvent } from '../../helpers/utils';
import ArrowsNavigation from '../ArrowsNavigation';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './StyleguideLayoutSearchPage.scss';
import { withRouter } from '../../lib/withRouter/index';

const DEFAULT_FILTERS_VISIBILITY = false;

const insertAfter = (el: any, referenceNode: any): void => {
  referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
};

const removeFilters = (): void => {
  const filters = document.querySelector('.search-page__button_filters');
  if (filters && filters.parentNode) {
    filters.parentNode.removeChild(filters);
  }
};

const StyleguideLayoutSearchPage = (props: IProps): ReactElement => {
  const { rendering } = props;
  const [isVisibleFiltersCol, setIsVisibleFiltersCol] = useState(DEFAULT_FILTERS_VISIBILITY);
  const toggleFilters = (): void => {
    setIsVisibleFiltersCol(!isVisibleFiltersCol);
    fireEvent(window, 'toggleOverflow', { data: !isVisibleFiltersCol });
    const facetColumnTop = document.querySelector('.coveo-facet-column');
    if (facetColumnTop) {
      facetColumnTop.scrollTop = 0;
    }

    if (isVisibleFiltersCol) {
      window.scrollTo(0, 0); // Scroll to top after going from filters
    }
  };
  const fields = get(props, 'sitecoreContext.route.fields');
  const getButtonLabel = (name: string): string => get(fields, `${name}ButtonLabel.value`, '');
  const handleQuerySuccess = (event: any): void => {
    const summarySection = document.querySelector('.coveo-summary-section');
    const filters = document.querySelector('.search-page__button_filters');
    const isNeedToRemoveFilters = !get(event, 'detail.results.totalCount');
    const isNeedToAddButton = summarySection && !filters && !isNeedToRemoveFilters;

    if (isNeedToAddButton) {
      const div = document.createElement('div');
      div.className = 'search-page__button search-page__button_filters';
      div.innerText = getButtonLabel('filters');
      div.addEventListener('click', () => {
        setIsVisibleFiltersCol(!isVisibleFiltersCol);
        fireEvent(window, 'toggleOverflow', { data: !isVisibleFiltersCol });
        fireEvent(window, 'filtersMounted');
      });

      insertAfter(div, summarySection);
    }

    if (isNeedToRemoveFilters) {
      removeFilters();
    }
  };

  useEffect(() => {
    document.addEventListener('querySuccess', handleQuerySuccess);

    return (): void => {
      setIsVisibleFiltersCol(DEFAULT_FILTERS_VISIBILITY);
      fireEvent(window, 'toggleOverflow', { data: DEFAULT_FILTERS_VISIBILITY });
      document.removeEventListener('querySuccess', handleQuerySuccess);
    };
  }, []);

  return (
    <Row className={`flex wrapper search-page ${isVisibleFiltersCol ? 'search-page_filters-visible' : ''}`}>
      <Col xs={12}>
        <div className="search-page__head">
          <Placeholder name="search-page-head" rendering={rendering} />
          <Visible xs={true} sm={true} md={true}>
            {isVisibleFiltersCol && (
              <div className="search-page__head-buttons">
                {getButtonLabel('back') && (
                  <div className="search-page__button search-page__button_back" onClick={toggleFilters}>
                    {getButtonLabel('back')}
                  </div>
                )}
                {getButtonLabel('apply') && (
                  <div className="search-page__button search-page__button_apply" onClick={toggleFilters}>
                    {getButtonLabel('apply')}
                  </div>
                )}
              </div>
            )}
          </Visible>
        </div>
        <div className="search-page__body">
          <Placeholder name="search-page" rendering={rendering} />
        </div>
        <ArrowsNavigation {...{ isSearch: true }} />
      </Col>
    </Row>
  );
};

export default withStyles(s)(withSitecoreContext()(withRouter(StyleguideLayoutSearchPage)));
