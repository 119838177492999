import React, { Component } from 'react';
import { noIndex } from '../../constants';

class NoIndex extends Component<any> {
  public render() {
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: `<!-- ${noIndex.begin} -->` }} />
        {this.props.children}
        <div dangerouslySetInnerHTML={{ __html: `<!-- ${noIndex.end} -->` }} />
      </>
    );
  }
}

export default NoIndex;
