import React from 'react';

export interface IInformationPage {
  isInformationPage: boolean;
}

const InformationPageContext = React.createContext({
  isInformationPage: false,
});

export default InformationPageContext;
