import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import ICommonLink from '../../interfaces/CommonLink';
import { LINK_TYPE } from '../../links/constants';
import get from 'lodash/get';

// https://kamsar.net/index.php/2018/09/Routing-Sitecore-links-with-JSS/

const CommonLink: React.FunctionComponent<ICommonLink> = ({ className, children, link, dataAttributes, onClick }) => {
  const isInternal = get(link, 'value.linktype') === LINK_TYPE.internal;

  return (
    <>
      {!isInternal ? (
        <Link onClick={onClick} className={className} field={link} {...dataAttributes}>
          {children}
        </Link>
      ) : (
        get(link, 'value') && (
          <NavLink onClick={onClick} to={link.value.href || ''} className={className}>
            {children || link.value.text || link.value.href}
          </NavLink>
        )
      )}
    </>
  );
};

export default CommonLink;
