import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import get from 'lodash/get';
import * as React from 'react';
import { connect } from 'react-redux';
import Step from '../Step';
// import { IProps } from './Interfaces/Component.props';
import { IStepItem } from './Interfaces/StepItem';
import { actionCreators, IRoutes } from '../../store/Routes/RoutesItem';
import { IApplicationState } from '../../interfaces/ApplicationState';
import { slash } from '../../helpers/utils';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './ProgressBar.scss';

// type AllProps = IProps & IApplicationState & typeof actionCreators & IRoutes;

const ACTIVE_CLASS = 'active';

class ProgressBar extends React.Component<any, any> {
  private routeName: string;

  constructor(props: any) {
    super(props);
    const segments = this.getSegments();
    const lastSegment = segments[segments.length - 1];
    this.routeName = lastSegment;
  }

  public render = () => (
    <div className="progress-bar">
      <ol className="list-no-style progress-bar__list">
        {get(this.props, 'fields.stepsList', []).map((item: any, key: string) => {
          const itemWithSteps = this.setSteps(item, key);
          return (
            <Step
              key={key}
              item={itemWithSteps}
              stateClass={this.getStepStatus(itemWithSteps, key)}
              isClickable={this.getIsClickable(itemWithSteps, key)}
              url={this.getUrl(key)}
            />
          );
        })}
      </ol>
    </div>
  );

  public componentDidMount() {
    this.props.setRouteItem(this.getSetOfRoutes());
  }

  public componentDidUpdate() {
    this.props.setRouteItem(this.getSetOfRoutes());
  }

  /* // it is fix for https://jira.valtech.com/browse/AOF-941, but it breaks progress bar go back/forward flow
  public shouldComponentUpdate = (update: AllProps): boolean => {
    return this.props.routeName !== update.routeName;
  }; */

  private getStepStatus(item: IStepItem, key: string): string {
    const isEnabled = this.getIsEnabled(key);
    const notActiveClass = isEnabled ? 'enabled' : 'disabled';
    const currentStep = this.getCurrentStep();

    return currentStep - 1 === Number(key) ? ACTIVE_CLASS : notActiveClass;
  }

  private getIsClickable(item: IStepItem, key: string): boolean {
    const routeName = this.routeName || '';
    const isAciveStep = this.getIsActiveStep(item, routeName);
    const isEnabled = this.getIsEnabled(key);

    return isAciveStep || isEnabled;
  }

  private getIsActiveStep(item: IStepItem, routeName: string): boolean {
    const routeNameHome = 'home';
    const step1 = 'step-1';
    const step = item.fields.step;
    const isAciveStep = routeName.includes(step) || (routeName === routeNameHome && step === step1);

    return isAciveStep;
  }

  private getIsEnabled(key: string): boolean {
    const numberOfSteps = Math.max(this.getSegments().length, this.props.route.length);

    return numberOfSteps > Number(key);
  }

  private setSteps(item: IStepItem, key: string): IStepItem {
    const index = parseInt(key, 10) + 1;
    item.fields.step = `step-${index}`;
    return item;
  }

  private getCurrentPageUrl = (): string => {
    return get(new URL(get(this.props, 'sitecoreContext.url')), 'pathname', '').substr(1);
  };

  private getSegments = (): string[] => {
    return this.getCurrentPageUrl().split(slash);
  };

  private getSetOfRoutes() {
    const arr: string[] = [slash];
    let path = '';

    this.getSegments().map((item, key) => {
      path += `${slash}${item}`;
      if (key !== 0) arr.push(path); // skip /CMF empty page
    });

    return arr;
  }

  private getCurrentStep() {
    return this.getSetOfRoutes().length;
  }

  private getUrl(key: string) {
    return this.props.route[Number(key)];
  }
}

export default withStyles(s)(
  connect(
    (state: IApplicationState): IRoutes => ({
      route: state.routes.payload.route,
    }),
    {
      setRouteItem: actionCreators.setRouteItem,
    }
  )(withSitecoreContext()(ProgressBar))
);
