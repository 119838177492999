import * as React from 'react';
import get from 'lodash/get';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { IAuthorBlockProps } from './interfaces/Component.props';
import { IAuthor } from './interfaces/Author';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './AuthorsBlock.scss';

class AuthorsBlock extends React.Component<IAuthorBlockProps, {}> {
  public render = () => {
    const authors = get(this.props, 'item.fields.authors', []);

    return (
      <>
        <Text tag="h3" field={get(this.props, 'item.fields.heading', {})} className="widget__content" />
        <p className="authors">
          {authors.map((author: IAuthor, key: number) => {
            const separator = key !== authors.length - 1 ? ', ' : '';

            return (
              <span key={key}>
                <span itemScope={true} itemType={'http://www.schema.org/Person'} className="widget__content">
                  <span className="author" itemProp="name">
                    {get(author, 'fields.name.value')}
                  </span>
                </span>
                {separator}
              </span>
            );
          })}
        </p>
      </>
    );
  };
}

export default withStyles(s)(AuthorsBlock);
