import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import get from 'lodash/get';
import { IDiagnosisCardBaseProps as IProps } from './Interfaces/Sitecore.props';
import CommonLink from '../CommonLink';
import { diagnosPageImagesSizes, tokenize, COMPONENT_KEY } from '../../constants';
import CommonImage from '../CommonImage';
import TruncateMarkup from 'react-truncate-markup';
import {
  getTextFromFullContent,
  loadFromSessionStorage,
  getUid,
  saveToSessionStorage,
  scrollToRef,
} from '../../helpers/utils';
import FontContext from '../../FontContext';
import DetailStickyTopContext from '../../DetailStickyTopContext';
import NoIndex from '../NoIndex';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './DiagnosisCard.scss';

interface IState {
  collapsedClass: string;
  truncated: boolean;
  isRendered: boolean;
  uid: string;
}

const NUMBER_CROP_LINES = 3;

class DiagnosisCard extends React.Component<IProps, IState> {
  public static contextType = DetailStickyTopContext;
  private ref;
  private isScrollLocked = false;

  constructor(props: IProps) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      collapsedClass: '',
      truncated: false,
      isRendered: false,
      uid: getUid(props),
    };
  }

  public render() {
    const { isRendered } = this.state;
    const content = get(this.props, 'fields.content', '');
    const contentValue = get(content, 'value', '');

    return (
      isRendered && (
        <DetailStickyTopContext.Consumer>
          {offset => (
            <article
              className={`diagnos ${this.state.collapsedClass}`}
              ref={this.ref}
              data-offset={offset.headerHeight}
            >
              <header className={this.getDiagnosHeaderClass()}>
                <div className="diagnos__icon">
                  <CommonImage field={get(this.props, 'fields.icon', {})} sizes={diagnosPageImagesSizes} />
                </div>
                <Text tag="h3" field={get(this.props, 'fields.headline', {})} className="diagnos__headline" />
              </header>
              {content && (
                <div className="truncate-container">
                  <FontContext.Consumer>
                    {isFontLoaded => (
                      <>
                        {isFontLoaded && (
                          <div className="collapsed__content notranslate">
                            <TruncateMarkup lines={NUMBER_CROP_LINES} tokenize={tokenize}>
                              <div>{getTextFromFullContent(contentValue)}</div>
                            </TruncateMarkup>
                          </div>
                        )}
                      </>
                    )}
                  </FontContext.Consumer>
                </div>
              )}
              <NoIndex>
                <footer className="diagnos__footer">
                  {this.getFooterLink('Default')}
                  {this.getFooterLink('Primary')}
                </footer>
              </NoIndex>
            </article>
          )}
        </DetailStickyTopContext.Consumer>
      )
    );
  }

  public componentDidMount() {
    this.setState({ isRendered: true }); // we need this to hide block for SSR
    this.context.hookCall();

    window.addEventListener('animationStart', this.lockScroll);
    window.addEventListener('animationEnd', this.unlockScroll);
    window.addEventListener('scrollToCard', this.handleScrollToCard);
  }

  public componentWillUnmount() {
    window.removeEventListener('animationStart', this.lockScroll);
    window.removeEventListener('animationEnd', this.unlockScroll);
    window.removeEventListener('scrollToCard', this.handleScrollToCard);
  }

  private lockScroll = () => {
    this.isScrollLocked = true;
  };

  private unlockScroll = () => {
    this.isScrollLocked = false;
  };

  private getDiagnosHeaderClass = (): string => {
    const isHeadlineOnly = !get(this.props, 'fields.content.value', '');
    const headlineOnlyClass = isHeadlineOnly ? 'diagnos__header_headline-only' : '';

    return `diagnos__header  ${headlineOnlyClass}`;
  };

  private getFooterLink = (type: string): React.ReactElement | null => {
    return this.isLinkAvailable(type) ? (
      <CommonLink
        onClick={event => {
          event.stopPropagation();
          this.onClick();
        }}
        className={`diagnos__footer-link_${type.toLowerCase()}`}
        link={get(this.props, `fields.link${type}Url`, { value: {} })}
      >
        <Text field={get(this.props, `fields.link${type}`, {})} />
      </CommonLink>
    ) : null;
  };

  private isLinkAvailable = (type: string): boolean => {
    return get(this.props, `fields.link${type}Url.value.href`, false) && get(this.props, `fields.link${type}.value`);
  };

  private handleScrollToCard = (): void => {
    const checkIsNeedToScroll = new Promise<void>(resolve => {
      if (!this.isScrollLocked) {
        resolve();
      }
    });

    checkIsNeedToScroll.then(() => {
      const isNeedToScroll = loadFromSessionStorage(`${COMPONENT_KEY.diagnosisCard}`) === this.state.uid;

      if (isNeedToScroll) {
        scrollToRef(this.ref);
      }
    });
  };

  private onClick = (): void => {
    this.saveDiagnosisCard();
  };

  private saveDiagnosisCard = (): void => {
    saveToSessionStorage({
      [COMPONENT_KEY.diagnosisCard]: this.state.uid,
    });
  };
}

export default withStyles(s)(DiagnosisCard);
