import React from 'react';

// Layout inport copy for not-found [age]
import { VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import Helmet from 'react-helmet';
import Header from './components/Header';
import Footer from './components/Footer';
import Modal from 'react-modal';
import { ScreenClassProvider, Container } from 'react-grid-system';
import { getSearchCategories } from './helpers/search';
import get from 'lodash/get';
import { loadWebFonts, getOffset } from './helpers/utils';
import { isDisconnectedMode } from './helpers/sitecoreContext';

import './assets/scss/_global.scss';

Modal.setAppElement('#root');

// Renders a route-not-found message when no route is available from Sitecore
// The JSS equivalent of a 404 Not Found page.

// This is invoked from RouteHandler when Sitecore returns no valid route data.
// The NotFound component receives the Layout Service Context data, but no route data.
// This can be used to power parts of your site, such as navigation, from LS context additions
// without losing the ability to render them on your 404 pages :)

class NotFound extends React.Component {
  headerPlaceholder = React.createRef();

  render = () => (
    <React.Fragment>
      <Helmet>
        <title>{i18n.t('Page not found')}</title>
      </Helmet>
      <VisitorIdentification />
      <ScreenClassProvider>
        <div className="not-found__wrapper">
          <Header
            fields={this.getHeader()}
            headerUpdated={this.headerUpdated}
            isAnimation={false}
            searchCategories={getSearchCategories(this.props)}
          ></Header>
          <div ref={this.headerPlaceholder} />

          <Container fluid={true} className="layout layout_base layout_404 layout_details">
            <div className="content">
              <h1>Page not found</h1>
              <p>This page does not exist.</p>
              <p>
                Site: {get(this.props, 'context.site.name')}
                <br />
                Language: {get(this.props, 'context.language')}
              </p>
            </div>
          </Container>
          <Footer data={get(this.props, 'route.footer')} />
        </div>
      </ScreenClassProvider>
    </React.Fragment>
  );

  updateHeaderOffset = () => {
    const headerOffset = getOffset('header');
    const headerPlaceholder = get(this.headerPlaceholder, 'current');

    if (headerPlaceholder) {
      headerPlaceholder.style.height = `${headerOffset}px`;
    }
  };

  headerUpdated = () => {
    this.updateHeaderOffset();
  };

  componentDidMount = () => {
    // font loader initialization
    loadWebFonts(isDisconnectedMode(this.props), () => {
      this.forceUpdate();
    });
  };

  get404Class() {
    const page404Name = '404 page';
    return get(this.route, 'name') === page404Name ? 'layout_404' : '';
  }

  isDisconnectedMode = () => {
    return this.props.route.databaseName === 'available-in-connected-mode';
  };

  getHeader = () => {
    return get(this.props, 'sitecoreContext.header');
  };
}
export default NotFound;
