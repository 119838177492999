import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Visible, Hidden } from 'react-grid-system';
import * as React from 'react';
import get from 'lodash/get';
import Sidebar from 'react-sidebar';
import { IRecommendationBaseProps as IProps } from './Interfaces/Sitecore.props';
import { IRecommendation } from './Interfaces/Recommendation';
import { DATA, EVENT, CATEGORY, ACTION } from '../../analytics/constants';
import CommonLink from '../CommonLink';
import CommonImage from '../CommonImage';
import { teasersImagesSizes } from '../../constants';
import { ILazy } from '../../interfaces/Lazy';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Recommendation.scss';

interface IRecommendationState {
  sidebarOpen: boolean;
}

class Recommendation extends React.Component<IProps, IRecommendationState> {
  constructor(props: any) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }
  public render() {
    return (
      <div className="recommendations">
        <Visible xs={true} sm={true}>
          <Sidebar
            sidebar={this.sidebarContent()}
            open={this.state.sidebarOpen}
            onSetOpen={this.onSetSidebarOpen}
            pullRight={true}
            sidebarClassName="recommendation__sidebar"
            rootClassName="recommendation__root"
          >
          </Sidebar>
        </Visible>
        <Hidden xs={true} sm={true}>
          {get(this.props, 'fields.componentTitle.value') && (
            <Text tag={'h4'} field={get(this.props, 'fields.componentTitle')} />
          )}
          {get(this.props, 'fields.recommendations', []).map(
            (recommendation: { fields: IRecommendation }, key: number) => {
              return this.recommendationContent(recommendation.fields, key);
            }
          )}
        </Hidden>
      </div>
    );
  }

  private onSetSidebarOpen() {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  private getDataAttributes = (): object => {
    const dataAttributes = {
      [DATA.event]: EVENT.click,
      [DATA.category]: CATEGORY.userInteraction,
      [DATA.action]: ACTION.popin,
      [DATA.label]: `${get(this.props, 'sitecoreContext.route.fields.pageTitle.value', '')} -
      ${get(this.props, 'fields.componentPanelTitle.value', '')}`,
    };

    return dataAttributes;
  };

  private sidebarContent() {
    return (
      <div>
        <a
          className={`recommendation__cta ${this.state.sidebarOpen ? 'opened' : ''}`}
          onClick={() => this.onSetSidebarOpen()}
          {...this.getDataAttributes()}
        >
          {get(this.props, 'fields.componentPanelTitle.value', '')}
        </a>
        <div className="recommendation__sidebar-container">
          {get(this.props, 'fields.recommendationsPanel', []).map(
            (recommendation: { fields: IRecommendation }, key: number) => {
              return this.recommendationContent(recommendation.fields, key);
            }
          )}
        </div>
      </div>
    );
  }

  private recommendationContent(recommendation: IRecommendation, key: number): React.ReactElement {
    const lazyLoading: ILazy = {
      loading: 'lazy',
    };

    return (
      <div key={key} className="recommendation">
        <Visible xs={true} sm={true}>
          <div className="recommendation__category">
            <img src={get(recommendation, 'icon.value', '')} className="recommendation__icon" {...lazyLoading} alt=""/>
            <Text field={get(recommendation, 'category')} />
          </div>
        </Visible>
        <div className="recommendation__preview">
          <CommonImage
            className="recommendation__image"
            field={get(recommendation, 'image')}
            sizes={teasersImagesSizes}
          />
        </div>
        <div className="recommendation__content">
          <div>
            <Hidden xs={true} sm={true}>
              <div className="recommendation__category">
                <img src={get(recommendation, 'icon.value', '')} className="recommendation__icon" {...lazyLoading} alt=""/>
                <Text field={get(recommendation, 'category')} />
              </div>
            </Hidden>
            <CommonLink className="recommendation__headline" link={get(recommendation, 'link', { value: {} })} />
          </div>
          <ul className="list-no-style recommendation__meta-list">
            <li>
              <Text field={recommendation.date} />
            </li>
            <li>
              <Text field={recommendation.section} />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(withSitecoreContext()(Recommendation));
