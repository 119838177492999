import React from 'react';

export interface IInformationReferencePage {
  isInformationReferencePage: boolean;
}

const InformationReferencePageContext = React.createContext({
  isInformationReferencePage: false,
});

export default InformationReferencePageContext;
