import React from 'react';

export interface IProgressBarContext {
  stepperIsRelative: boolean;
  setStepperIsRelative: () => void;
}

const ProgressBarContext = React.createContext({
  stepperIsRelative: false,
  setStepperIsRelative: Function,
});

export default ProgressBarContext;
