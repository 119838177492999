import TagManager from 'react-gtm-module';
import get from 'lodash/get';
import { getPathSegments } from '../helpers/utils';
import { EVENT, SITECORE_CONTEXT } from './constants';

const { applicationName, googleTagManagerId, title, url } = SITECORE_CONTEXT;

const googleTagManagerInit = (props: any):void => {
  // Google Tag Manager initialization
  TagManager.initialize({
    dataLayer: {
      environment: {
        applicationName: get(props, applicationName),
      }
    },
    gtmId: get(props, googleTagManagerId),
  });
};

const getSectionsSubSections = (contentType: string):object => {
  const segments = getPathSegments() || [];
  const pageSection = segments[0];
  const pageSubsectionLevel2 = segments[1];
  const pageSubsectionLevel3 = segments[2];

  return {
    ...(!!pageSection && { pageSection }),
    ...(!!pageSubsectionLevel2 && { pageSubsectionLevel2 }),
    ...(!!pageSubsectionLevel3 && { pageSubsectionLevel3 }),
    ...(!!contentType && { contentType })
  };
};

const getUserName = (props: any) => {
  return get(props, 'sitecoreContext.user.name');
};

const getPageInfo = (props: any):object => {
	const pageURL = get(props, url);
	const pageTitle = get(props, title);

	return {
		...(!!pageURL && { pageURL }),
		...(!!pageTitle && { pageTitle }),
	  };
};

const updateGoogleTagManagerPageInfo = (props: any, isAsync: boolean, browserBack: boolean):void => {
  const contentType = get(props, 'route.fields.contentType.fields["type name"].value', '');
  const subSections = getSectionsSubSections(contentType);
  const isEmptySections = !Object.keys(subSections).length;
  const userId = getUserName(props);

  TagManager.dataLayer({
    dataLayer: {
      ...(!!userId && { user: { userId }}),
	    ...isAsync && {event: EVENT.asyncPage},
	    ...browserBack && {event: EVENT.browserBack},
      page: {
        pageInfo: {
          ...!isEmptySections && subSections,
          ...getPageInfo(props)
        },
      },
    },
    gtmId: get(props, googleTagManagerId),
  });
};

export {
  googleTagManagerInit,
  updateGoogleTagManagerPageInfo
};
