import React, { useContext } from 'react';
import TreatmentDetailsPageContext from '../components/Styleguide-Layout-TreatmentDetailsPage/TreatmentDetailsPageContext';
import InformationPageContext from '../components/Styleguide-Layout-InformationPage/InformationPageContext';
import IndicationPageContext from '../components/Styleguide-Layout-IndicationPage/IndicationPageContext';

const withFractureCodePage = Component => props => {
  const treatmentDetailsPageContext = useContext(TreatmentDetailsPageContext);
  const indicationPageContext = useContext(IndicationPageContext);
  const informationPageContext = useContext(InformationPageContext);
  const mergedPageContext = {
    ...treatmentDetailsPageContext,
    ...indicationPageContext,
    ...informationPageContext,
  };
  const isFractureCodePage = Object.values(mergedPageContext).some(flag => !!flag);

  return <Component {...props} {...{ isFractureCodePage }} />;
};

export default withFractureCodePage;
