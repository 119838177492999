import {
  GraphQLLayoutService,
  RestLayoutService,
  constants,
} from '@sitecore-jss/sitecore-jss-react';
import config from '../temp/config';

export class LayoutServiceFactory {
  create() {
    const sitecoreApi = window.location.href;
    let JssSiteName = sitecoreApi.indexOf("edit.")>0 ? "AOSR-CM" : "AOSR";
    return process.env.REACT_APP_FETCH_WITH === constants.FETCH_WITH.GRAPHQL
      ? new GraphQLLayoutService({
          endpoint: config.graphQLEndpoint,
          apiKey: config.sitecoreApiKey,
          //siteName: "aosr-cm",
          siteName: JssSiteName,
          rootItemId:'{B1DE8AFE-94E1-4535-A540-5DF3932AFCFE}'
        })
      : new RestLayoutService({
          apiHost: config.sitecoreApiHost,
          apiKey: config.sitecoreApiKey,
          //siteName: "aosr-cm",
          siteName: JssSiteName,        
          configurationName: config.layoutServiceConfigurationName,
        });
  }
}

export const layoutServiceFactory = new LayoutServiceFactory();
