import React from 'react';
import get from 'lodash/get';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Navigate } from 'react-router-dom';
import { withRouter } from '../../lib/withRouter';
import { IProps } from './Interfaces/Component.props';
import { ILink } from '../../interfaces/Link';
import CommonLink from '../CommonLink';
import { getSimpleLink } from '../../helpers/utils';
import Slider from 'react-slick';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './HorizontalScrollingMenu.scss';

const SLIDER_SPEED = 300;
const OFFSET_WITH_ARROW = 40;
const OFFSET_WITHOUT_ARROW = 24;
const SLIDES_TO_SHOW = 1;
const SLIDES_TO_SCROLL = 1;

interface IState {
  isVisible: boolean;
  selected: string;
  menuLinksMap: { [key: string]: string };
  link: string;
  isRendered: boolean;
  menuClassNoRightArrow: string;
  menuClassNoLeftArrow: string;
  isInitialized: boolean;
  isInitScroll: boolean;
}

class HorizontalScrollingMenu extends React.Component<IProps, IState> {
  private slickSlider;
  private slider;
  private observer;
  private isSwipeEnabled = false;

  constructor(props: IProps) {
    super(props);

    const itemsList = this.getItemsList();
    const selectedItem = this.getSelectedItem(itemsList);

    this.slickSlider = React.createRef();

    this.state = {
      isVisible: false,
      selected: selectedItem,
      menuLinksMap: this.getMenuLinksMap(itemsList),
      link: '',
      isRendered: false,
      menuClassNoRightArrow: '',
      menuClassNoLeftArrow: '',
      isInitialized: false,
      isInitScroll: true,
    };
  }

  public render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: SLIDER_SPEED,
      slidesToShow: SLIDES_TO_SHOW,
      slidesToScroll: SLIDES_TO_SCROLL,
      centerMode: false,
      arrows: true,
      variableWidth: true,
      afterChange: () => {
        this.afterChange();
      },
    };

    return (
      <>
        <div className={this.getMenuWrapperClasses()} ref={this.slickSlider}>
          {this.state.link && <Navigate to={this.state.link} />}
          {!this.state.isRendered && ( // Links for SEO
            <ul style={{ display: 'none' }}>
              {this.getLinksItems().map(item => {
                return (
                  <li key={item.text}>
                    <CommonLink link={getSimpleLink(item.url)}>{item.text}</CommonLink>
                  </li>
                );
              })}
            </ul>
          )}
          {this.getLinksItems() && this.getLinksItems().length > 0 && (
            <Slider {...settings} ref={slider => this.initSlider(slider)} swipe={this.isSwipeEnabled}>
              {this.getLinksItems().map(item => {
                return (
                  <div className="menu-item-wrapper" key={item.text}>
                    <div
                      className="menu-item"
                      onClick={() => {
                        this.onSelect(item.text);
                      }}
                    >
                      {item.text}
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      </>
    );
  }

  public componentDidMount() {
    this.setState({ isRendered: true }); // we need this to hide block for SSR

    const selectedSlide = this.getSelectedSlide();
    this.addActiveToSlide(selectedSlide);
    this.addObserverToSlider(selectedSlide);
  }

  public componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private getSelectedSlide = (): any => {
    const selectedItemIndex = this.getSelectedItemIndex(this.getItemsList());
    const selectedSlide = this.slickSlider.current.querySelector(`[data-index="${selectedItemIndex}"]`);

    return selectedSlide;
  };

  private addActiveToSlide = (selectedSlide: any): void => {
    const itemClass = '.menu-item-wrapper';
    selectedSlide.querySelector(itemClass).classList.add('active');
  };

  private addObserverToSlider = (selectedSlide: any): void => {
    const config = { attributes: true };
    const targetNode = selectedSlide.parentNode;
    const callback = mutationsList => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes') {
          this.afterChange();
        }
      }
    };

    // Create an observer instance linked to the callback function
    this.observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    this.observer.observe(targetNode, config);
  };

  private initSlider = (slider: any): void => {
    this.slider = slider;
  };

  private getMenuWrapperClasses = (): string => {
    return `menu-wrapper horizontal-scrolling-menu
      ${this.state.menuClassNoRightArrow}
      ${this.state.menuClassNoLeftArrow}`;
  };

  private getInitialSlideIndex = (): number => {
    return this.getSelectedItemIndex(this.getItemsList());
  };

  private afterChange = (): void => {
    if (!this.slickSlider.current) {
      return;
    }

    this.handleNoArrows();
    this.handleNotVisibleActiveItem();
  };

  private isItemVisible = (itemIndex: number): boolean => {
    const currentSlickSlider = this.slickSlider.current;
    const slide = currentSlickSlider.querySelector(`[data-index="${itemIndex}"]`);
    const slideLeft = slide.offsetLeft;
    const slideWidth = slide.offsetWidth;
    const slickSliderWidth = currentSlickSlider.offsetWidth;
    const translateX = this.getSliderTranslateX();
    const isLeftArrowHidden = this.isFirstItemVisible();
    const sideOffset = isLeftArrowHidden ? OFFSET_WITHOUT_ARROW : OFFSET_WITH_ARROW;
    const isItemVisible = slickSliderWidth > slideWidth + slideLeft + translateX + sideOffset;

    return isItemVisible;
  };

  private getSliderTranslateX = (): number => {
    const currentSlickSlider = this.slickSlider.current;
    const slickTrack = currentSlickSlider.querySelector(`.slick-track`);
    const xCoordinateIndex = 4;
    const transform = window.getComputedStyle(slickTrack).getPropertyValue('transform');
    const matrix = transform.match(/^matrix\((.+)\)$/);
    const translateX = matrix ? parseFloat(matrix[1].split(', ')[xCoordinateIndex]) : 0;

    return translateX;
  };

  private isFirstItemVisible = (): boolean => this.getSliderTranslateX() === 0;

  private handleNotVisibleActiveItem = (): void => {
    const initialSlideIndex = this.getInitialSlideIndex();
    const isInitialItemVisible = this.isItemVisible(this.getInitialSlideIndex());
    const isNeedToGoToSlide = !isInitialItemVisible && this.isFirstItemVisible() && this.state.isInitScroll;

    // Make go to slide only once
    if (isNeedToGoToSlide) {
      this.slider.slickGoTo(initialSlideIndex);
      this.setState({ isInitScroll: false });
    }
  };

  private handleNoArrows = (): void => {
    const lastItemIndex = this.getItemsList().length - 1;
    const isLeftArrowHidden = this.isFirstItemVisible();
    const noLeftArrowClass = 'horizontal-scrolling-menu_no-left-arrow';

    const isRightArrowHidden = this.isItemVisible(lastItemIndex);
    const noRightArrowClass = 'horizontal-scrolling-menu_no-right-arrow';

    this.isSwipeEnabled = !isRightArrowHidden || !isLeftArrowHidden;

    this.setState({
      menuClassNoRightArrow: isRightArrowHidden ? noRightArrowClass : '',
      menuClassNoLeftArrow: isLeftArrowHidden ? noLeftArrowClass : '',
    });
  };

  private getItemsList = (): object[] => {
    return get(this.props, 'fields.children.value', []);
  };

  private getLinksItems = (): ILink[] => {
    const mappedItems = this.getItemsList() || [];
    const getItem = (item: object, name: string): string => get(item, `${name}.value`, '');
    const mappedLinks = mappedItems.map(mappedItem => {
      return {
        text: getItem(mappedItem, 'name'),
        url: getItem(mappedItem, 'url'),
      };
    });

    return mappedLinks;
  };

  private getSelectedItem = (listItems: object[]): string => {
    const selectedItem = listItems.find(item => get(item, 'isActive.value') === 'true');
    return get(selectedItem, 'name.value', '');
  };

  private getSelectedItemIndex = (listItems: object[]): number => {
    const selectedItemIndex = listItems.findIndex(item => get(item, 'isActive.value') === 'true');
    return selectedItemIndex;
  };

  private getMenuLinksMap = (menuItems: object[]): { [key: string]: string } => {
    const menuLinksMap: { [key: string]: string } = {};

    menuItems.forEach(menuItem => {
      const url = get(menuItem, 'url.value');
      const heading = get(menuItem, 'name.value', '');
      if (url) {
        menuLinksMap[heading] = url;
      }
    });
    return menuLinksMap;
  };

  private onSelect = (key: string | number | null): void => {
    this.setState({ selected: `${key}` });
    const url = this.state.menuLinksMap[`${key}`];

    if (url) {
      this.setState({
        link: url,
      });
    }
  };
}

export default withStyles(s)(withSitecoreContext()(withRouter(HorizontalScrollingMenu)));
