import * as React from 'react';
import get from 'lodash/get';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { IDetailedDescriptionBaseProps as IProps } from './Interfaces/Sitecore.props';
import CommonLink from '../CommonLink';
import CommonImage from '../CommonImage';
import DetailStickyTopContext from '../../DetailStickyTopContext';
import {
  loadFromSessionStorage,
  getUid,
  saveToSessionStorage,
  fireEvent,
  removeFromSessionStorage,
} from '../../helpers/utils';
import { detailScrollTimeout, detailedDescriptionImagesSizes } from '../../constants';
import Modal from 'react-modal';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './DetailedDescription.scss';

interface IState {
  isRendered: boolean;
  ref: any;
  uid: string;
  isModalOpen: boolean;
}

const COMPONENT_KEY = 'detailedDescription';
const OVERLAY_KEY = 'detailedDescriptionOverlay';
const SCROLL_OFFSET_CORRECTION = 10;

const getModalContent = (props: IProps): JSX.Element[] => {
  const modalContentPath = 'fields.linksGroups';
  const linksPath = 'fields.links';
  const overlay = props.fields.overlay;

  return get(overlay, modalContentPath, []).map((item, key) => (
    <section className="links" key={key}>
      <Text tag="h1" field={get(item, 'fields.heading', {})} />
      {getSection(item, linksPath)}
    </section>
  ));
};

const getSection = (item: any, linksPath: string): React.ReactElement => {
  return (
    <ul className="list-no-style links__list">
      {get(item, linksPath, []).map((subItem, keySubItem) => (
        <li key={keySubItem}>{getLink(subItem)}</li>
      ))}
    </ul>
  );
};

const getLink = (subItem: any): React.ReactElement => (
  <CommonLink link={subItem.fields.link} onClick={() => triggerIsFromOverlay()}>
    <span className="word-break">{get(subItem, 'fields.linkName.value', '')}</span>
  </CommonLink>
);

const triggerIsFromOverlay = (): void => {
  fireEvent(window.document, 'overlayLinkClick');
};

class DetailedDescription extends React.Component<IProps, IState> {
  public static contextType = DetailStickyTopContext;
  private scrollTimeout;
  private isNoIntersection = true;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isRendered: false,
      ref: React.createRef(),
      uid: getUid(props),
      isModalOpen: false,
    };
  }

  public render() {
    const { isRendered, ref } = this.state;
    const isLinkOverlayButton =
      !!get(this.props, 'fields.useOverlayIfNotEmpty.value') &&
      !!get(this.props, 'fields.overlay.fields.linksGroups', []).length;

    return (
      isRendered && (
        <DetailStickyTopContext.Consumer>
          {offset => (
            <>
              {get(this.props, 'fields.linkText') && (
                <div
                  className="detailed-description__wrapper"
                  ref={ref}
                  data-offset={offset.headerHeight + offset.detailStickyTopHeight}
                >
                  <div className="detailed-description">
                    <div className="detailed-description__image">
                      <CommonImage
                        isNotLazy={true}
                        sizes={detailedDescriptionImagesSizes}
                        field={get(this.props, 'fields.image', {})}
                        alt={get(this.props, 'fields.title.value', '')}
                      />
                    </div>
                    <div className="detailed-description__content">
                      <Text tag="h4" field={get(this.props, 'fields.title', {})} />
                      {isLinkOverlayButton ? (
                        <a onClick={this.openModal} className="toggle-overlay">
                          <Text field={get(this.props, 'fields.overlay.fields.overviewTitle', {})} />
                        </a>
                      ) : (
                        <CommonLink
                          link={get(this.props, 'fields.link')}
                          onClick={event => {
                            event.stopPropagation();
                            this.onClick();
                          }}
                        >
                          <Text field={get(this.props, 'fields.linkText', {})} />
                        </CommonLink>
                      )}
                    </div>
                  </div>
                  <Modal
                    isOpen={this.state.isModalOpen}
                    onRequestClose={this.closeModal}
                    className="modal"
                    overlayClassName="modal__overlay"
                  >
                    <Text
                      tag="button"
                      className="modal__close"
                      field={get(this.props, 'fields.modalClose')}
                      onClick={this.closeModal}
                    />
                    <div className="modal-content">{getModalContent(this.props)}</div>
                  </Modal>
                </div>
              )}
            </>
          )}
        </DetailStickyTopContext.Consumer>
      )
    );
  }

  public componentDidMount() {
    this.setState({ isRendered: true }); // we need this to hide block for SSR
    this.context.hookCall();

    window.addEventListener('animationEnd', this.handleScrollToCard);
    window.addEventListener('deckIntersection', this.handleDeckIntersection);

    const OverlaysList = loadFromSessionStorage(`${OVERLAY_KEY}`) || {};
    // check if current component overlay is stored and open it
    Object.keys(OverlaysList).map(x => {
      if (x === getUid(this.props)) {
        this.openModal();
      }
    });
  }

  public componentWillUnmount() {
    clearTimeout(this.scrollTimeout);
    window.removeEventListener('animationEnd', this.handleScrollToCard);
    window.removeEventListener('deckIntersection', this.handleDeckIntersection);
  }

  private handleDeckIntersection = (): void => {
    this.isNoIntersection = false;
  };

  private handleScrollToCard = (): void => {
    const isNeedToScroll =
      loadFromSessionStorage(`${COMPONENT_KEY}.${this.state.uid}`) === this.state.uid && this.isNoIntersection;

    if (isNeedToScroll) {
      // TODO we have to check scroll to deck first
      // disabling it because scroll to deck doesn't works
      // this.scrollToRef(this.state.ref);
    }
  };

  private scrollToRef = (ref: any): void => {
    if (!ref.current) {
      return;
    }

    const element = get(ref, 'current');
    const top = element.getBoundingClientRect().top + window.pageYOffset;
    const offset = parseInt(element.getAttribute('data-offset'), 10);
    const y = top - offset - SCROLL_OFFSET_CORRECTION;

    this.scrollTimeout = setTimeout(() => {
      window.scrollTo(0, y);
      clearTimeout(this.scrollTimeout);
    }, detailScrollTimeout);
  };

  private onClick = (): void => {
    this.saveDetailDescription();
  };

  private saveDetailDescription = (): void => {
    saveToSessionStorage({
      [COMPONENT_KEY]: {
        [this.state.uid]: this.state.uid,
      },
    });
  };

  private openModal = () => {
    this.setState({ isModalOpen: true });
    this.saveCriticalReadingOverlay();
  };

  private closeModal = () => {
    this.setState({ isModalOpen: false });
    this.deleteCriticalreadingOverlay();
  };

  private saveCriticalReadingOverlay = (): void => {
    const id = 0;
    saveToSessionStorage({
      [OVERLAY_KEY]: {
        [getUid(this.props)]: id,
      },
    });
  };

  private deleteCriticalreadingOverlay = (): void => {
    removeFromSessionStorage(OVERLAY_KEY, getUid(this.props));
  };
}

export default withStyles(s)(DetailedDescription);
