import * as React from 'react';
import { IOpenGraph as IProps, IFb } from './Interfaces/Component.props';
import Helmet from 'react-helmet';

const OpenGraph: React.FC<IProps> = (props: IProps) => {
  const { twitter = {}, og = {}, fb = {} } = props;
  const { card, site, creator } = twitter;
  const { siteName, title, description, image, url, type } = og;
  const { appId } = fb;
  const metaData = [
    { name: 'twitter:card', content: card },
    { name: 'twitter:site', content: site },
    { name: 'twitter:creator', content: creator },
    { name: 'og:site_name', content: siteName },
    { name: 'og:title', content: title },
    { name: 'og:description', content: description },
    { name: 'og:image', content: image },
    { name: 'og:url', content: url },
    { name: 'og:type', content: type },
    { name: 'fb:app_id', content: appId },
  ];
  const filteredMetaData = metaData.filter(data => !!data.content) || [];

  return (
    <Helmet>
      {filteredMetaData.map((meta, key) => (
        <meta key={key} name={meta.name} content={meta.content} />
      ))}
    </Helmet>
  );
};

export default OpenGraph;
