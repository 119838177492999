import { RichText } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import get from 'lodash/get';
import { ITreatmentCategoryBaseProps as IProps } from './Interfaces/Sitecore.props';
import CommonLink from '../CommonLink';
import { ILazy } from '../../interfaces/Lazy';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './TreatmentCategory.scss';

const TreatmentCategory: React.FC<IProps> = (props: IProps) => {
  const lazyLoading: ILazy = {
    loading: 'lazy',
  };

  return (
    <CommonLink className="treatment-category" link={props.fields.url}>
      <img src={get(props, 'fields.icon.value.src', '')} className="treatment-category__icon" {...lazyLoading} />
      <RichText field={get(props, 'fields.link', {})} />
    </CommonLink>
  );
};

export default withStyles(s)(TreatmentCategory);
