import * as React from 'react';
import get from 'lodash/get';
import CommonLink from '../CommonLink';
import { ILinksBlockBaseProps as IProps } from './Interfaces/Sitecore.props';

// Use styles for critical CSS
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './LinksBlock.scss';

class LinksBlock extends React.Component<IProps> {
  public render = () => {
    const getLinks = (props: IProps): JSX.Element[] => {
      return get(props, 'fields.links', []).map((link, key) => (
        <li className="links-block__link" key={key}>
          <CommonLink className="" link={get(link, `fields.link`, '')}>
            <span className="word-break">{get(link, 'fields.linkName.value', '')}</span>
          </CommonLink>
        </li>
      ));
    };

    return <ul className="links-block">{getLinks(this.props)}</ul>;
  };
}

export default withStyles(s)(LinksBlock);
