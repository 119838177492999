import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React, { ReactElement } from 'react';
import './StyleguideLayoutRestrictedPage.scss';
import { IProps } from './Interfaces/Component.props';

const StyleGuideLayoutRestrictedPage = (props: IProps): ReactElement => {
  const { rendering } = props;

  return (
    <div className="restricted-page">
      <Placeholder name="restricted-page" rendering={rendering} />
    </div>
  );
};

export default withSitecoreContext()(StyleGuideLayoutRestrictedPage);
